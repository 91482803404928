import api from './api';

const fetchConfiguredIntegrationsExecutions = async (uuid) => {
  try {
    const { data } = await api.get(
      `/configured-integrations/${uuid}/executions`,
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchConfiguredIntegrationsChangeHistory = async (uuid) => {
  try {
    const { data } = await api.get(
      `/configured-integrations/${uuid}/change-history`,
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchConfiguredIntegrations = async (uuid, page, limit) => {
  try {
    const { data } = await api.get(
      `/configured-integrations?filter[integration_uuid_eq]=${uuid}`,
      {
        params: { page, limit },
      },
    );

    return { data: data.data, totalRecords: data.totalRecords };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchConfiguredIntegrationsByUuid = async (uuid, page, limit) => {
  try {
    const { data } = await api.get(`/configured-integrations/${uuid}`, {
      params: { page, limit },
    });

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const updateConfiguredIntegration = async (uuid, params) => {
  try {
    const { data } = await api.put(
      `${process.env.REACT_APP_BASE_URL}/configured-integrations/${uuid}`,
      {
        data: {
          type: 'configured_integrations',
          attributes: params,
        },
      },
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const deleteConfiguredIntegrationByUuid = async (uuid) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_BASE_URL}/configured-integrations/${uuid}`,
      { data: {} },
    );

    return response;
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

export default {
  fetchConfiguredIntegrationsExecutions,
  fetchConfiguredIntegrationsChangeHistory,
  fetchConfiguredIntegrations,
  fetchConfiguredIntegrationsByUuid,
  deleteConfiguredIntegrationByUuid,
  updateConfiguredIntegration,
};
