import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { P, RadioGroup } from '@decisiv/ui-components';

export default function VisibilityFilter({
  handleForm,
  currentActiveValue,
  currentPublishValue,
}) {
  return (
    <>
      <P marginBottom={1}>
        <Trans>Published Status</Trans>
      </P>
      <P color="alaskanHusky" weight="regular" marginBottom={1}>
        <Trans>
          Published integrations are publicly available for end users to enable
          and self manage.
        </Trans>
      </P>
      <RadioGroup
        marginBottom={1}
        name="radio_publish"
        items={[
          { label: t`Both`, value: 'Both' },
          { label: t`Published`, value: 'Published' },
          { label: t`Unpublished`, value: 'Unpublished' },
        ]}
        vertical
        value={currentPublishValue}
        onChange={(e) => {
          handleForm({ event: e, field: 'published' });
        }}
      />
      <P marginBottom={1} marginTop={2}>
        <Trans>Active Status</Trans>
      </P>
      <RadioGroup
        name="radio_active"
        items={[
          { label: t`Both`, value: 'Both' },
          { label: t`Active`, value: 'Active' },
          { label: t`Inactive`, value: 'Inactive' },
        ]}
        vertical
        value={currentActiveValue}
        onChange={(e) => {
          handleForm({ event: e, field: 'active' });
        }}
      />
    </>
  );
}

VisibilityFilter.propTypes = {
  handleForm: PropTypes.func.isRequired,
  currentActiveValue: PropTypes.string.isRequired,
  currentPublishValue: PropTypes.string.isRequired,
};
