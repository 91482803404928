import styled from 'styled-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';

export const BigContentWrapper = styled(Flex)`
  padding-left: 0.4rem;
  flex-direction: row;
  gap: 0.3rem;
  margin-top: -0.7rem;
  margin-left: 2.5rem;
`;

export const ArrowContentWrapper = styled(Flex)`
  flex-direction: row;
  margin: auto auto;
  color: ${toColorString(color.base.alaskanHusky)};
`;
