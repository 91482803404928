const formatEnvironment = (env) => {
  const specialCases = {
    qa: 'QA',
    staging: 'Staging',
  };

  return specialCases[env] || env;
};

export default formatEnvironment;
