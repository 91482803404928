import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { CheckboxGroup, Combobox, Grid, H3, P } from '@decisiv/ui-components';
import color from '@decisiv/design-tokens/lib/color';
import toColorString from 'polished/lib/color/toColorString';
import Asterisk from '@decisiv/iconix/lib/components/Asterisk';
import SectionRow from '../../components/SectionRow';
import filterOptions from '../../utils/filterOptions';

export default function Scope({
  handleForm,
  serviceNetworkItems,
  editMode,
  integration,
}) {
  const [inputValue, setInputValue] = useState('');

  const [serviceNetworkValue, setServiceNetworkValue] = useState(
    (integration?.attributes?.service_network || []).map((serviceNetwork) => ({
      id: serviceNetwork,
      label: serviceNetwork,
      value: serviceNetwork,
    })),
  );

  const audienceItems = [
    {
      name: 'audience',
      id: 'service_management',
      label: 'Service Management',
      value: 'Service Management',
      defaultChecked: editMode
        ? integration?.attributes.audience.includes('Service Management')
        : null,
    },
    {
      name: 'audience',
      id: 'asset_management',
      label: 'Asset Management',
      value: 'Asset Management',
      defaultChecked: editMode
        ? integration?.attributes.audience.includes('Asset Management')
        : null,
    },
  ];

  const handleServiceNetwork = () => {
    const updateServiceNetwork = (
      integration?.attributes?.service_network || []
    ).map((serviceNetwork) => ({
      id: serviceNetwork,
      label: serviceNetwork,
      value: serviceNetwork,
    }));
    setServiceNetworkValue(updateServiceNetwork);
    setInputValue('');
  };

  return (
    <>
      <SectionRow>
        <Grid.Column padding={0} span="3">
          <H3>
            <Trans>Scope</Trans>
          </H3>
        </Grid.Column>
        <Grid.Column padding={0}>
          <P shade={1} size="small">
            <Trans>Define who can enable the integration.</Trans>
          </P>
          <P
            marginTop={1}
            size="small"
            style={{
              color: toColorString(color.base.alaskanHusky),
            }}
          >
            <Trans>Audience</Trans>
            <Asterisk
              color={toColorString(color.status.information.medium)}
              css={{ verticalAlign: 'middle' }}
              size="small"
            />
          </P>
          <CheckboxGroup
            required
            name="scope[]"
            style={{ marginTop: '0.5rem' }}
            items={audienceItems}
            onChange={(e) => {
              handleForm({ event: e });
            }}
          />

          <Combobox
            helpMessage={t`If it applies to all Service Networks, nothing needs to be filled in.`}
            multiple
            label={t`Service Network`}
            style={{ marginTop: '1.125rem' }}
            options={filterOptions(serviceNetworkItems, inputValue)}
            value={serviceNetworkValue}
            onChangeInputValue={(e) => setInputValue(e ? e.target.value : '')}
            onChangeValue={(selectedId, options) => {
              handleForm({
                selectedId,
                options,
                field: 'service_network',
              });
              handleServiceNetwork();
            }}
          />
        </Grid.Column>
      </SectionRow>
    </>
  );
}

Scope.defaultProps = {
  editMode: false,
};

Scope.propTypes = {
  handleForm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  serviceNetworkItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
  editMode: PropTypes.bool,
};
