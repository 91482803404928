import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@decisiv/ui-components';

import AudienceFilter from './AudienceFilter';
import CategoryFilter from './CategoryFilter';
import CommandsFilter from './CommandsFilter';
import ServiceNetworkFilter from './ServiceNetworkFilter';
import TriggerFilter from './TriggerFilter';
import VisibilityFilter from './VisibilityFilter';
import CacheFilter from './CacheFilter';

export default function AccordionFilter({ handleForm, form }) {
  const accordionItems = [
    {
      PanelComponent: () => (
        <CategoryFilter handleForm={handleForm} currentValue={form.category} />
      ),
      id: 'accordion-category',
      title: 'Category',
    },
    {
      PanelComponent: () => (
        <AudienceFilter handleForm={handleForm} currentValue={form.audience} />
      ),
      id: 'accordion-audience',
      title: 'Audience',
    },
    {
      PanelComponent: () => (
        <ServiceNetworkFilter
          handleForm={handleForm}
          currentValue={form.serviceNetwork}
        />
      ),
      id: 'accordion-service-network',
      title: 'Service Network',
    },
    {
      PanelComponent: () => (
        <TriggerFilter
          handleForm={handleForm}
          currentTriggerValue={form.trigger}
          currentEventsValue={form.events}
        />
      ),
      id: 'accordion-trigger',
      title: 'Trigger',
    },
    {
      PanelComponent: () => (
        <CommandsFilter handleForm={handleForm} currentValue={form.commands} />
      ),
      id: 'accordion-commands',
      title: 'Commands',
    },
    {
      PanelComponent: () => (
        <VisibilityFilter
          handleForm={handleForm}
          currentActiveValue={form.active}
          currentPublishValue={form.published}
        />
      ),
      id: 'accordion-visibility',
      title: 'Visibility',
    },
    {
      PanelComponent: () => (
        <CacheFilter handleForm={handleForm} currentValue={form.cacheEnabled} />
      ),
      id: 'accordion-cache-enabled',
      title: 'Cache',
    },
  ];

  return (
    <>
      <Accordion items={accordionItems} titleHeadingLevel="5" controls="hide" />
    </>
  );
}

AccordionFilter.propTypes = {
  handleForm: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
