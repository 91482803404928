const filterOptions = (options, filterValue) => {
  const filterBy = filterValue.toLowerCase();

  return options.reduce((acc, current) => {
    return String(current.label).toLowerCase().match(filterBy)
      ? acc.concat(current)
      : acc;
  }, []);
};

export default filterOptions;
