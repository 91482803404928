import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';

import { t, Trans } from '@lingui/macro';
import toColorString from 'polished/lib/color/toColorString';

import iconix from '@decisiv/iconix';
import color from '@decisiv/design-tokens/lib/color';
import Asterisk from '@decisiv/iconix/lib/components/Asterisk';
import { Button, Combobox, Flex, P } from '@decisiv/ui-components';
import filterOptions from '../../utils/filterOptions';

const CommandSelector = ({
  options,
  index,
  selectedCommand,
  updateSelection,
  removeCommandHandler,
  editMode,
}) => {
  const [inputValue, setInputValue] = useState(
    selectedCommand.match(/unselected/) ? '' : selectedCommand,
  );

  const asteriskIcon = useMemo(
    () => (
      <Asterisk
        color={toColorString(color.status.information.medium)}
        css={{ verticalAlign: 'middle' }}
        size="small"
      />
    ),
    [],
  );

  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      style={{
        whiteSpace: 'nowrap',
        gap: '1.25rem',
        margin: '-1px',
        border: '1px solid #e3ebf1',
        padding: '0.625rem',
      }}
    >
      <Button
        aria-label={t`Remove command ${index + 1}`}
        variant="ghost"
        icon={iconix.MinusCircle}
        disabled={!index || editMode}
        onClick={() => removeCommandHandler(index)}
      />
      <P>
        <Trans>
          Command {index + 1} {asteriskIcon}
        </Trans>
      </P>
      <Combobox
        hideLabel
        disabled={editMode}
        label={t`Command ${index + 1} label`}
        style={{ maxWidth: '332px' }}
        defaultValue={selectedCommand}
        defaultInputValue={inputValue}
        onChangeInputValue={(e) => setInputValue(e ? e.target.value : '')}
        onChangeValue={(selectedId) => updateSelection(selectedId, index)}
        options={filterOptions(options, inputValue)}
      />
    </Flex>
  );
};

CommandSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  index: PropTypes.number.isRequired,
  selectedCommand: PropTypes.string.isRequired,
  updateSelection: PropTypes.func.isRequired,
  removeCommandHandler: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default CommandSelector;
