import React from 'react';
import PropTypes from 'prop-types';
import { Modal, P } from '@decisiv/ui-components';
import iconx from '@decisiv/iconix';
import { t } from '@lingui/macro';
import startCase from 'lodash/startCase';

export default function ConfirmationModal(props) {
  const handleCloseModal = () => {
    props.toggleModal(false);
  };

  const handleConfirmModal = () => {
    props.toggleModal(false);
    props.changeIntegrationStatus('published');
  };

  return (
    <>
      <Modal
        actions={[
          {
            text: props.modalAction,
            onClick: handleConfirmModal,
          },
          {
            text: t`Cancel`,
            onClick: handleCloseModal,
          },
        ]}
        icon={iconx.EyeAlt}
        color="information"
        onClose={handleCloseModal}
        title={`${startCase(props.modalAction.toLocaleLowerCase())} [${
          props.integrationName
        }]`}
        visible={props.modalVisibility}
      >
        <P shade={1} marginTop={2}>
          {props.description}
        </P>
      </Modal>
    </>
  );
}

ConfirmationModal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  changeIntegrationStatus: PropTypes.func.isRequired,
  modalAction: PropTypes.string.isRequired,
  integrationName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
