import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

import AWSSfnGraph from '@tshepomgaga/aws-sfn-graph';
import '@tshepomgaga/aws-sfn-graph/index.css';

export default function CommandsGraph({ name, startAt, states }) {
  const aslData = {
    Comment: name,
    StartAt: startAt,
    States: states,
  };

  return (
    <AWSSfnGraph
      data={aslData}
      width={810}
      height={800}
      onError={console.log}
    />
  );
}

CommandsGraph.propTypes = {
  name: PropTypes.string.isRequired,
  startAt: PropTypes.string.isRequired,
  states: PropTypes.shape({}).isRequired,
};
