import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Button, Grid } from '@decisiv/ui-components';
import { t } from '@lingui/macro';
import { navigate, useParams } from '@reach/router';
import isEmpty from 'lodash/isEmpty';
import PageTitle from '../../components/PageTitle/PageTitle';
import AsteriskSubtitle from '../../components/AsteriskSubtitle';
import BasicInformation from '../IntegrationCreatePage/basic_information';
import PaddedForm from '../../components/PaddedForm';
import IntegrationsService from '../../api/integrations';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import Scope from '../IntegrationCreatePage/scope';
import ServiceNetworkService from '../../api/service_network';
import Trigger from '../IntegrationCreatePage/trigger';
import ApplicationEventsService from '../../api/application_events';
import EditModal from './edit_modal';
import Commands from '../IntegrationCreatePage/commands';
import CommandsService from '../../api/commands';
import CategoriesAndActionsService from '../../api/categories_and_actions';

export default function IntegrationEditPage() {
  const [integration, setIntegration] = useState({});
  const [form, setForm] = useState({});
  const [serviceNetworkItems, setServiceNetworkItems] = useState([]);
  const [applicationEventsItems, setApplicationEventsItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allCategoriesAndActions, setAllCategoriesAndActions] = useState([]);
  const [triggerTypeHttpRequest, setTriggerTypeHttpRequest] = useState(false);
  const [triggerTypeApplicationEvent, setTriggerTypeApplicationEvent] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [commands, setCommands] = useState([]);
  const [commandsFlow, setCommandsFlow] = useState(['']);
  const [categoryActions, setCategoryActions] = useState([]);
  const [saveBtnStatus, setSaveBtnStatus] = useState(true);

  const TRIGGER_TYPE_APPLICATION_EVENT = 'application_event';
  const TRIGGER_TYPE_HTTP_API_REQUEST = 'http_api_request';

  const params = useParams();

  function parseCommands({ data }) {
    const parsedData = data.map((command) => {
      return {
        id: command.attributes.name,
        connections: command.attributes.connections,
      };
    });

    return parsedData;
  }

  function parseCategories({ data }) {
    const parsedData = data.map((categoryAndActions) => {
      return {
        id: categoryAndActions.attributes.category,
        label: categoryAndActions.attributes.category,
        value: categoryAndActions.attributes.category,
        name: categoryAndActions.attributes.category,
      };
    });

    return parsedData;
  }

  function parseActions(actions) {
    const parsedData = actions.map((action) => {
      return {
        id: action,
        label: action,
        value: action,
        name: action,
      };
    });

    return parsedData;
  }

  const handleForm = ({ event, selectedId, options, field }) => {
    const index = isEmpty(field) ? event.target.name : field;
    const newForm = form;
    let actions = null;

    switch (index) {
      // managing array value fields
      case 'audience':
      case 'trigger_type':
        if (event.target.checked) {
          newForm[index].push(event.target.value);
        } else {
          const itemIndex = newForm[index].indexOf(event.target.value);
          if (itemIndex !== -1) {
            newForm[index].splice(itemIndex, 1);
          }
        }
        break;
      case 'service_network':
      case 'events':
        // managing combobox multiple values fields
        /* istanbul ignore next */
        if (
          !newForm[index].includes(selectedId) &&
          !isEmpty(selectedId) &&
          !options.removed
        ) {
          newForm[index].push(selectedId);
        }

        /* istanbul ignore next */
        if (newForm[index].includes(selectedId) && options.removed) {
          const indexToRemove = newForm[index].indexOf(selectedId);
          if (indexToRemove !== -1) {
            newForm[index].splice(indexToRemove, 1);
          }
        }

        /* istanbul ignore next */
        integration.attributes[index] = newForm[index];
        /* istanbul ignore next */
        setIntegration(integration);

        /* istanbul ignore next */
        break;
      /* istanbul ignore next */
      case 'commands_flow':
        newForm[index] = event;
        setCommandsFlow(event);
        break;
      /* istanbul ignore next */
      case 'action':
        newForm[index] = selectedId;
        break;
      /* istanbul ignore next */
      case 'category':
        /* istanbul ignore else */
        if (!isEmpty(selectedId)) {
          actions = allCategoriesAndActions.filter(
            (item) => item.attributes.category === selectedId,
          )[0].attributes.actions;

          setCategoryActions(parseActions(actions));
        } else {
          setCategoryActions([]);
        }

        newForm[index] = selectedId;
        break;
      default:
        // managing text value fields
        /* istanbul ignore next */
        newForm[index] = event.target.value.includes('{')
          ? event.target.value.replace(/\n/g, '')
          : event.target.value;
    }

    setForm(newForm);
    setSaveBtnStatus(false);

    setTriggerTypeApplicationEvent(
      newForm.trigger_type.includes(TRIGGER_TYPE_APPLICATION_EVENT),
    );
    setTriggerTypeHttpRequest(
      newForm.trigger_type.includes(TRIGGER_TYPE_HTTP_API_REQUEST),
    );
  };

  useEffect(() => {
    async function getData() {
      const responseCategoriesAndActions =
        await CategoriesAndActionsService.fetchCategoriesAndActions();
      /* istanbul ignore else */
      if (responseCategoriesAndActions.data) {
        const parsedCategories = parseCategories(responseCategoriesAndActions);

        setAllCategoriesAndActions(responseCategoriesAndActions.data);
        setCategories(parsedCategories);
      }
      const responseCommands = await CommandsService.fetchCommands();
      /* istanbul ignore else */
      if (responseCommands.data) {
        const parsedCommands = parseCommands(responseCommands);
        setCommands(parsedCommands);
      }

      const integrationResponse =
        await IntegrationsService.fetchIntegrationByUuid(params.id);

      if (integrationResponse.data) {
        setForm({
          name: integrationResponse.data.attributes.name,
          description: integrationResponse.data.attributes.description,
          category: integrationResponse.data.attributes.category,
          audience: integrationResponse.data.attributes.audience,
          service_network: integrationResponse.data.attributes.service_network,
          trigger_type: integrationResponse.data.attributes.trigger_type,
          events: integrationResponse.data.attributes.events,
          action: integrationResponse.data.attributes.action,
        });
        setIntegration(integrationResponse.data);

        let actions = null;
        actions = responseCategoriesAndActions.data.filter(
          (item) =>
            item.attributes.category ===
            integrationResponse.data.attributes.category,
        )[0].attributes.actions;
        setCategoryActions(parseActions(actions));
        setTriggerTypeApplicationEvent(
          integrationResponse.data.attributes.trigger_type.includes(
            TRIGGER_TYPE_APPLICATION_EVENT,
          ),
        );
        setTriggerTypeHttpRequest(
          integrationResponse.data.attributes.trigger_type.includes(
            TRIGGER_TYPE_HTTP_API_REQUEST,
          ),
        );

        setCommandsFlow(integrationResponse.data.attributes.commands_flow);
        setLoading(false);
      } else {
        /* istanbul ignore next */
        setLoading(false);
      }

      const responseServiceNetwork =
        await ServiceNetworkService.fetchServiceNetwork();
      /* istanbul ignore else */
      if (responseServiceNetwork.data) {
        const parsedServiceNetworks = responseServiceNetwork.data.map((sn) => ({
          id: sn.attributes.name,
          label: sn.attributes.name,
          value: sn.attributes.name,
        }));
        setServiceNetworkItems(parsedServiceNetworks);
      }

      const responseApplicationEvents =
        await ApplicationEventsService.fetchApplicationEvents();
      /* istanbul ignore else */
      if (responseApplicationEvents.data) {
        const parsedApplicationEvents = responseApplicationEvents.data.map(
          (sn) => ({
            id: sn.attributes.name,
            label: sn.attributes.name,
            value: sn.attributes.name,
          }),
        );
        setApplicationEventsItems(parsedApplicationEvents);
      }
    }

    getData();
  }, [params.id, integration.length, form.length]);
  const handleSave = () => {
    setShowEditModal(true);
  };

  const handleToggleEditModal = () => setShowEditModal(!showEditModal);

  return (
    <>
      <EditModal
        handleToggleEditModal={handleToggleEditModal}
        showEditModal={showEditModal}
        form={form}
        integration={integration}
      />

      <Breadcrumbs
        path={[{ to: '/integrations', text: 'Integrations' }]}
        current={t`Edit Integration`}
        style={{ padding: '0.375rem 1.3125rem' }}
      />
      <PageTitle title={t`Edit Integration`} subtitle={<AsteriskSubtitle />}>
        <Button
          aria-label={t`Save Edit Integration`}
          id="btn-save-integration"
          text={t`Save`}
          disabled={saveBtnStatus}
          onClick={handleSave}
        />
        <Button
          aria-label={t`Cancel`}
          text={t`Cancel`}
          kind="secondary"
          onClick={() =>
            navigate(`/integrations/${integration?.id}`)
          } /* istanbul ignore */
        />
      </PageTitle>

      <WrapperWithLoading loading={loading}>
        <PaddedForm>
          <Grid.Container>
            <BasicInformation
              handleForm={handleForm}
              integration={integration}
              categories={categories}
            />
            <Scope
              handleForm={handleForm}
              serviceNetworkItems={serviceNetworkItems}
              integration={integration}
              editMode
            />
            <Trigger
              handleForm={handleForm}
              applicationEventsItems={applicationEventsItems}
              triggerTypeHttpRequest={triggerTypeHttpRequest}
              triggerTypeApplicationEvent={triggerTypeApplicationEvent}
              integration={integration}
              editMode
            />
            <Commands
              handleForm={handleForm}
              commands={commands}
              commandsFlow={commandsFlow}
              categoryActions={categoryActions}
              triggerTypeHttpRequest={triggerTypeHttpRequest}
              editMode
              integration={integration}
            />
          </Grid.Container>
        </PaddedForm>
      </WrapperWithLoading>
    </>
  );
}
