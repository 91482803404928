import React from 'react';
import { Router } from '@reach/router';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages } from './locales/en/messages';
import Routes from './routes/Routes';

i18n.loadLocaleData('en', { plurals: 'en' });
i18n.load('en', messages);
i18n.activate('en');

function App() {
  return (
    <I18nProvider i18n={i18n}>
      <div className="App">
        <Router>
          <Routes path="*" />
        </Router>
      </div>
    </I18nProvider>
  );
}

export default App;
