const getEnvironment = (url) => {
  const regex = /integrate\.(qa|staging)\.decisivapps\.com/;
  const match = url.match(regex);

  if (match) {
    return match[1];
  }

  return 'production';
};

export default getEnvironment;
