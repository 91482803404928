import toColorString from 'polished/lib/color/toColorString';
import styled from 'styled-components';
import color from '@decisiv/design-tokens/lib/color';

const PaddedForm = styled.form`
  padding: 0 1.25rem;

  & > div > div {
    background: ${toColorString(color.base.snowWhite)};
    margin-bottom: 1.25rem;
  }
`;

export default PaddedForm;
