import React, { useState } from 'react';
import Grid from '@decisiv/iconix/lib/components/Grid';
import { Flex, H3, H4, Badge } from '@decisiv/ui-components';
import { Trans } from '@lingui/macro';
import IntegrationContainer from '../../containers/IntegrationContainer/index';
import HomePageTitle from '../../components/PageTitle/HomePageTitle';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import { IntegrationsWrapper } from '../../containers/IntegrationContainer/IntegrationsWrapper.styled';

export default function IntegrationDetailPage() {
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);

  return (
    <>
      <HomePageTitle
        setLoading={setLoading}
        setIntegrations={setIntegrations}
      />
      <WrapperWithLoading loading={loading}>
        {integrations.length > 0 ? (
          <IntegrationsWrapper title="integrations-container">
            {integrations.map(({ id, attributes }) => {
              return (
                <IntegrationContainer
                  key={attributes.name}
                  name={attributes.name}
                  status={attributes.enabled ? 'Active' : 'Inactive'}
                  published={attributes.published ? 'Published' : 'Unpublished'}
                  description={attributes.description}
                  locationUrl={`${id}`}
                  audience={attributes.audience}
                  serviceNetwork={attributes.service_network}
                  triggerType={attributes.trigger_type}
                />
              );
            })}
          </IntegrationsWrapper>
        ) : (
          <>
            <Flex
              justifyContent="center"
              style={{
                width: '100%',
                'background-color': '#fff',
              }}
            >
              <Flex marginTop={3} flexDirection="column">
                <Flex alignSelf="center">
                  <Badge
                    key="key"
                    aria-label="an example badge"
                    color="licoriceMousse"
                    icon={Grid}
                    size="large"
                    palette="bright"
                  />
                </Flex>
                <Flex alignSelf="center">
                  <H3>
                    <Trans>No Integrations Found</Trans>
                  </H3>
                </Flex>
                <Flex alignSelf="center">
                  <H4 color="alaskanHusky">
                    <Trans>
                      Try removing or editing some of your filters or search
                      criteria to expand your search.
                    </Trans>
                  </H4>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              justifyContent="center"
              style={{
                width: '100%',
                height: '100vh',
                'background-color': '#fff',
              }}
            />
          </>
        )}
      </WrapperWithLoading>
    </>
  );
}
