import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { H2 } from '@decisiv/ui-components';
import iconix from '@decisiv/iconix';

import { NoResultDescription, CenteredContainer } from './style';

export default function NoLocationsFound({
  filterSearch,
  filterOem,
  filterDealerType,
}) {
  const customSearch = () => {
    const customFilterOem = filterOem.filter(
      (selectedValue) => selectedValue !== 'all',
    );
    const customFilterDealerType = filterDealerType.filter(
      (selectedValue) => selectedValue !== 'all',
    );

    return customFilterOem.length > 0 || customFilterDealerType.length > 0;
  };

  return (
    <CenteredContainer flexDirection="column" alignItems="center">
      <iconix.Search height={32} width={32} />
      <H2 marginTop={1} marginBottom={1}>
        <Trans>No matches for &ldquo;{filterSearch}&rdquo;</Trans>
      </H2>
      <NoResultDescription shade={1}>
        {customSearch() ? (
          <Trans>
            Try removing or editing some of your filters to expand your search.
          </Trans>
        ) : (
          <Trans>
            Check that all words are spelled correctly or search by a different
            value.
          </Trans>
        )}
      </NoResultDescription>
    </CenteredContainer>
  );
}

NoLocationsFound.propTypes = {
  filterSearch: PropTypes.string.isRequired,
  filterOem: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterDealerType: PropTypes.arrayOf(PropTypes.string).isRequired,
};
