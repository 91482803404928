import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Filter from '@decisiv/ui-components/lib/components/Filter';
import spacing from '@decisiv/design-tokens/lib/spacing';
import rem from 'polished/lib/helpers/rem';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import { Code, Grid } from '@decisiv/ui-components';

export const CommandSection = styled(Flex)`
  gap: 0.313rem;
  width: 100%;
  background: ${toColorString(color.base.snowWhite)};
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const LineDivider = styled.div`
  width: 100%;
  height: ${rem(spacing.base * 0.1)};
  background: ${toColorString(color.opacity.charcoal15)};
`;

export const LineVerticalDivider = styled.div`
  width: ${rem(spacing.base * 0.1)};
  height: auto;
  background: ${toColorString(color.opacity.charcoal15)};
`;

export const FilterTextField = styled(TextField)`
  width: 310px;
`;

export const CommandSearchSection = styled(Flex)`
  width: 370px;
  height: calc(100vh - 190px);
  align-self: stretch;
  background: ${toColorString(color.base.snowWhite)};
  overflow: auto;
`;

export const DeveloperSectionHead = styled(Flex)`
  gap: 0.313rem;
  width: 100%;
  align-self: stretch;
  padding: 15px;
  background: ${toColorString(color.status.information.dark)};
  color: white;
`;

export const DeveloperSection = styled(Flex)`
  gap: 0.313rem;
  align-self: stretch;
  padding: 15px;
  height: calc(100vh - 365px);
  background: ${toColorString(color.base.snowWhite)};
  overflow: auto;
`;

export const FilterOption = styled(Filter)`
  width: 100%;
  border-top-style: solid;
  border-top-color: ${toColorString(color.opacity.charcoal15)};
`;

export const CommandLabel = styled(Flex)`
  width: 100%;
  padding: 10px;
  gap: 10px;
  flex-grow: 0;
`;

export const DisplayCode = styled(Code)`
  background: ${toColorString(color.base.fullMoon)};
  color: ${toColorString(color.base.alaskanHusky)};
  padding: 10px;
`;

export const DeveloperCodeView = styled(Code)`
  background: ${toColorString(color.base.blackSheep)};
  color: ${toColorString(color.base.snowWhite)};
  margin: 0;
  padding: 20px;
  letter-spacing: -0.8px;
  white-space: pre-wrap;
  overflow: auto;
`;

export const Stack = styled(Flex)`
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const SectionRow = styled(Grid.Row).attrs({
  paddingX: 1,
  paddingY: 1.5,
})`
  background-color: ${toColorString(color.base.snowWhite)};
  border: 1px solid ${toColorString(color.opacity.charcoal15)};
`;
