import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import Flex from '@decisiv/ui-components/lib/components/Flex';

const IntegrationContainerWrapper = styled(Flex)`
  flex-basis: 31.5%;
  flex-shrink: 0;
  word-break: break-word;
  background-color: ${toColorString(color.base.snowWhite)};
`;

export { IntegrationContainerWrapper };
