import React from 'react';

import { PropTypes } from 'prop-types';

import { t } from '@lingui/macro';
import iconix from '@decisiv/iconix';
import { Button, Flex, Tooltip } from '@decisiv/ui-components';

const CommandAddButton = (props) => {
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      style={{
        border: '1px solid #e3ebf1',
        margin: '-1px',
        gap: '1.25rem',
        padding: '0.625rem',
      }}
    >
      {props.disabled && props.emptyCommand ? (
        <Tooltip
          target={
            <div>
              <Button
                aria-label={t`Add Another Command`}
                text={t`Add Another Command`}
                icon={iconix.PlusCircle}
                variant="ghost"
                onClick={props.onClick}
                disabled={props.disabled}
              />
            </div>
          }
          placement="right"
        >
          No connecting commands available
        </Tooltip>
      ) : (
        <Button
          aria-label={t`Add Another Command`}
          text={t`Add Another Command`}
          icon={iconix.PlusCircle}
          variant="ghost"
          onClick={props.onClick}
          disabled={props.disabled}
        />
      )}
    </Flex>
  );
};

CommandAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  emptyCommand: PropTypes.bool,
};

CommandAddButton.defaultProps = {
  disabled: true,
  emptyCommand: false,
};

export default CommandAddButton;
