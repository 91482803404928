import React, { useMemo, useRef, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import PropTypes from 'prop-types';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import Button from '@decisiv/ui-components/lib/components/Button';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import { Alert, Grid, P } from '@decisiv/ui-components';

import iconx from '@decisiv/iconix';

import _ from 'lodash';

import { t, Trans } from '@lingui/macro';

import noop from 'lodash/noop';
import fileToText from '../../../utils/fileToText';
import WrapperWithLoading from '../../../components/WrapperWithLoading';
import { GreyishFlex } from '../../../components/StyledComponents';

import IntegrationsService from '../../../api/integrations';
import SrmAccountsService from '../../../api/srm_accounts';
import LocationsResult from './LocationsResult';
import AddMultipleLocations from './AddMultipleLocations';
import { HelpInputMessage, HelpInputText } from './styles';

export default function AddLocationModal(props) {
  const [filterSearch, setFilterSearch] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [multipleLocationsMode, setMultipleLocationsMode] = useState(false);
  const [readyToSearch, setReadyToSearch] = useState(filterSearch !== '');
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(0);
  const params = useParams();
  const inputRef = useRef(null);
  const [fileToUpload, setFileToUpload] = useState({});
  const [postingLocations, setPostingLocations] = useState(false);
  const [addLocations, setAddLocations] = useState(false);
  const [invalidFileMessage, setInvalidFileMessage] = useState('');
  const [multipleLocationData, setMultipleLocationData] = useState({});
  const [currentLocationUpload, setCurrentLocationUpload] = useState(0);
  const [uploadTotal, setUploadTotal] = useState(0);
  const [reportFile, setReportFile] = useState('');

  const search = (e) => {
    e.preventDefault();

    setReadyToSearch(true);
    setLoading(true);

    async function getSrmAccounts() {
      const response = await SrmAccountsService.fetchSrmAccounts(filterSearch);

      if (response.data) {
        setLocations(response.data);
      } else {
        setLocations([]);
      }
      setLoading(false);
    }

    getSrmAccounts();
  };

  const submitIntegration = async () => {
    setShowAlert(false);

    const schema = props.integration.attributes.location_settings_schema;

    const locationSettingsAttributes = _.chain(schema).map((attribute) => {
      return [attribute.name, attribute.type];
    });

    const defaultLocationSettingsAttributes = [];

    locationSettingsAttributes.value().map((element) => {
      let defaultValue = '';
      switch (element[1]) {
        case 'array':
          defaultValue = [];
          break;
        case 'object':
          defaultValue = {};
          break;
        default:
          defaultValue = '';
      }

      defaultLocationSettingsAttributes.push({
        name: element[0],
        value: defaultValue,
      });

      return defaultLocationSettingsAttributes;
    });

    const jsonBody = {
      srm_account_id: selectedLocationId,
      integration_uuid: params.id,
      location_settings_attributes: defaultLocationSettingsAttributes,
    };

    const response = await IntegrationsService.addIntegrationToLocation(
      jsonBody,
    );

    /* istanbul ignore else */
    if (response.data) {
      navigate(`/location_settings/${response.data.id}`);
    } else {
      setShowAlert(true);
    }
  };

  const submitMultipleLocations = async () => {
    setPostingLocations(true);
    // Convert uploaded CSV File to Text
    const csvText = await fileToText(fileToUpload);
    const csvHeaders = csvText[0].split(',');
    setUploadTotal(csvText.length - 1);
    const multipleLocationResponse = {};
    multipleLocationResponse.data = [];
    multipleLocationResponse.successCount = 0;
    multipleLocationResponse.errorCount = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const row of csvText) {
      const index = csvText.indexOf(row);
      if (index === 0) {
        // eslint-disable-next-line no-continue
        continue;
      }

      setCurrentLocationUpload(index);
      const jsonBody = {};
      jsonBody.location_settings_attributes = [];
      jsonBody.integration_uuid = params?.id;

      row.split(',').forEach((value, i) => {
        const key = csvHeaders[i]
          ?.replace('*', '')
          ?.replace(/ /g, '')
          ?.replace(/\r/, '')
          ?.trim();
        if (key === undefined) return;

        const parsedValue = value?.replace(/\r/, '').trim();

        if (value.startsWith('[') || value.startsWith('{')) {
          try {
            // eslint-disable-next-line no-param-reassign
            value = JSON.parse(parsedValue);
            // eslint-disable-next-line no-empty
          } catch {}
        }

        if (/[0-9]+$/.test(key.toString())) {
          jsonBody.location_settings_attributes.push({
            name: key,
            value: parsedValue,
          });
        } else {
          jsonBody[key] = parsedValue;
        }
      });

      // eslint-disable-next-line no-await-in-loop
      const response = await IntegrationsService.addIntegrationToLocation(
        jsonBody,
      );

      if (response.data) {
        multipleLocationResponse.successCount += 1;
        // Create a new column on CSV To save Success
        csvText[index] = csvText[index].concat(`, true \n`);
      } else {
        multipleLocationResponse.errorCount += 1;
        // Create a new column on CSV To save Failure, False - Error Message
        csvText[index] = csvText[index].concat(
          `, false - ${response.errors[0].detail} \n`,
        );
      }
    }

    // Add new header to CSV
    csvText[0] = csvText[0].concat(', success? \n');

    setReportFile(csvText);

    setMultipleLocationData(multipleLocationResponse);

    setPostingLocations(false);
  };

  const resetModalState = () => {
    setFileToUpload({});
    setInvalidFileMessage('');
    inputRef.current.value = '';
    setSelectedLocationId('');
    setFilterSearch('');
    setReadyToSearch(false);
    setLocations([]);
    setShowAlert(false);
    setPostingLocations(false);
    setAddLocations(false);
  };

  const handleLocationsMode = () => {
    setMultipleLocationsMode(!multipleLocationsMode);
  };

  const handleCloseModal = () => {
    props.setVisibleModal(false);
    setMultipleLocationsMode(false);
    resetModalState();
  };

  const handleAddLocation = () => {
    if (multipleLocationsMode) {
      handleCloseModal();
      setAddLocations(true);
      submitMultipleLocations();
    } else {
      submitIntegration();
    }
  };

  const handleDownloadTemplate = async () => {
    const response = await IntegrationsService.fetchTemplateFile(params.id);

    /* istanbul ignore else */
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${props.integration.attributes.name
          .replace(/\s+/g, '-')
          .toLowerCase()}-bulk-enabling.csv`,
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleRemovalOfSelectedFile = () => {
    setFileToUpload({});
    setInvalidFileMessage('');
    inputRef.current.value = '';
  };

  const handleBrowseFile = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const maxFileSizeInMb = 5;
    const maxFileSize = 1024 * 1024 * maxFileSizeInMb;
    const file = e.target.files && e.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > maxFileSize) {
      setInvalidFileMessage(
        t`${file.name} exceeds the max file size of ${maxFileSizeInMb}Mb.`,
      );
      return;
    }

    if (file.type !== 'text/csv') {
      setInvalidFileMessage(t`The file format for ${file.name} is invalid.`);
      return;
    }

    setFileToUpload(file);
  };

  const isFilePresent = useMemo(() => {
    return fileToUpload && fileToUpload.name;
  }, [fileToUpload]);

  const locationLegend = useMemo(() => {
    return multipleLocationsMode ? 'locations' : 'location';
  }, [multipleLocationsMode]);

  const trashWithLegend = () => {
    return (
      <Flex alignItems="center">
        <iconx.Trash />
        <P marginLeft={1}>{t`REMOVE`}</P>
      </Flex>
    );
  };

  return (
    <>
      <Modal
        actions={[
          {
            text: multipleLocationsMode ? t`Enable Locations` : t`Add Location`,
            onClick: handleAddLocation,
            disabled:
              (selectedLocationId === '' || selectedLocationId === 0) &&
              !isFilePresent,
          },
          {
            text: t`Cancel`,
            onClick: handleCloseModal,
          },
        ]}
        icon={iconx.PlusCircle}
        color="information"
        onClose={handleCloseModal}
        title={t`Add Locations`}
        visible={props.visibleModal}
      >
        <Flex flexDirection="column">
          <P shade={1} marginTop={1}>
            <Trans>
              Choose either &quot;Single Location&quot; to search for and add a
              single location to the list or select &quot;Multiple
              Locations&quot; to upload a bulk file containing multiple
              locations. To upload a batch file, you must first{' '}
              <a
                onClick={handleDownloadTemplate}
                href="/"
                target="_blank"
                download="download-csv-template.csv"
                rel="noopener noreferrer"
              >
                download a template
              </a>{' '}
              for {props.integration.attributes.name}. Once added, configure the
              settings and status for each one individually.
            </Trans>

            {showAlert ? (
              <Flex className="WideFlex">
                <Flex flex={1}>
                  <Trans>
                    <Alert
                      title="Error"
                      description={t`There was an error adding the integration to the ${locationLegend}`}
                      intent="danger"
                    />
                  </Trans>
                </Flex>
              </Flex>
            ) : null}
          </P>

          {!isFilePresent && (
            <Grid.Row marginTop={1.7} marginBottom={2}>
              <Grid.Column size="MD" span="10">
                <RadioGroup
                  name="radio2"
                  size="medium"
                  items={[
                    { label: t`Single Location`, value: 1 },
                    { label: t`Multiple Locations`, value: 2 },
                  ]}
                  defaultValue={multipleLocationsMode ? 2 : 1}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={handleLocationsMode}
                />
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            {!multipleLocationsMode ? (
              <>
                <Grid.Column size="MD" span="10">
                  <TextField
                    icon={iconx.Search}
                    label="searchInput"
                    hideLabel
                    name="input-ex-1"
                    onChange={(e) => {
                      setReadyToSearch(false);
                      setSelectedLocationId('');
                      setFilterSearch(e.target.value);
                    }}
                    value={filterSearch}
                  />

                  <HelpInputMessage alignItems="center">
                    <iconx.ExclamationCircleF />
                    <HelpInputText shade={1}>
                      <Trans>Search by Decisiv Dealer or SRM Account ID</Trans>
                    </HelpInputText>
                  </HelpInputMessage>
                </Grid.Column>

                <Grid.Column size="MD" span="2">
                  <Button
                    text={t`Search`}
                    size="medium"
                    type="submit"
                    onClick={search}
                  />
                </Grid.Column>
              </>
            ) : (
              <>
                {!isFilePresent && (
                  <>
                    {!_.isEmpty(invalidFileMessage) && (
                      <Grid.Column size="MD" span="12" marginBottom={1}>
                        <Alert intent="warning" title={invalidFileMessage} />
                      </Grid.Column>
                    )}
                    <Grid.Column size="MD" span="10">
                      <P shade={1} marginBottom={1}>
                        {t`To upload files from your workstation click BROWSE.`}
                      </P>
                    </Grid.Column>
                    <Grid.Column size="MD" span="12">
                      <Button
                        text={t`Browse`}
                        icon={iconx.Paperclip}
                        type="submit"
                        kind="secondary"
                        minWidth="100%"
                        size="small"
                        onClick={handleBrowseFile}
                      />
                    </Grid.Column>
                  </>
                )}
              </>
            )}
          </Grid.Row>

          {readyToSearch && !multipleLocationsMode ? (
            <WrapperWithLoading loading={loading}>
              <LocationsResult
                integration={props.integration.attributes}
                currentIntegrationLocations={props.locations}
                setSelectedLocationId={setSelectedLocationId}
                filterSearch={filterSearch}
                locations={locations}
                selectedLocationId={selectedLocationId}
              />
            </WrapperWithLoading>
          ) : (
            ''
          )}

          {isFilePresent && (
            <GreyishFlex>
              <P weight="semibold">{fileToUpload.name}</P>
              <Button
                icon={trashWithLegend}
                variant="ghost"
                onClick={handleRemovalOfSelectedFile}
                text=""
              />
            </GreyishFlex>
          )}
        </Flex>
      </Modal>

      <Modal visible={addLocations}>
        <WrapperWithLoading
          loading={postingLocations}
          description={t`Adding locations`}
        />
      </Modal>

      <AddMultipleLocations
        multipleLocationsResponseData={multipleLocationData}
        visible={addLocations}
        isPosting={postingLocations}
        integrationName={props.integration.attributes.name}
        onClose={handleCloseModal}
        setRefresh={props.setRefresh}
        currentLocationUpload={currentLocationUpload}
        uploadTotal={uploadTotal}
        reportFile={reportFile}
      />

      <input
        type="file"
        data-testid="fileInput"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept=".csv"
      />
    </>
  );
}

AddLocationModal.defaultProps = {
  integration: {},
  setRefresh: noop,
  locations: [],
};

AddLocationModal.propTypes = {
  locations: PropTypes.oneOfType([PropTypes.array]),
  setVisibleModal: PropTypes.func.isRequired,
  visibleModal: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  integration: PropTypes.objectOf(PropTypes.any),
  setRefresh: PropTypes.func,
};
