import React from 'react';
import { PropTypes } from 'prop-types';
import { Flex, H1 } from '@decisiv/ui-components';

import { PageTitleWrapper, TitleActionsContainer } from './PageTile.styled';

export default function PageTitle({ title, subtitle, children }) {
  return (
    <PageTitleWrapper style={{ padding: '1rem', marginBottom: '10px' }}>
      <Flex flexDirection="row">
        <H1 weight="regular" marginRight={1}>
          {title}
          {subtitle}
        </H1>
      </Flex>
      {children && <TitleActionsContainer>{children}</TitleActionsContainer>}
    </PageTitleWrapper>
  );
}

PageTitle.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.element,
};

PageTitle.defaultProps = {
  children: null,
  subtitle: null,
};
