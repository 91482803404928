import api from './api';

const fetchStepFunctionExecutions = async (traceId) => {
  try {
    const { data } = await api.get(
      `/log-inspector?filter[x_decisiv_trace_id]=${traceId}`,
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchStepFunctionExecutionsByIntegrationId = async (integrationId) => {
  try {
    const { data } = await api.get(
      `/log-inspector?filter[integration_id]=${integrationId}`,
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

export default {
  fetchStepFunctionExecutions,
  fetchStepFunctionExecutionsByIntegrationId,
};
