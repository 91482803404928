import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  P,
  Button,
  Flex,
  H3,
  SelectMenu,
  Pagination,
} from '@decisiv/ui-components';
import { t, Trans } from '@lingui/macro';
import iconix from '@decisiv/iconix/lib/components';
import Table from '@decisiv/ui-components/lib/components/Table';
import Tag from '@decisiv/ui-components/lib/components/Tag';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import { Link } from '@reach/router';

import Label from '../../components/Label';
import { dealerTypeOptions, oemOptions } from '../../utils/constants';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import NoLocationsFound from './NoLocationsFound';
import {
  FilterTextField,
  HelpInputMessage,
  HelpInputText,
  Separator,
} from './style';

export default function LocationsResult({
  setLoading,
  filterSearch,
  setFilterSearch,
  locations,
  loading,
  filterOem,
  setFilterOem,
  setFilterDealerType,
  filterDealerType,
  setVisibleModal,
}) {
  const [currentPageLocations, setCurrentPageLocations] = useState([]);
  const [sortedLocations, setSortedLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [totalLocationsPage, setTotalLocationsPage] = useState(0);

  const pageChangeHandler = (newPageNumber) => {
    setCurrentPage(newPageNumber);

    let currentLocations = [];

    if (isEmpty(sortedLocations)) {
      currentLocations = locations;
    } else {
      currentLocations = sortedLocations;
    }
    // Calculate the starting index and ending index for the specified page
    const startIndex = (newPageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Use slice to get the portion of the array for the specified page
    const pageLocations = currentLocations.slice(startIndex, endIndex);
    setTotalLocationsPage(Math.ceil(currentLocations?.length / itemsPerPage));
    setCurrentPageLocations(pageLocations);
  };

  useEffect(() => {
    setItemsPerPage(25);
    setSortedLocations(locations);
  }, [locations]);

  useEffect(() => {
    pageChangeHandler(1);
  }, [sortedLocations]);

  const onSort = useCallback(
    (columnName) => (sortingType) => {
      switch (sortingType) {
        case 'ascending':
          setSortedLocations(sortBy(locations, [columnName]));
          return;
        case 'descending':
          setSortedLocations(reverse(sortBy(locations, [columnName])));
          return;
        case 'unsorted':
        default:
          setSortedLocations(locations);
      }
    },
    [locations, setSortedLocations],
  );

  const columns = [
    {
      DataCell: (data) => <span>{data.rowData.attributes.srm_account_id}</span>,
      sortBy: onSort('attributes.srm_account_id'),
      title: 'SRM ACCOUNT ID',
      name: 'srm_account_id',
    },
    {
      DataCell: (data) => (
        <Link to={`/location_settings/${data.rowData.id}`}>
          {data.rowData.attributes.name}
        </Link>
      ),
      sortBy: onSort('attributes.name'),
      title: 'NAME',
      name: 'name',
    },
    {
      DataCell: (data) => (
        <span>
          {data.rowData.attributes.city}, {data.rowData.attributes.state}
        </span>
      ),
      sortBy: onSort('attributes.city'),
      title: 'CITY/STATE',
      name: 'city',
    },
    {
      DataCell: (data) => (
        <span>{data.rowData.attributes.oems?.join(',').toUpperCase()}</span>
      ),
      sortBy: onSort('attributes.oems'),
      title: 'OEM',
      name: 'oem',
    },
    {
      DataCell: (data) => <span>{data.rowData.attributes.account_type}</span>,
      sortBy: onSort('attributes.account_type'),
      title: 'TYPE',
      name: 'account_type',
    },
    {
      DataCell: (data) => (
        <Tag
          key={data.rowData.attributes.id}
          text={data.rowData.attributes.enabled ? 'Enabled' : 'Disabled'}
          color={data.rowData.attributes.enabled ? 'success' : 'licoriceMousse'}
        />
      ),
      sortBy: onSort('attributes.enabled'),
      title: 'STATUS',
      name: 'status',
    },
  ];

  const handleChangeOem = (e) => {
    setLoading(true);
    setFilterOem(e);
    if (e.length === 0 || e.includes('all')) {
      setSortedLocations(locations);
    } else {
      setSortedLocations(
        locations.filter((location) =>
          location.attributes.oems.some((r) => e.indexOf(r) >= 0),
        ),
      );
    }
    setLoading(false);
  };

  const handleChangeDealerType = (e) => {
    setLoading(true);
    setFilterDealerType(e);

    if (e.length === 0 || e.includes('all')) {
      setSortedLocations(locations);
    } else {
      setSortedLocations(
        locations.filter((location) =>
          e.some((i) => i === location.attributes.account_type?.toLowerCase()),
        ),
      );
    }
    setLoading(false);
  };

  const handleChangeFilterSearch = (e) => {
    setLoading(true);
    setFilterSearch(e.target.value);

    if (isEmpty(e.target.value)) {
      setSortedLocations(locations);
    } else {
      const filterResultName = locations.filter((location) =>
        location.attributes.name
          ?.toLowerCase()
          .includes(e.target.value?.toLowerCase()),
      );

      const filterResultCity = locations.filter((location) =>
        location.attributes.city
          ?.toLowerCase()
          .includes(e.target.value?.toLowerCase()),
      );

      const filterResultAccount = locations.filter((location) =>
        location.attributes.srm_account_id
          ?.toLowerCase()
          .includes(e.target.value?.toLowerCase()),
      );

      const concatedResult = [
        ...new Set([
          ...filterResultName,
          ...filterResultCity,
          ...filterResultAccount,
        ]),
      ];

      setSortedLocations(concatedResult);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  return (
    <Flex flexDirection="column" name="locationResult" flexGrow={1}>
      <Flex name="searchBar">
        <Flex
          marginTop={2}
          marginLeft={2}
          marginRight={2}
          name="topBar"
          flexGrow={1}
        >
          <Flex name="searchBox">
            <Flex>
              <H3 marginRight={2} marginTop={0.2}>
                <Trans>Locations</Trans>
              </H3>
              <Separator />
            </Flex>
            <Flex flexDirection="column">
              <FilterTextField
                width={32}
                icon={iconix.Search}
                label={t`searchInput`}
                hideLabel
                value={filterSearch}
                onChange={handleChangeFilterSearch}
              />
              <HelpInputMessage alignItems="center">
                <iconix.ExclamationCircleF />
                <HelpInputText shade={1}>
                  <Trans>Search by SRM Account ID, name or city</Trans>
                </HelpInputText>
              </HelpInputMessage>
            </Flex>
          </Flex>
          <Flex
            flexDirection="row"
            name="filters"
            marginLeft={1.9}
            marginBottom={5}
          >
            <Flex>
              <Label shade={1}>{t`OEM`}:</Label>
              <SelectMenu
                multiple
                label=""
                name="select-oem"
                options={oemOptions}
                value={filterOem}
                onChange={handleChangeOem}
                zIndex={999}
              />
            </Flex>

            <Flex marginLeft={1}>
              <Label shade={1}>{t`Dealer Type`}:</Label>
              <SelectMenu
                multiple
                label=""
                name="select-dealer-type"
                options={dealerTypeOptions}
                value={filterDealerType}
                onChange={(label) => handleChangeDealerType(label)}
                zIndex={999}
              />
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end" flexGrow={1}>
            <Button
              icon={iconix.PlusCircle}
              text={t`ADD LOCATION`}
              kind="secondary"
              onClick={() => setVisibleModal(true)}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex name="searchResult">
        <WrapperWithLoading loading={loading}>
          {isEmpty(sortedLocations) ? (
            <NoLocationsFound
              filterSearch={filterSearch}
              filterOem={filterOem}
              filterDealerType={filterDealerType}
            />
          ) : (
            <Flex flexGrow={1} flexDirection="column">
              <Table
                columns={columns}
                data={currentPageLocations}
                kind="secondary"
                getRowKey={({ id }) => id}
                footer={() => (
                  <Flex
                    flex={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex>
                      <P>
                        1 - {itemsPerPage} of {sortedLocations.length} Locations
                      </P>
                    </Flex>
                    <Pagination
                      totalPages={totalLocationsPage}
                      onPageChange={pageChangeHandler}
                      activePage={currentPage}
                    />
                  </Flex>
                )}
              />
            </Flex>
          )}
        </WrapperWithLoading>
      </Flex>
    </Flex>
  );
}

LocationsResult.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  filterSearch: PropTypes.string.isRequired,
  setFilterSearch: PropTypes.func.isRequired,
  filterOem: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilterOem: PropTypes.func.isRequired,
  setFilterDealerType: PropTypes.func.isRequired,
  filterDealerType: PropTypes.arrayOf(PropTypes.string).isRequired,
  setVisibleModal: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      oem: PropTypes.string,
      type: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
    }),
  ).isRequired,
};
