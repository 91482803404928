import { t } from '@lingui/macro';
import { normalizeCommandName } from './titlelizeCommand';

/**
 *
 * @param {String} commandName. Eg. FindCaseCommand1
 * @param {Object} schemasByCommand.
 * Eg {
 *  FindCaseCommand: {
 *    input_schema: {
 *      ..,
 *      example: {}
 *    },
 *    output_schema: {
 *      ..,
 *       example: {}
 *    }
 *  },
 *  MapParametersCommand: {
 *    ...
 *  }
 * }
 * @param {Object} triggerTypeSchemas. Eg
 *  {
 *    application_events: [
 *      {
 *        ...decisiv:asset_maganement:case:closed fields/values
 *      }
 *    ],
 *    http_api_request: {
 *       ...retrieve_warranty_information fields/values
 *    }
 *  }
 * @returns {Object}. Eg
 * {
 *  commandName: 'Find Case',
 *  input: { FindCaseCommand required input fields/value },
 *  output: { FindCaseCommand output }
 * }
 */
export const buildSchemaContent = (
  commandName,
  schemasByCommand,
  triggerTypeSchemas,
) => {
  const name = commandName.replace(/\d/g, '');
  const defaultStringContent = t`This integration does not support this trigger type`;

  // When the content is for a command

  if (schemasByCommand[name]) {
    return {
      commandName: normalizeCommandName(commandName),
      input: JSON.stringify(schemasByCommand[name].input_schema.example),
      output: JSON.stringify(schemasByCommand[name].output_schema.example),
    };
  }

  // When the content is for the first InputPayload information
  return {
    commandName: normalizeCommandName(commandName),
    input: JSON.stringify(
      triggerTypeSchemas.application_events.length > 0
        ? triggerTypeSchemas.application_events.map(
            (applicationEvent) => applicationEvent.example,
          )
        : defaultStringContent,
    ),
    inputTitle: t`Application Events`,
    output: JSON.stringify(
      triggerTypeSchemas.http_api_request?.example || defaultStringContent,
    ),
    outputTitle: t`HTTP API Request`,
  };
};
