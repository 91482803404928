import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { P, RadioGroup } from '@decisiv/ui-components';

export default function AudienceFilter({ handleForm, currentValue }) {
  return (
    <>
      <P marginBottom={1}>
        <Trans>Audience</Trans>
      </P>
      <RadioGroup
        name="radio_audience"
        items={[
          { label: t`Both`, value: 'Both' },
          { label: t`Asset Management`, value: 'Asset Management' },
          { label: t`Service Management`, value: 'Service Management' },
        ]}
        vertical
        value={currentValue}
        onChange={(e) => {
          handleForm({ event: e, field: 'audience' });
        }}
      />
    </>
  );
}

AudienceFilter.propTypes = {
  handleForm: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
};
