import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { t } from '@lingui/macro';
import { Wrapper } from './contentWrapper.styled';

export default function ContentWrapper({ content }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Wrapper>
        {showMore ? content : `${content.substring(0, 250)}`}
        {content.length > 250 && (
          <Flex justifyContent="right">
            <Button
              text={showMore ? t`See less` : t`See more`}
              size="small"
              variant="ghost"
              onClick={() => setShowMore(!showMore)}
            />
          </Flex>
        )}
      </Wrapper>
    </>
  );
}

ContentWrapper.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
