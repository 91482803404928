import React from 'react';
import { PropTypes } from 'prop-types';

import { t } from '@lingui/macro';

import { Flex, Modal } from '@decisiv/ui-components';
import Newspaper from '@decisiv/iconix/lib/components/Newspaper';

import ContentAccordion from '../ContentAccordion';

export default function SchemaDetailsModal({
  content,
  visibility,
  handleVisibility,
}) {
  const title = `${content.commandName} - `;
  return (
    <Modal
      actions={[
        {
          text: t`Close`,
          onClick: () => handleVisibility(false),
          kind: 'secondary',
        },
      ]}
      intent="information"
      icon={Newspaper}
      title={title + t`Schemas Example`}
      visible={visibility}
      onClose={() => handleVisibility(false)}
    >
      <Flex flexDirection="column">
        <Flex
          marginTop={2}
          flexDirection="row"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Flex marginTop={1}>
            <ContentAccordion content={content} />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}

SchemaDetailsModal.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  visibility: PropTypes.bool.isRequired,
  handleVisibility: PropTypes.func.isRequired,
};
