import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import rem from 'polished/lib/helpers/rem';
import spacing from '@decisiv/design-tokens/lib/spacing';

export const TitleActionsContainer = styled(Flex)`
  gap: 10px;
  margin: auto 5px;
`;

export const PageTitleWrapper = styled.div`
  width: 100%;
  background-color: ${toColorString(color.base.snowWhite)};
  line-height: 120%;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const FilterTextField = styled(TextField)`
  width: ${rem(spacing.base * 32)};
`;

export const Separator = styled(Flex)`
  width: 2px;
  height: ${rem(spacing.base * 1.5)};
  margin-right: ${rem(spacing.base * 1.5)};
  margin-top: ${rem(spacing.base)};
  background: ${toColorString(color.opacity.charcoal15)};
`;
