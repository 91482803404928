import React from 'react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Button, Flex, Grid, H3, P, TextField } from '@decisiv/ui-components';
import MinusCircle from '@decisiv/iconix/lib/components/MinusCircle';
import PlusCircle from '@decisiv/iconix/lib/components/PlusCircle';
import SectionRow from '../../components/SectionRow';

export default function Metadata({
  handleForm,
  metadataInputs,
  setMetadataInputs,
  setForm,
  form,
  enabledAddMetadataInput,
  setEnabledAddMetadataInput,
}) {
  const handleAddInput = () => {
    const newForm = form;
    newForm.metadata.push({ key: '', value: '' });

    setMetadataInputs([...newForm.metadata]);
    setForm(newForm);
  };

  const removeInput = (index) => {
    const inputs = [...metadataInputs];
    const newForm = form;

    newForm.metadata.splice(index, 1);
    inputs.splice(index, 1);

    setForm(newForm);
    setMetadataInputs(inputs);

    if (inputs.length === 0) {
      setEnabledAddMetadataInput(true);
    }
  };

  const lastEntryWithoutKey =
    form.metadata[form.metadata.length - 1]?.key === '';

  return (
    <>
      <SectionRow>
        <Grid.Column padding={0} span="3">
          <H3>
            <Trans>Metadata</Trans>
          </H3>
        </Grid.Column>
        <Grid.Column padding={0}>
          <P shade={1} size="small">
            <Trans>Define Integrations Metadata.</Trans>
          </P>
          <div style={{ marginTop: '0.625rem' }}>
            {metadataInputs.map((input, index) => {
              return (
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    whiteSpace: 'nowrap',
                    gap: '1.25rem',
                    margin: '-1px',
                    border: '1px solid #e3ebf1',
                    padding: '0.625rem',
                  }}
                >
                  <Button
                    aria-label={t`Remove entry`}
                    variant="ghost"
                    icon={MinusCircle}
                    onClick={() => removeInput(index)}
                  />
                  <TextField
                    required
                    value={input.key}
                    label={t`key`}
                    name={`key-${index}`}
                    style={{ marginTop: '0.5rem' }}
                    maxLength={50}
                    showCharacterCount
                    onChange={(e) => {
                      handleForm({
                        event: e,
                        field: 'metadata',
                      });
                    }}
                  />
                  <TextField
                    required
                    value={input.value}
                    label={t`value`}
                    name={`value-${index}`}
                    style={{ marginTop: '0.5rem' }}
                    maxLength={500}
                    showCharacterCount
                    onChange={(e) => {
                      handleForm({ event: e, field: 'metadata' });
                    }}
                  />
                </Flex>
              );
            })}
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              style={{
                border: '1px solid #e3ebf1',
                margin: '-1px',
                gap: '1.25rem',
                padding: '0.625rem',
              }}
            >
              <Button
                aria-label={t`Add Metadata`}
                text={t`Add Metadata`}
                icon={PlusCircle}
                variant="ghost"
                disabled={!enabledAddMetadataInput || lastEntryWithoutKey}
                onClick={handleAddInput}
              />
            </Flex>
          </div>
        </Grid.Column>
      </SectionRow>
    </>
  );
}

Metadata.propTypes = {
  setForm: PropTypes.func.isRequired,
  handleForm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadataInputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  setMetadataInputs: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  enabledAddMetadataInput: PropTypes.bool.isRequired,
  setEnabledAddMetadataInput: PropTypes.func.isRequired,
};
