import React from 'react';
import { PropTypes } from 'prop-types';
import { t } from '@lingui/macro';
import { Flex, Popover, Tag } from '@decisiv/ui-components';

import CheckCircle from '@decisiv/iconix/lib/components/CheckCircle';
import CheckCircleF from '@decisiv/iconix/lib/components/CheckCircleF';
import ExclamationTriangle from '@decisiv/iconix/lib/components/ExclamationTriangle';
import ExclamationTriangleF from '@decisiv/iconix/lib/components/ExclamationTriangleF';
import Hourglass from '@decisiv/iconix/lib/components/Hourglass';

export default function StatusTag({ status }) {
  const getStatusInfo = (logStatus) => {
    let statusText;
    let statusColor;
    let statusIcon;

    switch (logStatus) {
      case 'SUCCEEDED':
        statusText = t`Succeeded`;
        statusColor = 'success';
        statusIcon = CheckCircleF;
        break;
      case 'ExecutionDoesNotExist':
        statusText = t`Over 90 days ago`;
        statusColor = 'danger';
        statusIcon = ExclamationTriangleF;
        break;
      case 'RUNNING':
        statusText = t`Processing`;
        statusColor = 'information';
        statusIcon = Hourglass;
        break;
      case 'NOT_FOUND':
        statusText = 'Succeded - No Results';
        statusColor = 'success';
        statusIcon = CheckCircle;
        break;
      case 'FAILED':
      default:
        statusText = t`Failed`;
        statusColor = 'danger';
        statusIcon = ExclamationTriangle;
    }

    return { statusText, statusColor, statusIcon };
  };

  const { statusText, statusColor, statusIcon } = getStatusInfo(status);

  return (
    <>
      <Popover
        placement="bottom-start"
        manageEvents="hover"
        zIndex={999}
        target={({ ref, toggle }) => (
          <Tag
            ref={ref}
            color={statusColor}
            icon={statusIcon}
            key="id"
            variant="outline"
            style={{ marginRight: 5 }}
            action={toggle}
          />
        )}
      >
        <Flex padding={1}>
          <span>{statusText}</span>
        </Flex>
      </Popover>
    </>
  );
}

StatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};
