import React, { useState } from 'react';
import { Checkbox, Combobox, Grid, H3, P } from '@decisiv/ui-components';
import { t, Trans } from '@lingui/macro';
import startCase from 'lodash/startCase';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import PropTypes from 'prop-types';
import Asterisk from '@decisiv/iconix/lib/components/Asterisk';
import SectionRow from '../../components/SectionRow';
import Scope from './scope';
import filterOptions from '../../utils/filterOptions';

export default function Trigger({
  handleForm,
  applicationEventsItems,
  triggerTypeApplicationEvent,
  triggerTypeHttpRequest,
  editMode,
  integration,
}) {
  const [inputValue, setInputValue] = useState('');

  const [applicationEventsValue, setApplicationEventsValue] = useState(
    /* istanbul ignore next */
    (integration?.attributes?.events || []).map((applicationEvent) => ({
      id: applicationEvent,
      label: applicationEvent,
      value: applicationEvent,
    })),
  );

  const TRIGGER_TYPE_APPLICATION_EVENT = 'application_event';
  const TRIGGER_TYPE_HTTP_API_REQUEST = 'http_api_request';
  const HTTP_API_REQUEST_LABEL = 'HTTP API Request';

  const handleApplicationEvents = () => {
    /* istanbul ignore next */
    const updateApplicationEvents = (integration?.attributes?.events || []).map(
      (applicationEvent) => ({
        id: applicationEvent,
        label: applicationEvent,
        value: applicationEvent,
      }),
    );
    setApplicationEventsValue(updateApplicationEvents);
    setInputValue('');
  };

  return (
    <>
      <SectionRow>
        <Grid.Column padding={0} span="3">
          <H3>
            <Trans>Trigger</Trans>
          </H3>
        </Grid.Column>
        <Grid.Column padding={0}>
          <P shade={1} size="small">
            <Trans>Define how the integration is invoked.</Trans>
            <Asterisk
              color={toColorString(color.status.information.medium)}
              css={{ verticalAlign: 'middle' }}
              size="small"
            />{' '}
          </P>

          <div style={{ marginTop: '0.5rem' }}>
            <Checkbox
              name={TRIGGER_TYPE_APPLICATION_EVENT}
              id={TRIGGER_TYPE_APPLICATION_EVENT}
              label={startCase(TRIGGER_TYPE_APPLICATION_EVENT)}
              value={TRIGGER_TYPE_APPLICATION_EVENT}
              defaultChecked={
                editMode
                  ? integration?.attributes.trigger_type.includes(
                      TRIGGER_TYPE_APPLICATION_EVENT,
                    )
                  : null
              }
              onChange={(e) => {
                handleForm({ event: e, field: 'trigger_type' });
              }}
            />
          </div>

          {(triggerTypeApplicationEvent ||
            integration?.attributes?.trigger_type?.includes(
              'application_event',
            )) && (
            <Combobox
              required
              multiple
              label={t`Application Events`}
              options={filterOptions(applicationEventsItems, inputValue)}
              value={applicationEventsValue}
              style={{ marginTop: '0.5rem', marginLeft: '2.050rem' }}
              onChangeInputValue={(e) => setInputValue(e ? e.target.value : '')}
              onChangeValue={(selectedId, options) => {
                handleForm({
                  selectedId,
                  options,
                  field: 'events',
                });
                handleApplicationEvents();
              }}
            />
          )}

          <div style={{ marginTop: '0.5rem' }}>
            <Checkbox
              name={TRIGGER_TYPE_HTTP_API_REQUEST}
              id={TRIGGER_TYPE_HTTP_API_REQUEST}
              label={HTTP_API_REQUEST_LABEL}
              value={TRIGGER_TYPE_HTTP_API_REQUEST}
              style={{ marginTop: '0.5rem' }}
              defaultChecked={
                editMode
                  ? integration?.attributes.trigger_type.includes(
                      TRIGGER_TYPE_HTTP_API_REQUEST,
                    )
                  : null
              }
              onChange={(e) => {
                handleForm({ event: e, field: 'trigger_type' });
              }}
            />
          </div>

          {(triggerTypeHttpRequest ||
            integration?.attributes?.trigger_type?.includes(
              'http_api_request',
            )) && (
            <div
              style={{
                marginTop: '0.65rem',
                marginLeft: '2rem',
              }}
            >
              <a
                href="http://integration-framework-swagger-document-bucket-staging.s3-website-us-east-1.amazonaws.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: toColorString(color.interaction.pacificOcean),
                }}
              >
                <Trans>API Documentation</Trans>
              </a>
            </div>
          )}
        </Grid.Column>
      </SectionRow>
    </>
  );
}

Scope.defaultProps = {
  editMode: false,
};

Trigger.propTypes = {
  handleForm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  applicationEventsItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  triggerTypeApplicationEvent: PropTypes.bool.isRequired,
  triggerTypeHttpRequest: PropTypes.bool.isRequired,
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
  editMode: PropTypes.bool.isRequired,
};
