import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Combobox, Loading, P } from '@decisiv/ui-components';
import CategoriesAndActionsService from '../../../api/categories_and_actions';
import filterOptions from '../../../utils/filterOptions';

export default function CategoryFilter({ handleForm, currentValue }) {
  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState(
    currentValue.map((category) => ({
      id: category,
      label: category,
      value: category,
    })),
  );
  const [inputValue, setInputValue] = useState('');

  const handleCurrentValue = () => {
    const updateCategory = currentValue.map((category) => ({
      id: category,
      label: category,
      value: category,
    }));
    setCategoryValue(updateCategory);
  };

  function parseCategories({ data }) {
    const parsedData = data.map((categoryAndActions) => {
      return {
        id: categoryAndActions.attributes.category,
        label: categoryAndActions.attributes.category,
        value: categoryAndActions.attributes.category,
        name: categoryAndActions.attributes.category,
      };
    });

    return parsedData;
  }

  useEffect(() => {
    async function getData() {
      setCategories([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      const responseCategoriesAndActions =
        await CategoriesAndActionsService.fetchCategoriesAndActions();
      /* istanbul ignore else */
      if (responseCategoriesAndActions.data) {
        const parsedCategories = parseCategories(responseCategoriesAndActions);
        setCategories(parsedCategories);
      }
    }

    getData();
  }, []);

  return (
    <>
      <P marginBottom={1}>
        <Trans>Category</Trans>
      </P>
      <Combobox
        name="CategoryInputFilter"
        hideLabel
        multiple
        label={t`Category`}
        options={filterOptions(categories, inputValue)}
        onChangeInputValue={(e) => setInputValue(e ? e.target.value : '')}
        value={categoryValue}
        zIndex={777}
        onChangeValue={(selectedId, options) => {
          handleForm({
            selectedId,
            options,
            field: 'category',
          });
          handleCurrentValue();
        }}
      />
    </>
  );
}

CategoryFilter.propTypes = {
  handleForm: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
};
