import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import ExclamationCircleF from '@decisiv/iconix/lib/components/ExclamationCircleF';
import ChevronDoubleLeft from '@decisiv/iconix/lib/components/ChevronDoubleLeft';
import isEmpty from 'lodash/isEmpty';

import {
  Button,
  Drawer,
  Flex,
  H1,
  P,
  SearchInput,
} from '@decisiv/ui-components';

import {
  HelpInputMessage,
  HelpInputText,
  Separator,
  SeparatorX,
} from './style';
import { PageTitleWrapper } from './PageTile.styled';
import AccordionFilter from './filters/AccordionFilter';
import SelectedFilters from './filters/SelectedFilters';
import IntegrationsService from '../../api/integrations';

export default function HomePageTitle({ setLoading, setIntegrations }) {
  function hasCachedFilters() {
    return localStorage.getItem('filterCache') !== null;
  }

  const filterFormDefaults = {
    nameSrm: null,
    category: [],
    audience: 'Both',
    serviceNetwork: [],
    trigger: 'Both',
    events: [],
    commands: [],
    active: 'Both',
    published: 'Both',
    cacheEnabled: 'Both',
  };

  const tempFilterFormDefaults = {
    category: [],
    audience: 'Both',
    serviceNetwork: [],
    trigger: 'Both',
    events: [],
    commands: [],
    active: 'Both',
    published: 'Both',
    cacheEnabled: 'Both',
  };

  const initialSearch = hasCachedFilters()
    ? JSON.parse(localStorage.getItem('filterCache'))
    : {
        nameSrm: null,
        category: [],
        audience: 'Both',
        serviceNetwork: [],
        trigger: 'Both',
        events: [],
        commands: [],
        active: 'Both',
        published: 'Both',
        cacheEnabled: 'Both',
      };
  const [searchInput, setSearchInput] = useState('');
  const [tempForm, setTempForm] = useState(initialSearch);
  const [form, setForm] = useState(initialSearch);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [filterArray, setFilterArray] = useState([]);
  const toggleFilterDrawer = () => setShowFilterDrawer(!showFilterDrawer);

  function formatFilters(filters) {
    const filterObj = typeof filters === 'string' ? JSON.parse(filters) : form;

    const compactFilters = Object.fromEntries(
      Object.entries(filterObj).filter(([_key, value]) => {
        // Keep only the key-value pairs where the value is not 'Both' and not an empty array '[]'
        return (
          value !== null &&
          value !== '' &&
          value !== 'Both' &&
          !(Array.isArray(value) && value.length === 0)
        );
      }),
    );

    if (!isEmpty(compactFilters.active)) {
      compactFilters.active = compactFilters.active === 'Active';
    }
    if (!isEmpty(compactFilters.published)) {
      compactFilters.published = compactFilters.published === 'Published';
    }
    if (!isEmpty(compactFilters.cacheEnabled)) {
      compactFilters.cacheEnabled =
        compactFilters.cacheEnabled === 'Cache Enabled';
    }

    return compactFilters;
  }

  useEffect(() => {
    async function buildFilterArray() {
      let resultArray = form;

      resultArray = Object.keys(form)
        .map((key) => {
          const value = form[key];
          if (key === 'nameSrm') {
            return {};
          }

          if (Array.isArray(value)) {
            // If the value is an array, map each element to an object
            return value.map((element) => ({ field: key, value: element }));
          }
          // If the value is not an array, create a single object
          return { field: key, value };
        })
        .flat()
        .filter((element) => Object.keys(element).length !== 0);

      setFilterArray(
        resultArray?.filter(
          (item) =>
            item.value !== 'Both' && item.value !== null && item.value !== '',
        ),
      );
    }

    async function getIntegrations() {
      setLoading(true);
      const filterCache = localStorage.getItem('filterCache');
      const myFilters = formatFilters(filterCache);
      const response = await IntegrationsService.fetchIntegrations(myFilters);
      /* istanbul ignore else */
      if (response.data) setIntegrations(response.data);
      else setIntegrations([]);
      setLoading(false);
    }

    getIntegrations();
    buildFilterArray();
    setSearchInput(form.nameSrm);
  }, [form]);

  const handleForm = ({ event, selectedId, options, field }) => {
    const newForm = { ...tempForm };

    switch (field) {
      // managing combobox multiple values fields
      case 'category':
      case 'commands':
      case 'events':
      case 'serviceNetwork':
        /* istanbul ignore else */
        if (
          !newForm[field].includes(selectedId) &&
          !isEmpty(selectedId) &&
          !options.removed
        ) {
          newForm[field].push(selectedId);
        }

        /* istanbul ignore next */
        if (newForm[field].includes(selectedId) && options.removed) {
          const indexToRemove = newForm[field].indexOf(selectedId);
          if (indexToRemove !== -1) {
            newForm[field].splice(indexToRemove, 1);
          }
        }

        break;
      default:
        newForm[field] = event.target.value;
        break;
    }

    setTempForm(newForm);
  };

  const closeDrawer = () => {
    setShowFilterDrawer(false);
  };

  const clearFilters = () => {
    localStorage.removeItem('filterCache');
    setForm(filterFormDefaults);
    setTempForm(filterFormDefaults);
    setSearchInput('');
  };

  const clearTempFilters = () => {
    setTempForm({ ...form, ...tempFilterFormDefaults });
  };

  const applyFilter = () => {
    setForm(tempForm);
    localStorage.setItem('filterCache', JSON.stringify(tempForm));
    setShowFilterDrawer(false);
  };

  const onSearchHandler = () => {
    const newForm = { ...form };
    newForm.nameSrm = searchInput;
    localStorage.setItem('filterCache', JSON.stringify(newForm));
    setTempForm(newForm);
    setForm(newForm);
  };

  const onChangeSearchInputHandler = (event) => {
    if (event.target.value === '') {
      const newForm = { ...form };
      newForm.nameSrm = '';
      localStorage.setItem('filterCache', JSON.stringify(newForm));
      setForm(newForm);
    }

    setSearchInput(event.target.value);
  };

  return (
    <>
      <Drawer
        title="Filters"
        visible={showFilterDrawer}
        onClose={closeDrawer}
        side="right"
        actions={[
          {
            text: t`Apply`,
            onClick: applyFilter,
          },
          {
            text: t`Cancel`,
            onClick: closeDrawer,
            kind: 'secondary',
          },
          {
            text: t`Clear All`,
            onClick: clearTempFilters,
            variant: 'ghost',
            style: { 'margin-left': '7rem' },
          },
        ]}
      >
        <Flex flexDirection="column" style={{ width: '100%' }}>
          <Flex marginTop={0.7}>
            <AccordionFilter handleForm={handleForm} form={tempForm} />
          </Flex>
        </Flex>
      </Drawer>
      <PageTitleWrapper>
        <Flex name="header" flexDirection="column" style={{ width: '100%' }}>
          <Flex
            name="searchBoxRow"
            justifyContent="space-between"
            style={{ padding: '0.65rem 1.25rem' }}
          >
            <Flex name="searchBox">
              <Flex>
                <H1 marginRight={2} marginTop={0.2}>
                  <Trans> Integrations </Trans>
                </H1>
                <Separator />
              </Flex>
              <Flex flexDirection="column">
                <SearchInput
                  value={searchInput}
                  width={32}
                  label={t`searchInput`}
                  name="nameSrmSearch"
                  onChange={onChangeSearchInputHandler}
                  onSearch={onSearchHandler}
                  size="small"
                  searchDisabled={false}
                />
                <HelpInputMessage alignItems="center">
                  <ExclamationCircleF />
                  <HelpInputText shade={1}>
                    <Trans>
                      Search by integration\location name or SRM account
                    </Trans>
                  </HelpInputText>
                </HelpInputMessage>
              </Flex>
            </Flex>
            <Flex name="filterDrawerButtonBox" flexDirection="row">
              {isEmpty(filterArray) ? (
                <Button
                  text={t`Filters`}
                  icon={ChevronDoubleLeft}
                  iconPosition="left"
                  kind="secondary"
                  size="small"
                  variant="ghost"
                  onClick={toggleFilterDrawer}
                />
              ) : (
                <Button
                  text={`${filterArray.length} Filters `}
                  icon={ChevronDoubleLeft}
                  iconPosition="left"
                  size="small"
                  onClick={toggleFilterDrawer}
                />
              )}
            </Flex>
          </Flex>
          <Flex name="separatorRow">
            <SeparatorX />
          </Flex>
          {!isEmpty(filterArray) && (
            <>
              <Flex
                name="selectedFiltersRow"
                style={{ padding: '0.5rem 1.25rem' }}
              >
                <Flex name="advancedFilterLabel">
                  <P> Filters: </P>
                </Flex>
                <SelectedFilters
                  items={filterArray}
                  clearAll={clearFilters}
                  form={form}
                  setForm={setForm}
                  setTempForm={setTempForm}
                />
              </Flex>
              <Flex name="separatorRow">
                <SeparatorX />
              </Flex>
            </>
          )}
        </Flex>
      </PageTitleWrapper>
    </>
  );
}

HomePageTitle.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setIntegrations: PropTypes.func.isRequired,
};
