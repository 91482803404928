import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { encryptStorage } from '../utils/storage';

export default function Logout() {
  useEffect(() => {
    async function logoutUser() {
      encryptStorage.removeItem('authToken');
      encryptStorage.removeItem('userSession');
      localStorage.removeItem('filterCache');

      navigate('/sign_in');
    }

    logoutUser();
  });

  return <></>;
}
