import styled from 'styled-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';

export const ArrowWrapper = styled(Flex)`
  flex-direction: row;
  margin: auto 5px;
  color: ${toColorString(color.base.alaskanHusky)};
`;
