import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import spacing from '@decisiv/design-tokens/lib/spacing';

export const Wrapper = styled.pre`
  background: #f1f2f8;
  padding: ${rem(spacing.base * 1.5)};
  min-width: 40%;
  width: 30rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  flex-grow: 1;
`;
