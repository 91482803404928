import { isEmpty } from 'lodash';
import CryptoJS from 'crypto-js';

export default function encryptText(str) {
  const ciphertext = isEmpty(str)
    ? ''
    : CryptoJS.AES.encrypt(
        str,
        process.env.REACT_APP_ENCRYPTION_SECRET_KEY,
      ).toString();

  // Decrypt
  const bytes = CryptoJS.AES.decrypt(
    ciphertext,
    process.env.REACT_APP_ENCRYPTION_SECRET_KEY,
  );
  const originalText = bytes.toString(CryptoJS.enc.Utf8);

  return [ciphertext, originalText];
}
