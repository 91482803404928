import React from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import { H2 } from '@decisiv/ui-components';

import { NoResultDescription, SearchIcon, CenteredContainer } from './styles';

export default function NoLocationsFound({ filterSearch }) {
  return (
    <>
      <CenteredContainer flexDirection="column" alignItems="center">
        <SearchIcon width={32} height={32} />
        <H2 marginTop={1} marginBottom={1}>
          <Trans>No matches for &ldquo;{filterSearch}&rdquo;</Trans>
        </H2>

        <NoResultDescription shade={1}>
          <Trans>
            Check that all words are spelled correctly or search by a different
            value.
          </Trans>
        </NoResultDescription>
      </CenteredContainer>
    </>
  );
}

NoLocationsFound.propTypes = {
  filterSearch: PropTypes.string.isRequired,
};
