import React from 'react';
import PropTypes from 'prop-types';

import LoadingWrapper from '@decisiv/ui-components/lib/components/Loading';
import { t } from '@lingui/macro';
import { CenteredContainer } from './style';

export default function Searching({ loading, children, description }) {
  return (
    <>
      {loading ? (
        <CenteredContainer flexDirection="column" padding={1}>
          <LoadingWrapper
            size="large"
            title={t`Searching...`}
            description={description}
          />
        </CenteredContainer>
      ) : (
        children
      )}
    </>
  );
}

Searching.defaultProps = { description: '' };

Searching.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  description: PropTypes.string,
};
