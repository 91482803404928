import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import { P } from '@decisiv/ui-components';
import { t, Trans } from '@lingui/macro';
import noop from 'lodash/noop';
import WrapperWithLoading from '../../../components/WrapperWithLoading';

export default function AddMultipleLocations({
  multipleLocationsResponseData,
  visible,
  isPosting,
  integrationName,
  onClose,
  setRefresh,
  currentLocationUpload,
  uploadTotal,
  reportFile,
}) {
  const { successCount, errorCount } = multipleLocationsResponseData;

  const results = useMemo(() => {
    return {
      successCount,
      errorCount,
    };
  }, [successCount, errorCount]);

  const resultSummary = useMemo(() => {
    if (results.successCount === 0) {
      return {
        status: 'danger',
        message: t`Failed to Add ${results.errorCount} Locations to ${integrationName} Location List`,
      };
    }
    if (results.errorCount === 0) {
      return {
        status: 'success',
        message: t`Successfully Added ${results.successCount} Locations to ${integrationName} Location List`,
      };
    }

    return {
      status: 'warning',
      message: t`Not All Locations were Added to ${integrationName} Location List`,
    };
  }, [results, integrationName]);

  const handleDownloadReport = (e) => {
    e.preventDefault();

    // Convert CSV Into Blob Object, create a temporary link then click it
    const binaryData = [];
    binaryData.push(reportFile.join(''));

    const csvURL = window.URL.createObjectURL(
      new Blob(binaryData, { type: 'text/csv' }),
    );
    let tempLink;
    // eslint-disable-next-line prefer-const
    tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('csv_report', 'csv_report.csv');
    tempLink.click();
  };

  return (
    <>
      <Modal
        actions={[
          {
            text: t`Download Report`,
            onClick: handleDownloadReport,
          },
          {
            text: t`Close`,
            onClick: () => {
              onClose();
              setRefresh(true);
            },
          },
        ]}
        intent={resultSummary.status}
        title={resultSummary.message}
        onClose={() => {
          onClose();
          setRefresh(true);
        }}
        visible={visible && !isPosting}
      >
        {resultSummary.status === 'warning' && (
          <P shade={1} marginTop={2}>
            <Trans>
              {`${integrationName} was added to ${results.successCount} locations but couldn't be
              added to ${results.errorCount}. Download the report to find the troubles while adding
              the locations, and try uploading the file again.`}
            </Trans>
          </P>
        )}
      </Modal>

      <Modal visible={visible}>
        <WrapperWithLoading
          loading={isPosting}
          description={`Adding locations ${currentLocationUpload} of ${uploadTotal}`}
        />
      </Modal>
    </>
  );
}

AddMultipleLocations.propTypes = {
  multipleLocationsResponseData: PropTypes.shape({
    successCount: PropTypes.number,
    errorCount: PropTypes.number,
  }),
  visible: PropTypes.bool,
  isPosting: PropTypes.bool,
  integrationName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setRefresh: PropTypes.func,
  currentLocationUpload: PropTypes.number,
  uploadTotal: PropTypes.number,
  reportFile: PropTypes.arrayOf(PropTypes.string),
};

AddMultipleLocations.defaultProps = {
  multipleLocationsResponseData: {},
  visible: false,
  isPosting: false,
  integrationName: '',
  setRefresh: noop,
  currentLocationUpload: 0,
  uploadTotal: 0,
  reportFile: '',
};
