import React from 'react';

import { P } from '@decisiv/ui-components/lib/components/Typography';

export default function Separator(props) {
  return (
    <P as="span" shade={1} size="small" marginX={0.5} {...props}>
      •
    </P>
  );
}
