import React, { useState, useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Loading from '@decisiv/ui-components/lib/components/Loading';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';

import footerLogo from '../images/DecisivLogo_footer.png';
import VERSION from '../common/constants/version';
import ApiVersionService from '../api/api_version';

const NavFooter = ({ navExpandedState }) => {
  const year = new Date().getFullYear();
  const expanded = true;
  const [apiVersion, setApiVersion] = useState(null);
  const [isLoadingVersion, setIsLoadingVersion] = useState(false);

  useEffect(() => {
    async function getData() {
      setIsLoadingVersion(true);
      const response = await ApiVersionService.fetchApiVersion();
      if (response.data) {
        setApiVersion(response.data.attributes.api_version);
        setIsLoadingVersion(false);
      } else setApiVersion(null);
    }
    getData();
  }, [setApiVersion]);

  const apiVersionDisplayed = useMemo(() => {
    if (isLoadingVersion) {
      return (
        <Flex>
          <span>&nbsp;</span>
          <Loading size="small" variant="inverted" />
        </Flex>
      );
    }

    return `API ${apiVersion}`;
  }, [apiVersion, isLoadingVersion]);

  return (
    <Flex
      justifyContent="space-between"
      style={{
        fontSize: '0.5rem',
        backgroundColor: '#2F3039',
        color: '#C5CAD3',
        padding: '0.375rem 0.3125rem',
      }}
    >
      <Flex flexDirection="column">
        {navExpandedState === expanded ? (
          <div>
            <img
              style={{ width: 'fit-content', marginBottom: '0.3rem' }}
              src={footerLogo}
              alt="Powered by Decisiv"
            />
            <div>&copy; {year} Decisiv, Inc.</div>
            <Flex alignItems="center">
              UI {VERSION.frontend} | {apiVersionDisplayed}
            </Flex>
            <div>
              <a
                href="https://www.decisiv.com/data-governance-statement/"
                style={{ color: toColorString(color.base.snowWhite) }}
              >
                Data Governance
              </a>{' '}
              |
              <a
                href="https://www.decisiv.com/privacy-policy/"
                style={{ color: toColorString(color.base.snowWhite) }}
              >
                Privacy Police
              </a>{' '}
              |
            </div>
            <div>
              <a
                href="https://www.decisiv.com/terms-of-use/"
                style={{ color: toColorString(color.base.snowWhite) }}
              >
                Terms of Use
              </a>{' '}
              |
              <a
                href="https://www.decisiv.com/cookie-policy/"
                style={{ color: toColorString(color.base.snowWhite) }}
              >
                Cookie Policy
              </a>
            </div>
          </div>
        ) : (
          <>
            <div>Decisiv, Inc.</div>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default NavFooter;

NavFooter.propTypes = {
  navExpandedState: PropTypes.bool.isRequired,
};
