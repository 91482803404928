import api from './api';

const fetchActionDetails = async (action, inputType = 'input') => {
  try {
    const response = await api.get(
      `/actions?filter[action]=${action}&filter[type]=${inputType}`,
    );
    const { data } = response.data;

    return { data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchActions = async () => {
  try {
    const response = await api.get(`/categories-and-actions`);
    const { data } = response.data;

    return { data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

export default {
  fetchActionDetails,
  fetchActions,
};
