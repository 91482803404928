import React from 'react';
import { PropTypes } from 'prop-types';
import { t } from '@lingui/macro';
import { Flex, Button, Accordion } from '@decisiv/ui-components';
import Copy from '@decisiv/iconix/lib/components/Copy';
import { DeveloperCodeView } from './style';

import './style.css';

export default function ContentAccordion({ content }) {
  const formatJsonText = (text) => {
    try {
      return JSON.stringify(JSON.parse(text), undefined, 2);
    } catch (error) {
      return t`Invalid Payload Format`;
    }
  };

  const accordionItems = [
    {
      PanelComponent: () => (
        <Flex id="payloadBox">
          <DeveloperCodeView id="codeBox">
            {formatJsonText(content?.invocation_payload)}
          </DeveloperCodeView>
          <Button
            style={{ borderRadius: '4px' }}
            icon={Copy}
            text={t`Copy`}
            id="copyBtn"
            variant="inverted"
            onClick={() =>
              navigator.clipboard.writeText(
                formatJsonText(content?.invocation_payload),
              )
            }
            size="small"
          />
        </Flex>
      ),
      id: 'invocation-payload',
      title: content?.inputTitle || t`Invocation`,
    },
    {
      PanelComponent: () => (
        <Flex id="payloadBox">
          <DeveloperCodeView id="codeBox">
            {formatJsonText(content?.input)}
          </DeveloperCodeView>
          <Button
            style={{ borderRadius: '4px' }}
            icon={Copy}
            text={t`Copy`}
            id="copyBtn"
            variant="inverted"
            onClick={() =>
              navigator.clipboard.writeText(formatJsonText(content?.input))
            }
            size="small"
          />
        </Flex>
      ),
      id: 'input-payload',
      title: content?.inputTitle || t`Input`,
    },
    {
      PanelComponent: () => (
        <Flex id="payloadBox">
          <DeveloperCodeView id="codeBox">
            {formatJsonText(content?.output)}
          </DeveloperCodeView>
          <Button
            icon={Copy}
            text={t`Copy`}
            id="copyBtn"
            variant="inverted"
            onClick={() =>
              navigator.clipboard.writeText(formatJsonText(content?.output))
            }
            size="small"
          />
        </Flex>
      ),
      id: 'output-payload',
      title: content?.outputTitle || t`Output`,
    },
  ];

  return (
    <Accordion
      items={accordionItems}
      titleHeadingLevel="5"
      controls="hide"
      defaultExpanded={[
        'invocation-payload',
        'input-payload',
        'output-payload',
      ]}
      size="large"
    />
  );
}

ContentAccordion.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
