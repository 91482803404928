import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Tag, Tooltip } from '@decisiv/ui-components';
import Times from '@decisiv/iconix/lib/components/Times';

export default function SelectedFilters({
  items,
  clearAll,
  form,
  setForm,
  setTempForm,
}) {
  const fieldsNameMap = {
    nameSrm: 'Name/SRM Account',
    category: 'Category',
    audience: 'Audience',
    serviceNetwork: 'Service Network',
    trigger: 'Trigger Type',
    events: 'Application Events',
    commands: 'Commands',
    active: 'Visibility',
    published: 'Visibility',
    cacheEnabled: 'Cache',
  };

  const handleTagRemoval = (event) => {
    const newForm = { ...form };
    const key = Object.keys(event.filter)[0];
    const value = Object.values(event.filter)[0];

    if (Array.isArray(newForm[key])) {
      // If the key corresponds to an array, remove the specified value
      const index = newForm[key].indexOf(value);
      if (index !== -1) {
        newForm[key].splice(index, 1);
      }
    } else {
      // If the key does not correspond to an array, set the value to null
      newForm[key] = 'Both';
    }

    localStorage.setItem('filterCache', JSON.stringify(newForm));
    setForm(newForm);
    setTempForm(newForm);
  };

  return (
    <>
      <Flex name="advancedFilterTags" marginLeft={1} flexWrap="wrap">
        {items?.map((sn) => (
          <Tooltip
            target={
              <Flex marginLeft={0.5}>
                <Tag
                  text={sn.value}
                  variant="outline"
                  action={(e) => {
                    handleTagRemoval({
                      event: e,
                      filter: { [`${sn.field}`]: sn.value },
                    });
                  }}
                  actionIntent="danger"
                  actionAttributes={{ 'aria-label': 'Expand' }}
                  actionIcon={Times}
                />
              </Flex>
            }
          >
            {fieldsNameMap[sn.field]}
          </Tooltip>
        ))}
      </Flex>
      <Flex style={{ marginLeft: 'auto' }}>
        <Button
          text="Clear All"
          size="small"
          variant="ghost"
          onClick={clearAll}
        />
      </Flex>
    </>
  );
}

SelectedFilters.propTypes = {
  clearAll: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  setTempForm: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]).isRequired)
    .isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
