const oemOptions = [
  {
    id: 'all',
    label: 'ALL',
    value: 'all',
  },
  {
    id: 'dcv',
    label: 'DCV',
    value: 'dcv',
  },
  {
    id: 'flt',
    label: 'FLT',
    value: 'flt',
  },
  {
    id: 'greatdane',
    label: 'GREATDANE',
    value: 'greatdane',
  },
  {
    id: 'hino',
    label: 'HINO',
    value: 'hino',
  },
  {
    id: 'isuzu',
    label: 'ISUZU',
    value: 'isuzu',
  },
  {
    id: 'mck',
    label: 'MCK',
    value: 'mck',
  },
  {
    id: 'mich',
    label: 'MICH',
    value: 'mich',
  },
  {
    id: 'pcar',
    label: 'PCAR',
    value: 'pcar',
  },
  {
    id: 'stl',
    label: 'STL',
    value: 'stl',
  },
  {
    id: 'tico',
    label: 'TICO',
    value: 'tico',
  },
  {
    id: 'trimble',
    label: 'TRIMBLE',
    value: 'trimble',
  },
  {
    id: 'vlv',
    label: 'VLV',
    value: 'vlv',
  },
  {
    id: 'vce',
    label: 'VCE',
    value: 'vce',
  },
  {
    id: 'wlt',
    label: 'WLT',
    value: 'wlt',
  },
];

const dealerTypeOptions = [
  {
    id: 'all',
    label: 'ALL',
    value: 'all',
  },
  {
    id: 'fleet',
    label: 'FLEET Fleet Ownern',
    value: 'fleet',
  },
  {
    id: 'free',
    label: 'FREE Service Location',
    value: 'free',
  },
  {
    id: 'member',
    label: 'MEMBER Noop Service Location',
    value: 'member',
  },
  {
    id: 'paid',
    label: 'PAID Service Location',
    value: 'paid',
  },
  {
    id: 'partner',
    label: 'PARTNER Service Location',
    value: 'partner',
  },
  {
    id: 'uptime_center',
    label: 'UPTIME CENTER Service Location',
    value: 'uptime_center',
  },
];

export { oemOptions, dealerTypeOptions };
