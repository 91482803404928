import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Grid, Message, P, Tag } from '@decisiv/ui-components';
import { t, Trans } from '@lingui/macro';
import iconix from '@decisiv/iconix';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';

import isEmpty from 'lodash/isEmpty';
import { LocationOption } from './styles';
import NoLocationsFound from './NoLocationsFound';
import Separator from '../../../components/Separator';

export default function LocationsResult({
  integration,
  locations,
  selectedLocationId,
  setSelectedLocationId,
  filterSearch,
  currentIntegrationLocations,
}) {
  const [selectedValue, setSelectedValue] = useState(selectedLocationId);

  useEffect(() => {
    setSelectedLocationId(selectedValue);
  }, [selectedValue]);

  /* istanbul ignore next */
  const validServiceNetwork = (location) => {
    if (isEmpty(integration?.service_network)) {
      return true;
    }

    // Return true if pricing is still not serving the necessary data for validation
    if (
      location?.attributes?.service_networks === null ||
      location?.attributes?.service_networks === undefined
    ) {
      return true;
    }

    if (
      Array.isArray(location?.attributes?.service_networks) &&
      isEmpty(location?.attributes?.service_networks)
    ) {
      return false;
    }

    const integrationServiceNetworks = integration?.service_network.map(
      (item) => item.toLowerCase(),
    );
    const locationServiceNetworks = location?.attributes?.service_networks.map(
      (item) => item.name.toLowerCase(),
    );

    // Check if at least one item from locationServiceNetworks is the same as any integrationServiceNetworks;
    return locationServiceNetworks.some((network) =>
      integrationServiceNetworks.includes(network),
    );
  };

  /* istanbul ignore next */
  const validAudience = (location) => {
    if (isEmpty(integration?.audience)) {
      return true;
    }

    if (isEmpty(location?.attributes?.account_type)) {
      return false;
    }

    let locationTypeMapped;

    switch (location.attributes?.account_type.toLowerCase()) {
      case 'fleet':
        locationTypeMapped = 'Asset Management';
        break;
      default:
        locationTypeMapped = 'Service Management';
        break;
    }

    return integration?.audience.includes(locationTypeMapped);
  };

  /* istanbul ignore next */
  const validIsConfigured = (location, currentLocations) => {
    if (isEmpty(currentLocations)) {
      return true;
    }

    const currentIntegrationSrmAccountsIds = currentLocations.map(
      (l) => l.attributes.srm_account_id,
    );

    return !currentIntegrationSrmAccountsIds.includes(
      location.attributes.srm_account_id,
    );
  };

  const invalidServiceNetworkAndAudience = (location) => {
    if (!(validServiceNetwork(location) && validAudience(location))) {
      return true;
    }

    if (!validIsConfigured(location, currentIntegrationLocations)) {
      return true;
    }

    return false;
  };

  const setAddLocationError = (location) => {
    let condition;

    if (!validServiceNetwork(location) && !validAudience(location)) {
      condition = 'both';
    } else if (!validIsConfigured(location, currentIntegrationLocations)) {
      condition = 'invalid_is_configured';
    } else if (!validServiceNetwork(location)) {
      condition = 'invalid_service_network';
    } else if (!validAudience(location)) {
      condition = 'invalid_audience';
    } else {
      // If both validServiceNetwork(location) and validAudience(location) are true
      // This condition should not actually be reached with the above logic, but we can handle it gracefully
      condition = 'none'; // or any other default value if needed
    }

    return condition;
  };

  const renderErrorTag = (location) => {
    const error = setAddLocationError(location);

    return (
      <>
        {error === 'invalid_is_configured' && (
          <Tag
            color="warning"
            size="medium"
            variant="outline"
            text={t`Already Configured`}
            icon={iconix.ExclamationTriangle}
          />
        )}
        {error === 'invalid_service_network' && (
          <Tag
            color="warning"
            size="medium"
            variant="outline"
            text={t`Out of Network`}
            icon={iconix.ExclamationTriangle}
          />
        )}
        {error === 'invalid_audience' && (
          <Tag
            color="warning"
            size="medium"
            variant="outline"
            text={t`Out of Audience`}
            icon={iconix.ExclamationTriangle}
          />
        )}
        {error === 'both' && (
          <Tag
            color="warning"
            size="medium"
            variant="outline"
            text={t`Out of Network and Audience`}
            icon={iconix.ExclamationTriangle}
          />
        )}
      </>
    );
  };

  const renderErrorMsg = (location) => {
    const error = setAddLocationError(location);

    return (
      <>
        {error === 'invalid_is_configured' && (
          <Message icon={iconix.ExclamationTriangle} color="warning">
            <Trans>
              Cannot add location. Location is already assigned to this
              integration.
            </Trans>
          </Message>
        )}
        {error === 'invalid_service_network' && (
          <Message icon={iconix.ExclamationTriangle} color="warning">
            <Trans>
              Cannot add location. This location is outside the
              integration&apos;s service network.
            </Trans>
          </Message>
        )}
        {error === 'invalid_audience' && (
          <Message icon={iconix.ExclamationTriangle} color="warning">
            <Trans>
              Cannot add location. This location is outside the
              integration&apos;s audience.
            </Trans>
          </Message>
        )}
        {error === 'both' && (
          <Message icon={iconix.ExclamationTriangle} color="warning">
            <Trans>
              Cannot add location. This location is outside the
              integration&apos;s service network and audience.
            </Trans>
          </Message>
        )}
      </>
    );
  };

  return locations.length === 0 ? (
    <NoLocationsFound filterSearch={filterSearch} />
  ) : (
    locations.map((location) => {
      return (
        <>
          <LocationOption
            paddingY={1}
            marginX={0}
            key={location.id}
            alignment={['start', 'top']}
            selected={selectedLocationId === location.attributes.srm_account_id}
          >
            <Grid.Column>
              <Grid.Row>
                <Grid.Column>
                  <Flex justifyContent="space-between">
                    <RadioGroup
                      name="radio-add-location"
                      onChange={(e) => {
                        setSelectedValue(e.target.value);
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      disabled={invalidServiceNetworkAndAudience(
                        location,
                        currentIntegrationLocations,
                      )}
                      items={[
                        {
                          label: `${location.attributes.name} (${location.attributes.srm_account_id})`,
                          value: location.attributes.srm_account_id,
                        },
                      ]}
                      value={
                        selectedLocationId ===
                        location.attributes.srm_account_id
                          ? selectedLocationId
                          : ''
                      }
                    />
                    <Flex>{renderErrorTag(location)} </Flex>
                  </Flex>
                </Grid.Column>
              </Grid.Row>
              <Flex
                alignItems="flexStart"
                marginTop={0.5}
                marginLeft={3}
                flexWrap="wrap"
              >
                <P as="span" color="alaskanHusky" size="small">
                  {location.oem}
                </P>

                <Separator />

                <P as="span" color="alaskanHusky" size="small">
                  {location.attributes.account_type}
                </P>

                <Separator />

                <P as="span" color="alaskanHusky" marginLeft={0.5} size="small">
                  {location.attributes.city}, {location.attributes.state}
                </P>
              </Flex>
            </Grid.Column>
          </LocationOption>
          <Flex paddingY={1}>{renderErrorMsg(location)}</Flex>
        </>
      );
    })
  );
}

LocationsResult.defaultProps = {
  currentIntegrationLocations: [],
};

LocationsResult.propTypes = {
  currentIntegrationLocations: PropTypes.oneOfType([PropTypes.array]),
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedLocationId: PropTypes.string.isRequired,
  setSelectedLocationId: PropTypes.func.isRequired,
  filterSearch: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      oem: PropTypes.string,
      type: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
    }),
  ).isRequired,
};
