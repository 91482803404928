const fileToText = async (file) => {
  const fileReader = new FileReader();
  fileReader.readAsText(file);

  return new Promise((resolve) => {
    fileReader.onload = () => {
      resolve(fileReader.result.split('\n'));
    };
  });
};

export default fileToText;
