import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import {
  Combobox,
  Grid,
  H3,
  P,
  TextArea,
  TextField,
} from '@decisiv/ui-components';
import SectionRow from '../../components/SectionRow';
import filterOptions from '../../utils/filterOptions';

export default function BasicInformation({
  handleForm,
  categories,
  integration,
}) {
  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <SectionRow>
        <Grid.Column padding={0} span="3">
          <H3>
            <Trans>Basic Information</Trans>
          </H3>
        </Grid.Column>
        <Grid.Column padding={0}>
          <P shade={1} size="small">
            <Trans>
              Fill out basic information about the integration that you are
              about to create.
            </Trans>
          </P>
          <TextField
            required
            label={t`Name`}
            name="name"
            maxLength={40}
            showCharacterCount
            style={{ marginTop: '0.5rem' }}
            onChange={(e) => {
              handleForm({ event: e });
            }}
            defaultValue={integration?.attributes?.name}
          />
          <TextArea
            required
            label={t`Description`}
            name="description"
            style={{ marginTop: '0.545rem' }}
            maxLength={255}
            showCharacterCount
            onChange={(e) => {
              handleForm({ event: e });
            }}
            defaultValue={integration?.attributes?.description}
          />
          <Combobox
            required
            label={t`Category`}
            helpMessage={t`The category selected will determine the command types available.`}
            style={{ marginTop: '0.545rem' }}
            options={filterOptions(categories, inputValue)}
            onChangeInputValue={(e) => setInputValue(e ? e.target.value : '')}
            onChangeValue={(selectedId, options) => {
              handleForm({
                selectedId,
                options,
                field: 'category',
              });
            }}
            defaultInputValue={integration?.attributes.category}
          />
        </Grid.Column>
      </SectionRow>
    </>
  );
}

BasicInformation.defaultProps = {
  categories: null,
  integration: null,
};

BasicInformation.propTypes = {
  handleForm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.arrayOf(PropTypes.object),
  integration: PropTypes.oneOfType([PropTypes.object]),
};
