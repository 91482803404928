import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, H1, SearchInput, P, Badge, H3 } from '@decisiv/ui-components';
import { t, Trans } from '@lingui/macro';
import Info from '@decisiv/iconix/lib/components/Info';
import {
  LogInspectorSection,
  LineDivider,
  LogSearchBarSection,
  LogListSection,
} from './style';
import LogList from './LogList';
import LogInspectorService from '../../api/step_function_executions';
import WrapperWithSearching from '../../components/WrapperWithSearching';

export default function LogInspectorPage({ traceId }) {
  const [searchFailed, setSearchFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logItems, setLogItems] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  async function getLogs(urlTraceId) {
    const uuidToSearch = searchInput || urlTraceId;

    if (!uuidToSearch) {
      return;
    }

    setIsLoading(true);
    setSearchFailed(false);

    const responseLogs = await LogInspectorService.fetchStepFunctionExecutions(
      uuidToSearch,
    );

    if (responseLogs.data && responseLogs.data.length > 0) {
      setLogItems(responseLogs.data);
    } else {
      setSearchFailed(true);
      setLogItems([]);
    }

    setIsLoading(false);
  }

  const onChangeSearchInputHandler = (event) => {
    if (event.target.value === '') {
      setLogItems([]);
      setSearchFailed(false);
    }

    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (traceId) {
      getLogs(traceId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LogInspectorSection
        flexDirection="column"
        justifyContent="center"
        padding={2}
        flex="none"
        flexGrow="0"
      >
        <Flex flexDirection="row">
          <Flex flex={1} title={t`Log Inspector`}>
            <H1>
              <Trans>Log Inspector</Trans>
            </H1>
          </Flex>
        </Flex>
      </LogInspectorSection>

      <LineDivider />
      <LogSearchBarSection>
        <Flex
          marginLeft={1}
          padding={1}
          name="SearchInputFlex"
          style={{ width: '800px' }}
        >
          <SearchInput
            value={searchInput}
            label={t`Search it`}
            name="input-name"
            onSearch={getLogs}
            onChange={onChangeSearchInputHandler}
            size="medium"
            helpMessage={t`Search by X-Decisiv-Trace-Id.`}
          />
        </Flex>
      </LogSearchBarSection>

      <LogListSection flexDirection="column" name="LogListSection">
        {searchFailed && (
          <Flex
            alignItems="center"
            style={{ width: '100%' }}
            name="searchErrorBox"
            flexDirection="column"
          >
            <Flex>
              <Badge
                key="ErrorBadge"
                aria-label="Error Badge"
                color="information"
                icon={Info}
                size="large"
                palette="bright"
              />
            </Flex>
            <Flex marginTop={1}>
              <H3 weight="semibold">
                <Trans>No X-Decisiv-Trace-Id Found</Trans>
              </H3>
            </Flex>
            <Flex>
              <P size="small">
                <Trans>No X-Decisiv-Trace-Id were found with the ID</Trans>
              </P>
            </Flex>
            <Flex>
              <P color="alaskanHusky">{searchInput}</P>
            </Flex>
          </Flex>
        )}

        <WrapperWithSearching loading={isLoading}>
          {logItems.length > 0 && (
            <LogList logsList={logItems} isLoading={isLoading} />
          )}
        </WrapperWithSearching>
      </LogListSection>
    </>
  );
}

LogInspectorPage.propTypes = {
  traceId: PropTypes.string,
};

LogInspectorPage.defaultProps = {
  traceId: null,
};
