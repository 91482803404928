import React, { useEffect, useState } from 'react';
import { Flex, Breadcrumbs } from '@decisiv/ui-components';
import PageTitle from '../../components/PageTitle/PageTitle';
import IntegrationsService from '../../api/integrations';
import { IntegrationsWrapper } from '../../containers/IntegrationContainer/IntegrationsWrapper.styled';
import IntegrationContainer from '../../containers/IntegrationContainer';
import WrapperWithLoading from '../../components/WrapperWithLoading';

export default function ArchivePage() {
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const response = await IntegrationsService.fetchArchivedIntegrations(
        1,
        10,
      );
      if (response.data) setIntegrations(response.data);
      else setIntegrations([]);
      setLoading(false);
    }
    getData();
  }, []);

  return (
    <>
      <Flex padding={1}>
        <Breadcrumbs
          path={[{ to: '/integrations', text: 'Integrations' }]}
          current="Archive"
        />
      </Flex>
      <Flex flexDirection="row">
        <PageTitle title="Archived Integrations" />
      </Flex>
      <WrapperWithLoading loading={loading}>
        <IntegrationsWrapper title="integrations-container">
          {integrations.map(({ _id, attributes }) => {
            return (
              <IntegrationContainer
                key={attributes.name}
                name={attributes.name}
                status={attributes.enabled ? 'Active' : 'Inactive'}
                published={attributes.published ? 'Published' : 'Unpublished'}
                description={attributes.description}
                locationUrl="/integrations/archive"
                audience={attributes.audience}
                serviceNetwork={attributes.service_network}
                triggerType={attributes.trigger_type}
                archivedBy={attributes.discarded_by}
                archivedAt={attributes.discarded_at}
                archiveMode
              />
            );
          })}
        </IntegrationsWrapper>
      </WrapperWithLoading>
    </>
  );
}
