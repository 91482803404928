import styled from 'styled-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';

const IntegrationsWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 1.25rem;
  padding: 0.625rem;
  justify-content: flex-start;
`;

export { IntegrationsWrapper };
