import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Combobox, Flex, Loading, P, RadioGroup } from '@decisiv/ui-components';
import ApplicationEventsService from '../../../api/application_events';
import filterOptions from '../../../utils/filterOptions';

export default function TriggerFilter({
  handleForm,
  currentTriggerValue,
  currentEventsValue,
}) {
  const [applicationEventsItems, setApplicationEventsItems] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [eventsValue, setEventsValue] = useState(
    currentEventsValue.map((event) => ({
      id: event,
      label: event,
      value: event,
    })),
  );

  const handleCurrentEventsValue = () => {
    const updateEvents = currentEventsValue.map((event) => ({
      id: event,
      label: event,
      value: event,
    }));
    setEventsValue(updateEvents);
  };

  useEffect(() => {
    async function getData() {
      setApplicationEventsItems([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      const responseApplicationEvents =
        await ApplicationEventsService.fetchApplicationEvents();
      /* istanbul ignore else */
      if (responseApplicationEvents.data) {
        const parsedApplicationEvents = responseApplicationEvents.data.map(
          (sn) => ({
            id: sn.attributes.name,
            label: sn.attributes.name,
            value: sn.attributes.name,
          }),
        );
        setApplicationEventsItems(parsedApplicationEvents);
      }
    }

    getData();
  }, []);

  return (
    <>
      <P marginBottom={1}>
        <Trans>Trigger</Trans>
      </P>
      <RadioGroup
        name="radio_trigger"
        items={[
          { label: t`Both`, value: 'Both' },
          { label: t`HTTP Call`, value: 'http_api_request' },
          { label: t`Application Events`, value: 'application_event' },
        ]}
        vertical
        value={currentTriggerValue}
        onChange={(e) => {
          handleForm({ event: e, field: 'trigger' });
        }}
      />
      {(currentTriggerValue === 'application_event' ||
        currentTriggerValue === 'Both') && (
        <Flex marginTop={1} marginLeft={3.4}>
          <Combobox
            hideLabel
            multiple
            marginTop={5}
            label={t`Application Events`}
            options={filterOptions(applicationEventsItems, inputValue)}
            onChangeInputValue={(e) => setInputValue(e ? e.target.value : '')}
            value={eventsValue}
            zIndex={777}
            onChangeValue={(selectedId, options) => {
              handleForm({
                selectedId,
                options,
                field: 'events',
              });
              handleCurrentEventsValue();
            }}
          />
        </Flex>
      )}
    </>
  );
}

TriggerFilter.propTypes = {
  handleForm: PropTypes.func.isRequired,
  currentTriggerValue: PropTypes.string.isRequired,
  currentEventsValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
};
