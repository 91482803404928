import React, { Fragment } from 'react';

import { H3 } from '@decisiv/ui-components';
import { PropTypes } from 'prop-types';
import ChangeList from './ChangeList';

export default function ChangeHistory({ histories, integration, location }) {
  return (
    <>
      <H3 marginBottom={1.8}>History</H3>
      {Object.values(histories).map((history, index) => (
        <Fragment key={`${String(index)}`}>
          <ChangeList
            changes={history.attributes.changes}
            author={history.attributes.author}
            createdAt={history.attributes.date}
            integration={integration}
            location={location}
          />
        </Fragment>
      ))}
    </>
  );
}

ChangeHistory.defaultProps = {
  location: {},
};

ChangeHistory.propTypes = {
  histories: PropTypes.oneOfType([PropTypes.object]).isRequired,
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]),
};
