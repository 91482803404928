import { uniq } from 'lodash';
import api from './api';

const fetchCommands = async (filter = []) => {
  const uniqFilter = uniq(filter);

  try {
    const url =
      uniqFilter.length > 0
        ? `/commands?filter[name]=${uniqFilter.join(',')}`
        : '/commands';
    const { data } = await api.get(url);

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

/**
 * Fetches commands schemas and builds a new object to group schemas
 * by command
 * @param {Array} filter
 * @returns Example
 * {
 *  FindCaseCommand: {
 *    input_schema: {..},
 *    output_schema: {..}
 *  },
 *  MapParametersCommand: {
 *    input_schema: {..},
 *    output_schema: {..}
 *  }
 * }
 */
const fetchSchemasGroupedByCommand = async (filter = []) => {
  const schemas = await fetchCommands(filter);

  const schemasGroupedByCommand = {};
  schemas.data.forEach(({ attributes }) => {
    schemasGroupedByCommand[attributes.name] = {
      input_schema: attributes.input_schema,
      output_schema: attributes.output_schema,
    };
  });

  return schemasGroupedByCommand;
};

export default {
  fetchCommands,
  fetchSchemasGroupedByCommand,
};
