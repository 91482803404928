import React, { useEffect, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { Alert, Button, P, TextField } from '@decisiv/ui-components';
import { Trans } from '@lingui/macro';
import Loading from '@decisiv/ui-components/lib/components/Loading';

import TopNav from '@decisiv/ui-components/lib/components/TopNav';
import Paper from '../components/Paper';
import AvatarProd from '../images/logo.png';
import AvatarStaging from '../images/AvatarStaging.png';
import AvatarQa from '../images/AvatarQA.png';
import { Divider } from '../components/StyledComponents';

import '../styles/SignIn.css';
import login from '../api/login';
import { encryptStorage } from '../utils/storage';
import oktaLogo from '../images/okta_logo.png';
import formatEnvironment from '../utils/formatEnv';
import getEnvironment from '../utils/getCurrentEnv';

export default function SignIn() {
  const passwordRef = useRef(null);
  const userNameRef = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isOktaLogin, setIsOktaLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const handleOktaLogin = async (token) => {
      setIsButtonDisabled(true);
      setShowAlert(false);
      setIsOktaLogin(true);

      const queryParams = new URLSearchParams(window.location.search);
      const redirectError = queryParams.get('error');
      if (redirectError) {
        setErrorMessage(redirectError);
        setShowAlert(true);
        return;
      }

      const response = await login.loginRequest('', '', token);

      if (response.errors) {
        setErrorMessage(response.errors[0].detail);
        setShowAlert(true);
        setIsButtonDisabled(false);
        return;
      }

      encryptStorage.setItem(
        'authToken',
        response.data.attributes.access_token,
      );
      encryptStorage.setItem(
        'userSession',
        JSON.stringify(response.data.attributes),
      );

      navigate('/integrations');
    };

    // OKTA Redirect Login
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size > 0) {
      const token = queryParams.get('sso_token');
      handleOktaLogin(token);
    }

    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        document.getElementById('login').click();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const handleClick = async () => {
    setIsButtonDisabled(true);
    setShowAlert(false);

    const response = await login.loginRequest(
      userNameRef.current.value,
      passwordRef.current.value,
    );

    if (response.errors) {
      setErrorMessage(response.errors[0].detail);
      setShowAlert(true);
      setIsButtonDisabled(false);
      return;
    }

    encryptStorage.setItem('authToken', response.data.attributes.access_token);
    encryptStorage.setItem(
      'userSession',
      JSON.stringify(response.data.attributes),
    );

    navigate('/integrations');
  };

  const currentEnv = getEnvironment(process.env.REACT_APP_BASE_URL);

  const getImageByEnv = () => {
    const environment = currentEnv || 'qa';

    const images = {
      qa: AvatarQa,
      staging: AvatarStaging,
      production: AvatarProd,
    };

    return images[environment] || images.qa;
  };

  return (
    <Paper css="height: 100%;" padding={0}>
      <TopNav className="TopNav">
        <Flex paddingX={1}>
          <TopNav.BrandInfo
            logo={getImageByEnv()}
            primaryText="Decisiv"
            secondaryText="Integration Framework"
          />
          {['qa', 'staging'].includes(currentEnv) && (
            <>
              <Flex alignItems="flex-end" marginLeft={0.5}>
                <P marginRight={0.5}>|</P>
                <P weight="semibold">{formatEnvironment(currentEnv)}</P>
              </Flex>
            </>
          )}
        </Flex>
      </TopNav>

      <Flex
        alignItems="center"
        flexDirection="column"
        className="PageContainer"
      >
        <form name="loginForm">
          <Flex
            alignItems="center"
            flexDirection="column"
            className="SignInForm"
          >
            <span className="LoginHeaderText">
              <Trans>Log in to Integration Framework</Trans>
              {isOktaLogin && (
                <Flex marginTop={1} justifyContent="center">
                  <Flex>
                    <img
                      style={{ width: '300px', height: '100px' }}
                      src={oktaLogo}
                      alt="Okta Logo"
                    />
                  </Flex>
                </Flex>
              )}

              {!showAlert && isOktaLogin && (
                <Loading size="large" description="Logging in" />
              )}
            </span>
            <Flex
              alignItems="center"
              flexDirection="column"
              className="SignInFields"
            >
              {showAlert && (
                <Flex className="WideFlex">
                  <Flex flex={1}>
                    <Trans>
                      <Alert title={errorMessage} intent="danger" />
                    </Trans>
                  </Flex>
                </Flex>
              )}

              {isOktaLogin ? (
                <Flex className="WideFlex">
                  <a href="/sign_in">Return to login page</a>
                </Flex>
              ) : (
                <>
                  <Flex className="WideFlex">
                    <Flex flex={1}>
                      <Trans>
                        <TextField
                          ref={userNameRef}
                          label="Username or email"
                          name="LoginInformation"
                        />
                      </Trans>
                    </Flex>
                  </Flex>
                  <Flex className="WideFlex">
                    <Flex flex={1}>
                      <Trans>
                        <TextField
                          ref={passwordRef}
                          label="Password"
                          name="password"
                          type="password"
                        />
                      </Trans>
                    </Flex>
                  </Flex>
                  <Flex className="WideFlex">
                    <Trans>
                      <Button
                        type="button"
                        id="login"
                        text="LOG IN"
                        kind="primary"
                        onClick={handleClick}
                        disabled={isButtonDisabled}
                      />
                    </Trans>
                  </Flex>

                  <Flex className="WideFlex">
                    <Divider className="LeftDivider" />
                    <span>
                      <Trans>Or</Trans>
                    </span>
                    <Divider className="RightDivider" />
                  </Flex>
                  <Flex className="WideFlex">
                    <a href={process.env.REACT_APP_OKTA_AUTH_URL}>
                      Log in with Single Sign On (SSO)
                    </a>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </form>
        <Flex alignItems="center" flexDirection="column">
          <p>
            <span className="LicenseAndAgreements">
              <Trans>By logging into the application you agree to the</Trans>
            </span>{' '}
            <span className="LicenseLink LicenseAndAgreements">
              <Trans>Policies & Agreements.</Trans>
            </span>
          </p>
        </Flex>
      </Flex>
    </Paper>
  );
}
