const replaceTextWithBlank = (text, replace) => {
  return String(text).replace(replace, '');
};

/**
 * Given a command name like FindCaseCommand
 * @param {String} text
 * @returns Find Case
 */
export const titlelizeCommand = (text) => {
  return replaceTextWithBlank(text, 'Command').replace(
    /([a-z])([A-Z])/g,
    '$1 $2',
  );
};

/**
 * Given a command name like GlobalLevelMapParameters1
 * @param {String} text
 * @returns Map Pamarateres
 */
export const normalizeCommandName = (text) => {
  return String(text)
    .replace(/Command\d+/, '')
    .replace('GlobalLevel', '')
    .replace(/([a-z])([A-Z])/g, '$1 $2');
};
