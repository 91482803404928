import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider, NotificationsPanel } from '@decisiv/ui-components';
import './index.css';
import { Link } from '@reach/router';
import App from './App';

ReactDOM.render(
  <ConfigProvider linkRenderer={Link}>
    <NotificationsPanel marginTop={0} zIndex={1000} />
    <App />
  </ConfigProvider>,
  document.getElementById('root'),
);
