import api from './api';

const loginRequest = async (
  username = null,
  password = null,
  ssoToken = null,
) => {
  try {
    const { data } = await api.post(`${process.env.REACT_APP_BASE_URL}/login`, {
      data: {
        attributes: {
          username,
          password,
          ssoToken,
        },
      },
    });

    return { data: data.data };
  } catch (e) {
    switch (e.response.status) {
      case 401:
      case 422:
        return { errors: e.response?.data?.errors };
      default:
        return {
          errors: [{ detail: 'Something went wrong, please try again' }],
        };
    }
  }
};

export default { loginRequest };
