import React from 'react';
import { Flex } from '@decisiv/ui-components/lib/components';
import { H1 } from '@decisiv/ui-components';
import { ActionsSection, LineDivider } from './style';
import ActionSearch from './actionSearch';

export default function ActionsPage() {
  return (
    <>
      <ActionsSection
        flexDirection="column"
        justifyContent="center"
        padding={2}
        flex="none"
        flexGrow="0"
      >
        <Flex flexDirection="row">
          <Flex flex={1} title="Actions">
            <H1>Actions</H1>
          </Flex>
        </Flex>
      </ActionsSection>
      <LineDivider />
      <ActionSearch />
    </>
  );
}
