import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import Flex from '@decisiv/ui-components/lib/components/Flex';

const ArchiveCardFooter = styled(Flex)`
  background-color: ${toColorString(color.base.fullMoon)};
  margin-top: auto;
  align-items: flex-start;
  justify-content: space-between;
`;

export { ArchiveCardFooter };
