import React, { useMemo } from 'react';
import Asterisk from '@decisiv/iconix/lib/components/Asterisk';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import { Trans } from '@lingui/macro';
import { P } from '@decisiv/ui-components';

const AsteriskSubtitle = () => {
  const asteriskIcon = useMemo(
    () => (
      <Asterisk
        color={toColorString(color.status.information.medium)}
        css={{ verticalAlign: 'middle' }}
        size="small"
      />
    ),
    [],
  );

  return (
    <P size="small" shade={1}>
      <Trans>All fields marked {asteriskIcon} are required</Trans>
    </P>
  );
};

export default AsteriskSubtitle;
