import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import spacing from '@decisiv/design-tokens/lib/spacing';

export const ChangesWrapper = styled.pre`
  background: #f1f2f8;
  padding: ${rem(spacing.base * 1.5)};
  width: 10rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  flex-grow: 1;
  color: ${toColorString(color.base.alaskanHusky)};
`;
