import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid, H3, P, TextArea } from '@decisiv/ui-components';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import SectionRow from '../../components/SectionRow';

export default function CacheSettings({ handleForm, triggerTypeHttpRequest }) {
  const [showField, setShowField] = useState(false);

  const handleShowField = () => {
    setShowField(!showField);
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [triggerTypeHttpRequest]);

  return (
    <>
      {triggerTypeHttpRequest ? (
        <SectionRow>
          <Grid.Column padding={0} span="3">
            <H3>
              <Trans>Cache Settings</Trans>
            </H3>
          </Grid.Column>
          <Grid.Column padding={0}>
            <P shade={1} size="small" marginBottom={1}>
              <Trans>
                Enable caching for some integrations which result does not
                change often, you can define how long integration gonna be
                cached in seconds
              </Trans>
            </P>
            <RadioGroup
              name="cache_enabled"
              size="medium"
              items={[
                { label: t`Yes`, value: true },
                { label: t`No`, value: false },
              ]}
              onChange={(e) => {
                handleForm({ event: e });
                handleShowField();
              }}
              defaultValue={false}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            <br />
            {showField ? (
              <TextArea
                label={t`Cache TTL in seconds`}
                name={t`cache_ttl`}
                required
                type="number"
                onChange={(e) => {
                  handleForm({ event: e });
                }}
                defaultValue={0}
                maxLength={6}
              />
            ) : null}
          </Grid.Column>
        </SectionRow>
      ) : (
        ''
      )}
    </>
  );
}

CacheSettings.defaultProps = {};

CacheSettings.propTypes = {
  handleForm: PropTypes.func.isRequired,
  triggerTypeHttpRequest: PropTypes.bool.isRequired,
};
