import React from 'react';

import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { Button, Flex, TextField } from '@decisiv/ui-components';
import MinusCircle from '@decisiv/iconix/lib/components/MinusCircle';
import PlusCircle from '@decisiv/iconix/lib/components/PlusCircle';

export default function EditMetadata({ metadata, handlers, editDisabled }) {
  return (
    <>
      {metadata.map((item, index) => {
        return (
          <Flex
            justifyContent="flex-start"
            alignItems="center"
            style={{
              whiteSpace: 'nowrap',
              gap: '1.25rem',
              margin: '-1px',
              border: '1px solid #e3ebf1',
              padding: '0.625rem',
            }}
          >
            <Button
              aria-label={t`Remove entry`}
              variant="ghost"
              icon={MinusCircle}
              disabled={editDisabled}
              onClick={() => handlers.removeEntry(index)}
            />
            <TextField
              required
              value={item[0]}
              label={t`key`}
              name={`key-${index}`}
              style={{ marginTop: '0.5rem' }}
              maxLength={50}
              disabled={editDisabled}
              showCharacterCount
              onChange={(e) => handlers.changeKey({ e, item, index })}
            />
            <TextField
              required
              value={item[1]}
              label={t`value`}
              name={`value-${index}`}
              style={{ marginTop: '0.5rem' }}
              maxLength={500}
              disabled={editDisabled}
              showCharacterCount
              onChange={(e) => handlers.changeValue({ e, item, index })}
            />
          </Flex>
        );
      })}
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        style={{
          border: '1px solid #e3ebf1',
          margin: '-1px',
          gap: '1.25rem',
          padding: '0.625rem',
        }}
      >
        <Button
          aria-label={t`Add Metadata`}
          text={t`Add Metadata`}
          icon={PlusCircle}
          variant="ghost"
          disabled={editDisabled || metadata[metadata.length - 1]?.[0] === ''}
          onClick={handlers.addEntry}
        />
      </Flex>
    </>
  );
}

EditMetadata.propTypes = {
  metadata: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  handlers: PropTypes.shape.isRequired,
  editDisabled: PropTypes.bool,
};

EditMetadata.defaultProps = {
  editDisabled: true,
};
