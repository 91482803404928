import React from 'react';
import { Flex, Modal, P } from '@decisiv/ui-components';
import Pencil from '@decisiv/iconix/lib/components/Pencil';
import PropTypes from 'prop-types';
import { navigate, useParams } from '@reach/router';
import { useNotifications } from '@decisiv/ui-components/lib/components/Notifications';
import { t, Trans } from '@lingui/macro';
import Arrow from '@decisiv/iconix/lib/components/ArrowRight';
import { capitalize } from 'lodash/string';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import IntegrationsService from '../../api/integrations';
import ChangesContentWrapper from './changesContentWrapper';
import { ArrowWrapper } from './style';

export default function EditModal({
  showEditModal,
  handleToggleEditModal,
  form,
  integration,
}) {
  const { notify } = useNotifications();
  const params = useParams();

  const notificationSuccessProps = {
    intent: 'success',
    title: t`Changes Saved`,
    duration: 3500,
    onClose: noop,
  };

  const notificationErrorProps = {
    intent: 'danger',
    title: t`Error`,
    duration: 3500,
    onClose: noop,
  };

  const handleSubmit = async () => {
    const events =
      !form?.trigger_type || form.trigger_type.includes('application_event')
        ? form.events
        : [];

    const integrationAttributes = {
      name: form.name,
      description: form.description,
      category: form.category,
      audience: form.audience,
      service_network: form.service_network,
      events,
      trigger_type: form.trigger_type,
      action: form.action,
    };

    const response = await IntegrationsService.updateIntegration(
      params.id,
      integrationAttributes,
    );

    /* istanbul ignore else */
    if (response.data) {
      navigate(`/integrations/${integration.id}`, {
        state: { fromEdit: true },
      });
      notify(notificationSuccessProps, t`${form.name} has been edited.`);
    } else {
      notify(notificationErrorProps, t`${form.name} could not be edited.`);
    }
  };

  return (
    <>
      <Modal
        icon={Pencil}
        color="information"
        onClose={handleToggleEditModal}
        title="Confirm Changes"
        visible={showEditModal}
        actions={[
          {
            text: t`Confirm Changes`,
            onClick: handleSubmit,
            intent: 'success',
          },
          { text: t`Cancel`, onClick: handleToggleEditModal },
        ]}
      >
        <Flex flexDirection="column">
          <P
            color="alaskanHusky"
            weight="medium"
            marginTop={1}
            marginBottom={1}
          >
            <Trans>
              Clicking CONFIRM CHANGES will update this integration in the
              Integrations Framework.
            </Trans>
          </P>

          {form !== null && !isEmpty(integration) && (
            <Flex flexDirection="column">
              {Object.keys(form).map((attribute) => (
                <>
                  <P
                    shade={1}
                    size="small"
                    color="charcoal"
                    marginBottom={-0.8}
                  >
                    <b>{capitalize(attribute)}</b> <Trans>updated</Trans>
                  </P>
                  <Flex flexDirection="row" marginBottom={1}>
                    <ChangesContentWrapper
                      content={JSON.stringify(
                        integration?.attributes[attribute],
                      )}
                    />
                    <ArrowWrapper>
                      <Arrow size="small" />
                    </ArrowWrapper>
                    <ChangesContentWrapper
                      content={JSON.stringify(form[attribute])}
                    />
                  </Flex>
                </>
              ))}
            </Flex>
          )}
        </Flex>
      </Modal>
    </>
  );
}

EditModal.propTypes = {
  showEditModal: PropTypes.bool.isRequired,
  handleToggleEditModal: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
