import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Combobox, Loading, P } from '@decisiv/ui-components';
import CommandsService from '../../../api/commands';
import filterOptions from '../../../utils/filterOptions';

export default function CommandsFilter({ handleForm, currentValue }) {
  const [commands, setCommands] = useState([]);
  const [commandsValue, setCommandsValue] = useState(
    currentValue.map((command) => ({
      id: command,
      label: command,
      value: command,
    })),
  );
  const [inputValue, setInputValue] = useState('');

  const handleCurrentValue = () => {
    const updateCommands = currentValue.map((command) => ({
      id: command,
      label: command,
      value: command,
    }));
    setCommandsValue(updateCommands);
  };

  function parseCommands({ data }) {
    const parsedData = data.map((command) => {
      return {
        id: command.attributes.name,
        label: command.attributes.name,
        value: command.attributes.name,
        name: command.attributes.name,
      };
    });

    return parsedData;
  }

  useEffect(() => {
    async function getData() {
      setCommands([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      const responseCommands = await CommandsService.fetchCommands();
      /* istanbul ignore else */
      if (responseCommands.data) {
        const parsedCommands = parseCommands(responseCommands);
        setCommands(parsedCommands);
      }
    }

    getData();
  }, []);

  return (
    <>
      <P marginBottom={1}>
        <Trans>Commands</Trans>
      </P>
      <Combobox
        name="CommandsInputFilter"
        hideLabel
        multiple
        label={t`Commands`}
        options={filterOptions(commands, inputValue)}
        onChangeInputValue={(e) => setInputValue(e ? e.target.value : '')}
        value={commandsValue}
        zIndex={777}
        onChangeValue={(selectedId, options) => {
          handleForm({
            selectedId,
            options,
            field: 'commands',
          });
          handleCurrentValue();
        }}
      />
    </>
  );
}

CommandsFilter.propTypes = {
  handleForm: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object]).isRequired,
  ).isRequired,
};
