import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import Pencil from '@decisiv/iconix/lib/components/Pencil';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import {
  Alert,
  Button,
  Checkbox,
  Grid,
  TextArea,
  useNotifications,
} from '@decisiv/ui-components';
import { H3, H4, P } from '@decisiv/ui-components/lib/components/Typography';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { t, Trans } from '@lingui/macro';
import { isEmpty, noop, startCase, trim } from 'lodash';

import {
  FormDivider,
  FormGroup,
  IntegrationForm,
  SectionRow,
} from './StyledComponents';
import ConfiguredIntegrationsService from '../api/configured_integrations';
import validEmail from '../utils/validEmail';
import encryptText from '../utils/encryptText';
import { buildSchemaContent } from '../utils/buildSchemaContent';
import SchemaDetailsModal from './SchemaDetailsModal';
import CommandsService from '../api/commands';
import CommandIconAndTitle from './CommandIconAndTitle';
import { SETTINGS_PAGE_INPUT_PAYLOAD_TEXT } from '../common/constants/staticTexts';

const Form = (props) => {
  const [form, setForm] = useState({ ...props.locationSettingsAttributes });
  const [formAttributes, setFormAttributes] = useState({
    ...props.configuredIntegration.attributes,
  });
  const locationInitialValue = useRef();
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [schemasByCommand, setSchemasByCommand] = useState([]);
  const [schemasContent, setSchemasContent] = useState({
    input: '',
    output: '',
  });
  const [apiError, setApiError] = useState([]);
  const [schemaDetailsModalVisibility, setSchemaDetailsModalVisibility] =
    useState(false);

  const locationConfigured = useCallback(() => {
    const filterCondition = (field) => field.name.includes('token');

    // Get the form field names and their values for refresh_token and access_token
    const tokenFields = Object.values(form).filter(filterCondition);

    const fieldsToSkip = /password/i;

    // Check if both refresh_token and access_token have values
    const tokensHaveValue = tokenFields.every(
      (field) => !isEmpty(trim(field.value)),
    );

    return Object.values(form)
      .filter((formField) => {
        // Ignore the password field if both tokens have values
        if (tokensHaveValue) {
          return !fieldsToSkip.test(formField.name); // Skip password validation
        }

        // Continue validating required fields
        return Object.values(props.locationSettingsSchema)
          .flat()
          .filter((schemaAttr) => schemaAttr.required)
          .map((attr) => attr.name)
          .includes(formField.name);
      })
      .every((value) => !isEmpty(trim(value.value)));
  }, [form]);

  const setInputLabel = (name) => {
    const inputWithoutNumber = String(name).replace(/[0-9]/g, '');

    if (
      Object.keys(props.locationSettingsAttributes).filter((v) =>
        props.locationSettingsAttributes[v].name.includes(inputWithoutNumber),
      ).length > 1
    ) {
      return name;
    }

    return inputWithoutNumber;
  };

  const fileTypeValue = useCallback(
    (fileTypeName) => {
      const elements = Object.values(form).filter((formField) =>
        Object.values(props.locationSettingsSchema)
          .flat()
          .filter((schemaAttr) => schemaAttr.required)
          .map((attr) => attr.name)
          .includes(formField.name),
      );
      return elements.filter(
        (element) => setInputLabel(element.name) === fileTypeName,
      );
    },
    [form],
  );

  const validateEmailFormat = (value) => {
    try {
      const isValid = JSON.parse(value.value).every((val) => validEmail(val));

      if (isValid) {
        setErrors({ ...errors, [value.name]: '' });
        return true;
      }
      setErrors({ ...errors, [value.name]: t`Invalid email` });
      return false;
    } catch (error) {
      setErrors({ ...errors, [value.name]: t`Invalid format ${error}` });
      return false;
    }
  };

  const validateExtension = (value) => {
    if (setInputLabel(value.name) === 'recipients') {
      return validateEmailFormat(value);
    }
    if (
      !(
        setInputLabel(value.name) === 'file_name' ||
        setInputLabel(value.name) === 'file_type'
      )
    ) {
      return true;
    }
    const ext = trim(value.value)
      .substring(trim(value.value).lastIndexOf('.') + 1)
      .toLowerCase();
    if (!(ext === 'xml' || ext === 'json' || ext === 'pdf')) return false;

    if (
      setInputLabel(value.name) === 'file_name' &&
      fileTypeValue('file_type').length === 0
    ) {
      return true;
    }

    if (trim(fileTypeValue('file_type')[0].value).toLowerCase() !== ext) {
      setErrors({
        ...errors,
        [value.name]: `Invalid extension: ${ext}. Check File Type.`,
      });
      return false;
    }

    if (setInputLabel(value.name) === 'file_name') {
      setErrors({ ...errors, [value.name]: '' });
    }

    return true;
  };

  const valuesConfigured = useCallback(() => {
    return Object.values(form)
      .filter((formField) =>
        Object.values(props.locationSettingsSchema)
          .flat()
          .filter((schemaAttr) => schemaAttr.required)
          .map((attr) => attr.name)
          .includes(formField.name),
      )
      .every((value) => validateExtension(value));
  }, [form]);

  useEffect(() => {
    locationInitialValue.current = JSON.parse(
      JSON.stringify(props.configuredIntegration),
    );
    setForm(props.locationSettingsAttributes);
    props.setToggleDisabled(!locationConfigured());
  }, [props.locationSettingsAttributes]);

  useEffect(() => {
    setFormAttributes(props.configuredIntegration.attributes);
  }, [props.configuredIntegration.attributes]);

  useEffect(() => {
    async function getSchemasByCommand() {
      if (props.integration?.id) {
        const schemasGroupedByCommand =
          await CommandsService.fetchSchemasGroupedByCommand(
            props.integration.attributes.commands_flow,
          );

        setSchemasByCommand(schemasGroupedByCommand);
      }
    }

    getSchemasByCommand();
  }, [props.integration?.id, props.integration.attributes.commands_flow]);

  const handleTextChangeAdditionalDetails = (e) => {
    formAttributes.additional_details = e.target.value.includes('{')
      ? e.target.value.replace(/\n/g, '')
      : e.target.value;
    setFormAttributes(formAttributes);
  };
  const handleTextChange = (e) => {
    const index =
      props.configuredIntegration.attributes.location_settings_attributes.findIndex(
        (da) => da.name === e.target.name,
      );

    const newForm = form;

    newForm[index].value = e.target.value.includes('{')
      ? e.target.value.replace(/\n/g, '')
      : e.target.value;
    setForm(newForm);
  };

  const validateEmailField = (e) => {
    if (e.target.required && isEmpty(trim(e.target.value))) {
      setErrors({ ...errors, [e.target.name]: t`Can't be blank` });
      setDisableSubmit(true);

      return;
    }
    setDisableSubmit(!validateEmailFormat(e.target));
  };

  const handleCheckboxChange = (e) => {
    const index =
      props.configuredIntegration.attributes.location_settings_attributes.findIndex(
        (da) => da.name === e.target.name,
      );

    const newForm = form;

    newForm[index].value = e.target.checked.toString();
    setForm(newForm);
  };

  const toggleEdit = () => {
    setEdit(!edit);
    setDisableSubmit(!edit);
  };

  const cancelEdit = () => {
    props.setLocation({ ...locationInitialValue.current });
    props.setFormKey(Math.random());
    setEdit(true);
  };

  const notificationsProps = {
    intent: 'success',
    title: `Updated Integration Settings`,
    onClose: noop,
  };

  const { notify } = useNotifications();

  const handleSubmit = async () => {
    setDisableSubmit(true);
    setApiError([]);
    const attributesWithObject = Object.keys(form).map((number) => {
      try {
        return {
          name: form[number].name,
          value:
            form[number].value.includes('{') || form[number].value.includes('[')
              ? JSON.parse(form[number].value)
              : form[number].value,
        };
      } catch (e) {
        return {
          name: form[number].name,
          value: form[number].value,
        };
      }
    });

    // eslint-disable-next-line no-shadow
    const { data, errors } =
      await ConfiguredIntegrationsService.updateConfiguredIntegration(
        props.configuredIntegration.id,
        {
          location_settings_attributes: attributesWithObject,
          additional_details: formAttributes.additional_details,
        },
      );
    if (data) {
      const { data: configuredIntegrationData } =
        await ConfiguredIntegrationsService.fetchConfiguredIntegrationsByUuid(
          props.configuredIntegration.id,
        );

      props.setLocation(configuredIntegrationData);

      const successMessageBody = `${props.configuredIntegration.attributes.name} was successfully updated.`;
      setEdit(true);
      notificationsProps.intent = 'success';
      notify(notificationsProps, successMessageBody);
    } else {
      const failureMessage = `Failed to update ${props.configuredIntegration.attributes.name}.`;
      notificationsProps.intent = 'warning';
      notify(notificationsProps, failureMessage);
      setApiError(errors);
      setDisableSubmit(false);
    }
  };

  const validateField = (e) => {
    if (e.target.required && isEmpty(trim(e.target.value))) {
      setErrors({ ...errors, [e.target.name]: t`Can't be blank` });
    } else if (!validateExtension(e.target)) {
      setErrors({
        ...errors,
        [e.target.name]: t`Invalid extension (Allowed: xml, pdf or json)`,
      });
    } else {
      setErrors({ ...errors, [e.target.name]: '' });
    }
    if (locationConfigured()) setDisableSubmit(!valuesConfigured());
    else setDisableSubmit(true);
    props.setToggleDisabled(!locationConfigured());
  };

  const additionalDetailsElement = (configuredIntegration) => {
    if (Object.keys(configuredIntegration).length === 0) {
      return '';
    }

    const inputProps = {
      name: 'additional_details',
      label: setInputLabel('Additional Details'),
      onChange: handleTextChangeAdditionalDetails,
      disabled: edit,
      maxLength: 500,
      defaultValue: '',
    };
    if (isEmpty(configuredIntegration.attributes.additional_details)) {
      return <TextArea {...inputProps} />;
    }
    return (
      <TextArea
        {...inputProps}
        defaultValue={formAttributes.additional_details}
      />
    );
  };

  /* istanbul ignore next */
  const transformObject = (inputProps) => {
    const objectKeys = Object.keys(inputProps.defaultValue);
    const currentValue =
      objectKeys.length > 0
        ? `{\n${objectKeys
            .map(
              (e) =>
                `${JSON.stringify(e)}: ${
                  typeof inputProps.defaultValue[e] === 'boolean'
                    ? inputProps.defaultValue[e]
                    : JSON.stringify(inputProps.defaultValue[e])
                }`,
            )
            .join(',\n')}\n}`
        : '{\n}';

    return currentValue;
  };

  /* istanbul ignore next */
  const transformArray = (inputProps) => {
    return `[\n${inputProps.defaultValue.map((e) => `"${e}"`).join(',\n')}\n]`;
  };

  /* istanbul ignore next */
  function getCurrentValue(inputProps) {
    let currentValue = '';

    switch (inputProps.defaultValue?.constructor) {
      case Object:
        currentValue = transformObject(inputProps);
        break;
      case Array:
        currentValue = transformArray(inputProps);
        break;
      default:
        currentValue = inputProps.defaultValue;
        break;
    }

    return currentValue;
  }

  const decideFormElement = (input, configuredIntegration) => {
    const { name: formId } = input;

    if (Object.keys(configuredIntegration).length === 0) {
      return '';
    }

    const defaultValueIndex =
      configuredIntegration.attributes.location_settings_attributes.findIndex(
        (da) => da.name === formId,
      );

    let defaultValue = '';

    if (
      configuredIntegration.attributes.location_settings_attributes[
        defaultValueIndex
      ]
    ) {
      defaultValue =
        configuredIntegration.attributes.location_settings_attributes[
          defaultValueIndex
        ].value;

      if (
        formId.includes('header') &&
        defaultValue &&
        !edit &&
        Object.keys(defaultValue).length === 0
      ) {
        defaultValue = '{\n}';
      }
    }

    const inputProps = {
      name: formId,
      label: setInputLabel(formId),
      key: formId,
      onChange: handleTextChange,
      onBlur: validateField,
      disabled: edit,
      required: input.required,
      warningMessage: errors[input.name],
      defaultValue,
    };

    if (input.html_tag === 'text_input') {
      return <TextField {...inputProps} />;
    }

    if (input.html_tag === 'password_input' && !input.html_tag_hidden) {
      inputProps.type = 'password';

      const [cipherText, originalText] = encryptText(inputProps.defaultValue);

      return edit ? (
        <TextField {...inputProps} defaultValue={cipherText} />
      ) : (
        <TextField
          {...inputProps}
          key={Math.random()}
          defaultValue={originalText}
        />
      );
    }

    if (input.html_tag === 'password_input' && input.html_tag_hidden) {
      inputProps.type = 'hidden';

      const [cipherText, originalText] = encryptText(inputProps.value);

      return edit ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <input {...inputProps} defaultValue={cipherText} value={cipherText} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <input {...inputProps} value={originalText} />
      );
    }

    if (input.html_tag === 'email_input') {
      inputProps.onBlur = validateEmailField;

      return <TextArea {...inputProps} />;
    }

    if (input.html_tag === 'checkbox_input') {
      if (
        configuredIntegration.attributes.location_settings_attributes[
          defaultValueIndex
        ]?.value === 'true'
      ) {
        inputProps.checked = true;
      }
      inputProps.label = setInputLabel(startCase(input.name));
      inputProps.onChange = handleCheckboxChange;
      return <Checkbox {...inputProps} />;
    }

    if (input.html_tag === 'object_input' || input.html_tag === 'text_area') {
      const currentValue = getCurrentValue(inputProps);

      return <TextArea {...inputProps} defaultValue={currentValue} />;
    }

    return '';
  };

  const handleSchemaDetailsModalOpen = (commandName) => {
    const content = buildSchemaContent(
      commandName,
      schemasByCommand,
      props.integration.attributes.schemas,
    );
    setSchemasContent(content);
    setSchemaDetailsModalVisibility(true);
  };

  return (
    <>
      <SchemaDetailsModal
        content={schemasContent}
        visibility={schemaDetailsModalVisibility}
        handleVisibility={setSchemaDetailsModalVisibility}
      />
      <Grid.Container>
        <Grid.Row>
          <Grid.Column>
            <IntegrationForm>
              <Grid.Container>
                <SectionRow>
                  <Grid.Column span="10">
                    <Flex>
                      <Flex flexDirection="column">
                        <H3>
                          <Trans>Location Settings</Trans>
                        </H3>
                        <P shade={1} size="small">
                          <Trans>Configure the global settings for</Trans>
                          &nbsp;{props?.integration?.attributes?.name}&nbsp;
                          <Trans>
                            integration. All fields marked * are required.
                          </Trans>
                        </P>
                      </Flex>
                    </Flex>
                  </Grid.Column>
                  {edit && (
                    <Grid.Column span="2">
                      <Flex justifyContent="right">
                        <Button
                          icon={Pencil}
                          text="Edit"
                          kind="secondary"
                          onClick={toggleEdit}
                        />
                      </Flex>
                    </Grid.Column>
                  )}
                </SectionRow>

                {apiError.map((error) => (
                  <SectionRow>
                    <Grid.Column span="12">
                      <Alert
                        intent="danger"
                        title={t`Error Saving Changes`}
                        dismissable
                      >
                        {error?.detail}
                      </Alert>
                    </Grid.Column>
                  </SectionRow>
                ))}

                <SectionRow key="InputPayload">
                  <Grid.Column span="2">
                    <CommandIconAndTitle
                      title={t`InputPayload`}
                      onClick={() =>
                        handleSchemaDetailsModalOpen(t`InputPayload`)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <P shade={1} style={{ marginTop: 7 }}>
                      <Trans>{SETTINGS_PAGE_INPUT_PAYLOAD_TEXT}</Trans>
                    </P>
                  </Grid.Column>
                </SectionRow>
                {Object.keys(props.locationSettingsSchema).map(
                  (classification) => (
                    <SectionRow key={classification}>
                      <Grid.Column span="2">
                        <CommandIconAndTitle
                          title={classification}
                          onClick={() =>
                            handleSchemaDetailsModalOpen(classification)
                          }
                        />
                      </Grid.Column>
                      <Grid.Column key={`Column${classification}`}>
                        {Object.keys(
                          props.locationSettingsSchema[classification],
                        ).map((input) => (
                          <FormGroup key={`FormGroup${classification + input}`}>
                            {decideFormElement(
                              props.locationSettingsSchema[classification][
                                input
                              ],
                              props.configuredIntegration,
                            )}
                          </FormGroup>
                        ))}
                        <FormDivider />
                      </Grid.Column>
                    </SectionRow>
                  ),
                )}
                <SectionRow>
                  <Grid.Column span="2">
                    <H4> Additional Details </H4>
                  </Grid.Column>
                  <Grid.Column key={`Column 'additional_details'`}>
                    <FormGroup>
                      {additionalDetailsElement(props.configuredIntegration)}
                    </FormGroup>
                    {!edit && (
                      <Flex marginTop={2}>
                        <Button
                          text="Save"
                          disabled={disableSubmit}
                          onClick={handleSubmit}
                        />
                        <Button
                          style={{ marginLeft: '0.4rem' }}
                          text="Cancel"
                          onClick={cancelEdit}
                          kind="secondary"
                        />
                      </Flex>
                    )}
                  </Grid.Column>
                </SectionRow>
              </Grid.Container>
            </IntegrationForm>
          </Grid.Column>
        </Grid.Row>
      </Grid.Container>
    </>
  );
};

export default Form;

Form.propTypes = {
  locationSettingsSchema: PropTypes.oneOfType([PropTypes.object]).isRequired,
  configuredIntegration: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  locationSettingsAttributes: PropTypes.any.isRequired,
  setLocation: PropTypes.func.isRequired,
  setFormKey: PropTypes.func.isRequired,
  setToggleDisabled: PropTypes.func.isRequired,
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
