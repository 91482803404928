import api from './api';

const fetchCategoriesAndActions = async () => {
  try {
    const { data } = await api.get(`/categories-and-actions`);

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

export default {
  fetchCategoriesAndActions,
};
