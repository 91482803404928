import styled, { keyframes } from 'styled-components';
import { toColorString } from 'polished';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import spacing from '@decisiv/design-tokens/lib/spacing';
import rem from 'polished/lib/helpers/rem';
import { P, Grid } from '@decisiv/ui-components';
import iconx from '@decisiv/iconix';

import color from '@decisiv/design-tokens/lib/color';

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const HelpInputMessage = styled(Flex)`
  display: flex;
  align-items: center;
  margin-top: ${rem(spacing.base * 0.2)};
  margin-bottom: ${rem(spacing.base * 2)};
  color: #737482;
`;

const HelpInputText = styled(P)`
  margin-left: ${rem(spacing.base * 0.5)};
`;

const ErrorInputMessage = styled(Flex)`
  display: flex;
  align-items: center;
  color: #ff4141;
`;

const CenteredContainer = styled(Flex)`
  width: 100%;
`;

const NoResultDescription = styled(P)`
  width: ${rem(spacing.base * 40)};
  text-align: center;
`;

const InfiniteLoading = styled(iconx.Refresh)`
  margin-top: ${rem(spacing.base * 2)};
  margin-bottom: ${rem(spacing.base * 2)};
  animation: ${rotate} 2s linear infinite;
`;

const LocationOption = styled(Grid.Row)`
  background-color: ${(props) =>
    props.selected
      ? toColorString(color.interaction.pacificOcean15)
      : toColorString(color.base.fullMoon)};
`;

const RowWithMarginBottom = styled(Grid.Row)`
  margin-bottom: ${rem(spacing.base * 2)};
`;

const SearchIcon = styled(iconx.Search)`
  margin-top: ${rem(spacing.base * 2)};
`;

export {
  HelpInputMessage,
  HelpInputText,
  CenteredContainer,
  NoResultDescription,
  LocationOption,
  InfiniteLoading,
  RowWithMarginBottom,
  SearchIcon,
  ErrorInputMessage,
};
