import React from 'react';
import { Flex, H1 } from '@decisiv/ui-components';
import { CommandSection, LineDivider } from './style';
import CommandSearch from './commandSearch';

export default function CommandPage() {
  return (
    <>
      <CommandSection
        flexDirection="column"
        justifyContent="center"
        padding={2}
        flex="none"
        flexGrow="0"
      >
        <Flex flexDirection="row">
          <Flex flex={1} title="Commands">
            <H1>Commands</H1>
          </Flex>
        </Flex>
      </CommandSection>
      <LineDivider />
      <CommandSearch />
    </>
  );
}
