import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';

import { P } from '@decisiv/ui-components/lib/components/Typography';
import Arrow from '@decisiv/iconix/lib/components/ArrowRight';
import { Avatar, Flex } from '@decisiv/ui-components';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { t, Trans } from '@lingui/macro';
import { ArrowContentWrapper, BigContentWrapper } from './style';
import ContentWrapper from './contentWrapper';

export default function ChangeList({
  changes,
  author,
  createdAt,
  integration,
  location,
}) {
  return (
    <>
      {changes.map((change, index) => (
        <Fragment key={`${author}-${createdAt}-${String(index)}`}>
          <Flex marginTop={0.15}>
            <Flex>
              <Avatar name={author} size="medium" />
            </Flex>
            <Flex flexDirection="column" paddingX={1}>
              <Flex>
                <P shade={1} size="small">
                  {/* Friday 9 September, 2022 · 3:27 PM*/}
                  {dayjs(createdAt).format('dddd D MMMM, YYYY · h:mm a')}
                </P>
              </Flex>
              <Flex>
                {change.fieldName === 'enabled' ? (
                  <P shade={1} size="small" color="charcoal">
                    <b>{author}</b>{' '}
                    {JSON.stringify(change.to) === 'true'
                      ? t`enabled`
                      : t`disabled`}{' '}
                    <b>{integration ? integration.attributes.name : ''}</b>
                    {!isEmpty(location) ? (
                      <>
                        <Trans>, at</Trans>
                        <b> {location.attributes.name}</b>
                      </>
                    ) : (
                      ''
                    )}
                  </P>
                ) : (
                  <P shade={1} size="small" color="charcoal">
                    <b>{author}</b> updated <b>{change.fieldName}</b>.
                  </P>
                )}
              </Flex>
            </Flex>
          </Flex>
          <BigContentWrapper>
            {change.fieldName === 'enabled' ? (
              <Flex marginBottom={1.7}> </Flex>
            ) : (
              <>
                <ContentWrapper content={JSON.stringify(change.from)} />
                <ArrowContentWrapper>
                  <Arrow size="small" />
                </ArrowContentWrapper>
                <ContentWrapper content={JSON.stringify(change.to)} />
              </>
            )}
          </BigContentWrapper>
        </Fragment>
      ))}
    </>
  );
}

ChangeList.defaultProps = {
  location: {},
};

ChangeList.propTypes = {
  changes: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  author: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  createdAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]),
};
