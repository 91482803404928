import axios from 'axios';
import { encryptStorage } from '../utils/storage';

let authToken = encryptStorage.getItem('authToken');

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { Authorization: authToken },
});

api.interceptors.request.use(async (request) => {
  authToken = encryptStorage.getItem('authToken');
  request.headers['Content-Type'] = 'application/vnd.api+json';

  if (authToken) {
    request.headers.Authorization = authToken;
  }
  return request;
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      encryptStorage.removeItem('authToken');
      encryptStorage.removeItem('userSession');

      window.history.replaceState({}, 'Sign_in page', '/sign_in');
      window.location.replace('sign_in');
      return {};
    }
    return Promise.reject(error);
  },
);
export default api;
