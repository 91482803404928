import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Button from '@decisiv/ui-components/lib/components/Button';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { t } from '@lingui/macro';
import { ChangesWrapper } from './changesWrapper.styled';

export default function ChangesContentWrapper({ content }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <ChangesWrapper>
        {showMore ? content : `${content.substring(0, 50)}`}
        {content.length > 50 && (
          <Flex justifyContent="right">
            <Button
              text={showMore ? t`See less` : t`See more`}
              size="small"
              variant="ghost"
              onClick={() => setShowMore(!showMore)}
            />
          </Flex>
        )}
      </ChangesWrapper>
    </>
  );
}

ChangesContentWrapper.defaultProps = {
  content: '',
};
ChangesContentWrapper.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
