import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import { Code } from '@decisiv/ui-components';

export const DeveloperCodeView = styled(Code)`
  background: ${toColorString(color.base.blackSheep)};
  color: ${toColorString(color.base.snowWhite)};
  margin: 0;
  padding: 20px;
  letter-spacing: -0.8px;
  overflow: auto;
  width: 100%;
`;
