import api from './api';

const fetchSrmAccounts = async (srmAccountId) => {
  try {
    const response = await api.get(`/srm-accounts/${srmAccountId}`);

    const { data } = response.data;

    return { data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

export default {
  fetchSrmAccounts,
};
