import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import iconix from '@decisiv/iconix/lib/components';
import spacing from '@decisiv/design-tokens/lib/spacing';
import { H3 } from '@decisiv/ui-components';

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const InfiniteLoading = styled(iconix.Refresh)`
  margin-top: ${rem(spacing.base * 2)};
  margin-bottom: ${rem(spacing.base * 2)};
  animation: ${rotate} 2s linear infinite;
`;

const OverlayCenteredContainer = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff88;
  z-index: 1000;
`;

export default function OverlayLoading({ loading, message }) {
  return (
    <>
      {loading ? (
        <OverlayCenteredContainer>
          <InfiniteLoading width={32} height={32} />

          <H3>{message}</H3>
        </OverlayCenteredContainer>
      ) : (
        ''
      )}
    </>
  );
}

OverlayLoading.defaultProps = { message: 'Loading...' };
OverlayLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string,
};
