import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import spacing from '@decisiv/design-tokens/lib/spacing';
import rem from 'polished/lib/helpers/rem';

export const LogInspectorSection = styled(Flex)`
  gap: 0.313rem;
  width: 100%;
  background: ${toColorString(color.base.snowWhite)};
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const LineDivider = styled.div`
  width: 100%;
  height: ${rem(spacing.base * 0.1)};
  background: ${toColorString(color.opacity.charcoal15)};
`;

export const LogListSection = styled(Flex)`
  width: 100%;
  height: calc(100vh - 145px);
  align-self: stretch;
  background: ${toColorString(color.base.snowWhite)};
  overflow: auto;
`;

export const LogSearchBarSection = styled(Flex)`
  width: 100%;
  align-self: stretch;
  background: ${toColorString(color.base.snowWhite)};
  overflow: auto;
`;
