import styled from 'styled-components';
import spacing from '@decisiv/design-tokens/lib/spacing';
import rem from 'polished/lib/helpers/rem';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';

export const HeaderBlock = styled(Flex).attrs({
  alignItems: 'flex-start',
  padding: 2,
})`
  background-color: ${toColorString(color.base.snowWhite)};
`;

export const HalfMoonBg = styled(Flex).attrs({
  flexDirection: 'column',
  flexWrap: 'wrap',
  paddingTop: 2,
  paddingBottom: 2,
})`
  background-color: ${toColorString(color.base.halfMoon)};
`;

export const EnableIntegration = styled(Flex).attrs({
  flexDirection: 'column',
  flexWrap: 'no-wrap',
  padding: 2,
  marginBottom: 2,
})`
  background-color: ${toColorString(color.base.fullMoon)};
`;

export const RemoveIntegration = styled(Flex).attrs({
  flexDirection: 'column',
  flexWrap: 'no-wrap',
  padding: 2,
})`
  background-color: ${toColorString(color.base.snowWhite)};
`;

export const Bold = styled.span`
  font-weight: bold;
  margin-left: ${rem(spacing.base * 0.3)};
  text-transform: capitalize;
`;

export const LocationContainer = styled(Flex)`
  padding: 1.25rem;
  margin-top: 1rem;
  background: ${toColorString(color.base.snowWhite)};
  flex-direction: column;
`;
