import styled from 'styled-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import Flex from '@decisiv/ui-components/lib/components/Flex';

const CardFooter = styled(Flex)`
  background-color: ${toColorString(color.status.information.light)};
  padding: 0.625rem 1.188rem;
  height: 2.5rem;
  margin-top: auto;
  gap: 0.213rem;
  align-items: flex-start;
`;

export { CardFooter };
