import React, { Fragment, useCallback, useEffect, useState } from 'react';
import iconix from '@decisiv/iconix';
import { Filter, Flex, H1, H4, Tab, Tabs, Tag } from '@decisiv/ui-components';
import { t, Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import {
  ActionSearchSection,
  ActionsSection,
  DeveloperSection,
  DeveloperSectionHead,
  DisplayCode,
  FilterTextField,
  LineDivider,
  LineVerticalDivider,
  Stack,
} from './style';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import ActionService from '../../api/actions';
import ActionList from './actionList';
import { titlelizeCommand } from '../../utils/titlelizeCommand';
import Paper from '../../components/Paper';

export default function ActionSearch() {
  const filters = [
    {
      icon: iconix.Tools,
      text: 'Operations',
      color: 'carrotCake',
      tags: ['retrieve_operations'],
    },
    {
      icon: iconix.Share,
      text: 'PlatformApi',
      color: 'berryCrisp',
      tags: ['add_operations_to_case'],
    },
    {
      icon: iconix.Briefcase,
      text: 'Service Management',
      color: 'fizzyLime',
      tags: [
        'add_metadata_to_case',
        'retrieve_service_contract_information',
        'retrieve_manufacturer_build_information',
      ],
    },
    {
      icon: iconix.ServiceProvider,
      text: 'Service Provider Business System',
      color: 'mamboMango',
      tags: [
        'retrieve_customer_credit_status',
        'retrieve_parts',
        'retrieve_deferred_operations',
      ],
    },
    {
      icon: iconix.BaselineChart,
      text: 'Telematics',
      color: 'cottonCandy',
      tags: [
        'retrieve_diagnostics_information',
        'retrieve_diagnostic_history',
        'find_device_location',
      ],
    },
    {
      icon: iconix.Shield,
      text: 'Warranty and Recall',
      color: 'cookieMonster',
      tags: [
        'request_recall_information',
        'retrieve_warranty_information',
        'retrieve_campaign_information',
      ],
    },
  ];

  const AMOUNT_OF_FILTERS = filters.length;
  const [actionsFilter, setActionsFilter] = useState([]);
  const [filtersTagList, setFiltersTagList] = useState(filters);
  const [tag, setTag] = useState('');
  const [selectedActionName, setSelectedActionName] = useState('');
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [secondaryLoading, setSecondaryLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [currentActionSchema, setCurrentActionSchema] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchSResponse = async () => {
      const response = await ActionService.fetchActions();

      if (response) {
        const actionsWithCategories = response.data.flatMap((item) => {
          const category = item?.attributes?.category || []; // Check if 'category' exists
          const allActions = item?.attributes?.actions || []; // Check if 'actions' exists, default to empty array

          return allActions.map((action) => ({
            action,
            category: category || 'Unknown ', // Default to "Unknown" if category is missing
          }));
        });

        setActions(actionsWithCategories);
        setActionsFilter(actionsWithCategories);
        setLoading(false);
      } else setActions([]);
      setLoading(false);
    };

    fetchSResponse();
  }, []);

  const filterSearchTags = useCallback(
    (arrayFilter) => {
      const filterAction = actions.filter((element) => {
        const { action } = element;

        return arrayFilter.find((item) => item.tags.includes(action));
      });
      return filterAction;
    },
    [actions],
  );

  const filterSearch = useCallback(
    (text) => {
      const filterAction = actionsFilter.filter((element) => {
        const { action } = element;
        return action.toLowerCase().includes(text);
      });
      if (filterAction.length !== 0) {
        setActionsFilter(filterAction);
      }
      if (isEmpty(text)) setActionsFilter(filterSearchTags(filtersTagList));
    },
    [actionsFilter, filterSearchTags, filtersTagList],
  );

  const handleChange = (e) => {
    const value = e.target.value.replace(/\s/g, '').toLowerCase();
    filterSearch(value);
  };

  const filtersUpdate = (array) => {
    setFiltersTagList((prevState) => {
      setActionsFilter(filterSearchTags([...prevState, array]));
      return setFiltersTagList([...prevState, array]);
    });
  };

  // eslint-disable-next-line no-unused-vars
  function childResult(actionItem) {
    const currentAction = actions.find((action) => {
      return action.action.includes(actionItem);
    });
    const currentTag = filters.find((filter) =>
      filter.tags.includes(actionItem),
    );

    setTag(currentTag);
    setSelectedActionName(actionItem);
    setSelectedAction(currentAction);

    const fetchActionDetails = async () => {
      setSecondaryLoading(true);
      const inputResponse = await ActionService.fetchActionDetails(
        actionItem,
        'input',
      );

      const outputResponse = await ActionService.fetchActionDetails(
        actionItem,
        'output',
      );

      if (inputResponse && outputResponse) {
        setCurrentActionSchema({
          input: inputResponse.data.attributes.schema,
          output: outputResponse.data.attributes.schema,
        });
        setSecondaryLoading(false);
      } else {
        setCurrentActionSchema('{}');
        setSecondaryLoading(false);
      }
    };

    fetchActionDetails();
  }

  const TagFilter = (item) => {
    const [active, setActive] = useState(false);

    const handleChangeFilter = (e) => {
      if (isEmpty(e.target.textContent)) return false;

      setActive(!active);
      setActionsFilter(filterSearchTags(filters));
      const filterWithoutDuplicates = [...new Set(filtersTagList)];
      if (!active) {
        /* select all*/
        if (filterWithoutDuplicates.length === AMOUNT_OF_FILTERS - 1)
          return setFiltersTagList(filters);

        const filter = filters.filter(
          (element) => element.text === e.target.textContent,
        );
        /* select 1*/
        if (filterWithoutDuplicates.length === AMOUNT_OF_FILTERS) {
          setActionsFilter(filterSearchTags(filter));
          return setFiltersTagList(filter);
        }
        /* select more of one*/
        return filtersUpdate(filter[0]);
      }
      /* unselect all*/
      if (filterWithoutDuplicates.length < 2) return setFiltersTagList(filters);
      /* unselect some*/
      const filter = filterWithoutDuplicates.filter(
        (element) => element.text !== e.target.textContent,
      );
      setActionsFilter(filterSearchTags(filter));
      setFiltersTagList(filter);
      return true;
    };
    return (
      <Flex margin={0.5} key={item.color}>
        <Filter
          text={item.text}
          badgeIcon={item.icon}
          badgeColor={item.color}
          active={active}
          onClick={handleChangeFilter}
          variant="simple"
        />
      </Flex>
    );
  };

  return (
    <>
      <ActionsSection
        flexDirection="column"
        justifyContent="center"
        padding={3}
        flex="none"
        flexGrow="0"
      >
        <Flex flexDirection="row">
          <Flex flex={0} alignItems="center" justifyContent="center">
            <FilterTextField
              icon={iconix.Search}
              label="searchInput"
              hideLabel
              onChange={handleChange}
            />
          </Flex>
          <Flex
            flex={3}
            alignItems="center"
            justifyContent="flex-start"
            marginLeft={1}
          >
            <p>
              <span title="FilterTags">
                <Trans>Filter by:</Trans>
              </span>
            </p>

            <Stack vertical width="100%">
              <Flex flexWrap="wrap">
                {filters.map((item) => TagFilter(item))}
              </Flex>
            </Stack>
          </Flex>
        </Flex>
      </ActionsSection>
      <LineDivider />
      <Flex flexDirection="row">
        <ActionSearchSection
          flexDirection="column"
          justifyContent="flex-start"
          title="actionListSection"
        >
          <WrapperWithLoading loading={loading}>
            <ActionList
              actions={actionsFilter}
              filters={filters}
              childResult={childResult}
            />
          </WrapperWithLoading>
        </ActionSearchSection>
        <LineVerticalDivider />
        <Flex flexDirection="column" flex={3}>
          <DeveloperSectionHead justifyContent="space-between">
            <Flex flexDirection="column">
              <H1>{selectedAction && titlelizeCommand(selectedActionName)}</H1>
            </Flex>
            <Flex marginTop={4}>
              {tag && <Tag icon={tag.icon} text={tag.text} color={tag.color} />}
            </Flex>
          </DeveloperSectionHead>
          <Paper css="height: 100%;" padding={2}>
            <Tabs
              size="large"
              renderHiddenTabsContent={false}
              defaultSelectedId="large-tab-1"
            >
              <Tab id="large-tab-1" title={t`Action Details`}>
                <DeveloperSection
                  justifyContent="flex-start"
                  flexDirection="column"
                  flex={3}
                >
                  <WrapperWithLoading loading={secondaryLoading}>
                    <H4 marginY={1}>
                      <Trans>Input</Trans>
                    </H4>

                    <DisplayCode>
                      {JSON.stringify(currentActionSchema.input, undefined, 2)}
                    </DisplayCode>

                    <H4 marginY={1}>
                      <Trans>Output</Trans>
                    </H4>

                    <DisplayCode>
                      {JSON.stringify(currentActionSchema.output, undefined, 2)}
                    </DisplayCode>
                  </WrapperWithLoading>
                </DeveloperSection>
              </Tab>
            </Tabs>
          </Paper>
        </Flex>
      </Flex>
    </>
  );
}
