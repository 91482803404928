import styled from 'styled-components';
import color from '@decisiv/design-tokens/lib/color';
import toColorString from 'polished/lib/color/toColorString';
import rem from 'polished/lib/helpers/rem';
import spacing from '@decisiv/design-tokens/lib/spacing';
import { Grid } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';

export const Divider = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${toColorString(color.opacity.charcoal15)};
`;

export const FormGroup = styled.div`
  margin-top: ${rem(spacing.base * 0.6)};
`;

export const SectionRow = styled(Grid.Row).attrs({
  paddingX: 1,
  paddingY: 1.5,
})`
  background-color: ${toColorString(color.base.snowWhite)};
`;

export const FormDivider = styled.div`
  width: 100%;
  height: ${rem(spacing.base * 0.1)};
  margin-top: ${rem(spacing.base * 3)};
  background: ${toColorString(color.opacity.charcoal15)};
`;

export const IntegrationForm = styled(Flex).attrs({
  padding: 1,
})`
  background-color: ${toColorString(color.base.snowWhite)};
`;

export const Label = styled.label`
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: #737482;
  font-size: 0.75rem;
  line-height: 140%;
  font-size: 0.75rem;
  display: block;
  padding-right: 10px;
  text-align: left;
  width: 100%;
  cursor: not-allowed;
`;

export const GreyishFlex = styled(Flex).attrs({
  marginTop: 1.7,
  padding: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  background: ${toColorString(color.base.halfMoon)};
`;
