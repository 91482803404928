import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Flex, H4 } from '@decisiv/ui-components';
import Newspaper from '@decisiv/iconix/lib/components/Newspaper';

import { normalizeCommandName } from '../../utils/titlelizeCommand';

const CommandIconAndTitle = ({ title, onClick }) => {
  return (
    <Flex justifyContent="left">
      <Button
        icon={Newspaper}
        variant="ghost"
        onClick={() => onClick(title)}
        style={{
          cursor: 'pointer',
          marginRight: 5,
        }}
        aria-label=""
      />
      <H4 style={{ marginTop: 6 }}>{normalizeCommandName(title)}</H4>
    </Flex>
  );
};

CommandIconAndTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CommandIconAndTitle;
