import React from 'react';
import PropTypes from 'prop-types';

import { Trans, t } from '@lingui/macro';

import iconx from '@decisiv/iconix';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import { P } from '@decisiv/ui-components';

export default function RemoveCommandModal({
  setVisibleModal,
  visibleModal,
  commands,
  actions,
}) {
  return (
    <Modal
      actions={actions}
      icon={iconx.MinusCircle}
      color="danger"
      onClose={() => setVisibleModal(false)}
      title={t`Remove ${commands.commands[0]}?`}
      visible={visibleModal}
      style={{ flexDirection: 'column' }}
    >
      <div>
        <P shade={1} marginTop={1}>
          <Trans>
            After clicking REMOVE COMMANDS, the following will be deleted:
          </Trans>
        </P>
        <ul
          style={{
            color: '#737482',
            marginTop: '1rem',
            paddingLeft: '1.2rem',
          }}
        >
          {commands.commands.map((command, index) => {
            if (!index || !command) return null;
            const key = `${command}-${index}`;

            return (
              <li key={key}>{t`Command ${
                commands.startingIndex + index + 1
              }: ${command}`}</li>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
}

RemoveCommandModal.propTypes = {
  setVisibleModal: PropTypes.func.isRequired,
  visibleModal: PropTypes.bool.isRequired,
  commands: PropTypes.shape({
    commands: PropTypes.arrayOf(PropTypes.string),
    startingIndex: PropTypes.number,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
