import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Combobox, Loading, P } from '@decisiv/ui-components';
import ServiceNetworkService from '../../../api/service_network';
import filterOptions from '../../../utils/filterOptions';

export default function ServiceNetworkFilter({ handleForm, currentValue }) {
  const [serviceNetworkItems, setServiceNetworkItems] = useState([]);
  const [serviceNetworkValue, setServiceNetworkValue] = useState(
    currentValue.map((serviceNetwork) => ({
      id: serviceNetwork,
      label: serviceNetwork,
      value: serviceNetwork,
    })),
  );
  const [inputValue, setInputValue] = useState('');

  const handleCurrentValue = () => {
    const updateServiceNetwork = currentValue.map((serviceNetwork) => ({
      id: serviceNetwork,
      label: serviceNetwork,
      value: serviceNetwork,
    }));
    setServiceNetworkValue(updateServiceNetwork);
  };

  useEffect(() => {
    async function getData() {
      setServiceNetworkItems([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      const responseServiceNetwork =
        await ServiceNetworkService.fetchServiceNetwork();
      /* istanbul ignore else */
      if (responseServiceNetwork.data) {
        const parsedServiceNetworks = responseServiceNetwork.data.map((sn) => ({
          id: sn.attributes.name,
          label: sn.attributes.name,
          value: sn.attributes.name,
        }));

        setServiceNetworkItems(parsedServiceNetworks);
      }
    }

    getData();
  }, []);

  return (
    <>
      <P marginBottom={1}>
        <Trans>Service Network</Trans>
      </P>
      <Combobox
        name="ServiceNetworkInputFilter"
        hideLabel
        multiple
        label={t`Service Network`}
        options={filterOptions(serviceNetworkItems, inputValue)}
        onChangeInputValue={(e) => setInputValue(e ? e.target.value : '')}
        value={serviceNetworkValue}
        zIndex={777}
        onChangeValue={(selectedId, options) => {
          handleForm({
            selectedId,
            options,
            field: 'serviceNetwork',
          });
          handleCurrentValue();
        }}
      />
    </>
  );
}

ServiceNetworkFilter.propTypes = {
  handleForm: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
};
