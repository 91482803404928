import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Alert, Flex, H4, Modal, P } from '@decisiv/ui-components';
import Spaces from '@decisiv/iconix/lib/components/Spaces';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import startCase from 'lodash/startCase';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { useNotifications } from '@decisiv/ui-components/lib/components/Notifications';
import noop from 'lodash/noop';
import IntegrationsService from '../../api/integrations';

export default function CreateModal({
  handleToggleCreateModal,
  showCreateModal,
  form,
  triggerTypeHttpRequest,
  triggerTypeApplicationEvent,
}) {
  const { notify } = useNotifications();
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const CustomList = styled.ul`
    display: block;
    margin-block: 0.1em;
    margin-inline: -2px;
    padding-inline-start: 25px;
    color: ${toColorString(color.base.alaskanHusky)};
  `;

  const notificationSuccessProps = {
    intent: 'success',
    title: t`Integration Created`,
    duration: 3500,
    onClose: noop,
  };

  function parseMetadata(metadata) {
    const parsedData = metadata.reduce((map, object) => {
      // eslint-disable-next-line no-param-reassign
      map[object.key] = object.value;
      return map;
    }, {});

    return Object.keys(parsedData)[0] === '' ? [''] : parsedData;
  }

  const postIntegration = async () => {
    setShowAlert(false);
    setIsSubmitting(true);

    const parsedMetadata = parseMetadata(form.metadata);

    const integrationAttributes = {
      name: form.name,
      description: form.description,
      audience: form.audience,
      service_network: form.service_network,
      events: form.events,
      trigger_type: form.trigger_type,
      commands_flow: form.commands_flow,
      category: form.category,
      action: form.action,
      metadata: parsedMetadata,
      cache_enabled: form.cache_enabled,
      cache_ttl: form.cache_ttl,
    };

    // We only need the events if the integration have the ApplicationEvent trigger
    if (!triggerTypeApplicationEvent) {
      delete integrationAttributes.events;
    }

    const response = await IntegrationsService.createIntegration(
      integrationAttributes,
    );

    /* istanbul ignore next */
    if (response.data) {
      navigate(`/`);
      notify(notificationSuccessProps, t`${form.name} has been created`);
    } else {
      setShowAlert(true);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        actions={[
          {
            text: t`Create Integration`,
            onClick: postIntegration,
            intent: 'success',
            loading: isSubmitting,
          },
          {
            text: t`Cancel`,
            onClick: () => {
              handleToggleCreateModal();
              setShowAlert(false);
            },
          },
        ]}
        icon={Spaces}
        color="information"
        onClose={() => {
          handleToggleCreateModal();
          setShowAlert(false);
        }}
        title={t`Create ${form.label} Integration`}
        visible={showCreateModal}
      >
        <Flex flexDirection="column">
          <Flex marginBottom={2}>
            <P
              weight="medium"
              style={{
                color: toColorString(color.base.alaskanHusky),
              }}
            >
              <Trans>
                Clicking CREATE INTEGRATION will publish this integration and
                make it available at the Integrations Framework.
              </Trans>
            </P>
          </Flex>

          {showAlert ? (
            <Flex marginBottom={2}>
              <Trans>
                <Alert
                  title=""
                  description={t`Integration creation failed. Please check your inputs and try again later. If the issue persists, contact support for assistance.`}
                  intent="danger"
                />
              </Trans>
            </Flex>
          ) : null}

          <Flex
            style={{
              backgroundColor: toColorString(color.base.fullMoon),
            }}
            flexDirection="column"
            padding={1}
          >
            <Flex flexDirection="column">
              <H4
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                {form.name}
              </H4>
              <P
                weight="medium"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                {form.description}
              </P>
            </Flex>

            <Flex flexDirection="column" marginTop={1}>
              <P
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                <Trans>Category</Trans>
              </P>

              <P
                weight="medium"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                {form.category}
              </P>
            </Flex>

            <Flex flexDirection="column" marginTop={1}>
              <P
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                <Trans>Audience</Trans>
              </P>

              <CustomList>
                <li
                  key="audience_items"
                  style={{
                    color: toColorString(color.base.alaskanHusky),
                  }}
                >
                  {form.audience?.map(startCase)?.join(', ')}
                </li>
              </CustomList>
            </Flex>

            <Flex flexDirection="column" marginTop={1}>
              <P
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                {startCase('service_network')}
              </P>

              <CustomList>
                {form.service_network?.map((sn) => (
                  <li
                    key="service_network_items"
                    style={{
                      color: toColorString(color.base.alaskanHusky),
                    }}
                  >
                    {sn}
                  </li>
                ))}
              </CustomList>
            </Flex>

            <Flex flexDirection="column" marginTop={1}>
              <P
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                <Trans>Trigger</Trans>
              </P>

              <CustomList>
                <li
                  key="trigger_type_items"
                  style={{
                    color: toColorString(color.base.alaskanHusky),
                  }}
                >
                  {form.trigger_type?.map(startCase)?.join(', ')}
                </li>
                {triggerTypeHttpRequest && (
                  <P weight="medium">
                    <u>{form.http_api_request_url}</u>
                  </P>
                )}
                {triggerTypeApplicationEvent &&
                  form.events?.map((applicationEvent) => (
                    <li
                      key="application_events_items"
                      style={{
                        color: toColorString(color.base.alaskanHusky),
                      }}
                    >
                      {applicationEvent}
                    </li>
                  ))}
              </CustomList>
            </Flex>

            <Flex flexDirection="column" marginTop={1}>
              <P
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                <Trans>Commands</Trans>
              </P>

              <CustomList>
                {form.commands_flow?.map((command) => (
                  <li
                    key="commands_items"
                    style={{
                      color: toColorString(color.base.alaskanHusky),
                    }}
                  >
                    {command}
                  </li>
                ))}
              </CustomList>
            </Flex>

            <Flex flexDirection="column" marginTop={1}>
              <P
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                <Trans>Cache Enabled</Trans>
              </P>

              <P
                weight="medium"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                {form.cache_enabled ? 'Yes' : 'No'}
              </P>
            </Flex>

            <Flex flexDirection="column" marginTop={1}>
              <P
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                <Trans>Cache TTL</Trans>
              </P>

              <P
                weight="medium"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                {form.cache_ttl}
              </P>
            </Flex>

            <Flex flexDirection="column" marginTop={1}>
              <P
                weight="semibold"
                style={{
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                <Trans>Metadata</Trans>
              </P>

              <CustomList>
                {form.metadata?.map((metadata) => (
                  <li
                    key="metadata_items"
                    style={{
                      color: toColorString(color.base.alaskanHusky),
                    }}
                  >
                    {JSON.stringify(metadata)}
                  </li>
                ))}
              </CustomList>

              {triggerTypeHttpRequest && (
                <>
                  <P
                    weight="semibold"
                    style={{
                      color: toColorString(color.base.alaskanHusky),
                    }}
                  >
                    <Trans>Action</Trans>
                  </P>

                  <P
                    weight="medium"
                    style={{
                      color: toColorString(color.base.alaskanHusky),
                    }}
                  >
                    {form.action}
                  </P>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}

CreateModal.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleToggleCreateModal: PropTypes.func.isRequired,
  showCreateModal: PropTypes.bool.isRequired,
  triggerTypeHttpRequest: PropTypes.bool.isRequired,
  triggerTypeApplicationEvent: PropTypes.bool.isRequired,
};
