import React, { useCallback, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import iconix from '@decisiv/iconix/lib/components';
import { Trans } from '@lingui/macro';
import { Tag, P, Flex, Popover, Button, Tooltip } from '@decisiv/ui-components';

import { IntegrationContainerWrapper } from '../../pages/IntegrationsPage/style';
import { CardFooter } from './CardFooter.styled';
import { ArchiveCardFooter } from './ArchiveCardFooter.styled';
import { CardTitle } from './CardTitle.styled';

export default function IntegrationContainer({
  name,
  status,
  published,
  description,
  locationUrl,
  audience,
  serviceNetwork,
  triggerType,
  archivedAt,
  archivedBy,
  archiveMode,
}) {
  const [popover, setPopover] = useState({});
  const actionRef = useRef();
  const popoverRef = useCallback(setPopover, [popover]);

  const MAX_SERVICE_NETWORK_SIZE = 70;
  const MAX_DESCRIPTION_SIZE = 85;

  const formatServiceNetwork = (list) => {
    let serviceNetworkString = list?.join(', ');

    /* istanbul ignore else */
    if (serviceNetworkString?.length > MAX_SERVICE_NETWORK_SIZE) {
      serviceNetworkString = serviceNetworkString.slice(
        0,
        MAX_SERVICE_NETWORK_SIZE,
      );
    }

    return serviceNetworkString;
  };

  return (
    <IntegrationContainerWrapper
      flexDirection="column"
      size="MD"
      name="integration-container"
    >
      <Flex padding={2} flexDirection="column" size="MD">
        <CardTitle to={locationUrl} text={name} />

        <Flex flexDirection="row">
          <P color="alaskanHusky" weight="regular">
            {description.substring(0, MAX_DESCRIPTION_SIZE)}
            {description.length >= MAX_DESCRIPTION_SIZE ? '...' : ''}
          </P>
        </Flex>

        <Flex flexDirection="row" marginTop={1}>
          <P>
            <Trans>Audience</Trans>
          </P>
        </Flex>

        <Flex flexDirection="row">
          <P color="alaskanHusky" weight="regular">
            {audience?.length > 0 ? audience?.sort().join(', ') : '___'}
          </P>
        </Flex>

        <Flex flexDirection="row" marginTop={1}>
          <P>
            <Trans>Service Network</Trans>
          </P>
        </Flex>

        <Flex flexDirection="row">
          <P color="alaskanHusky" weight="regular">
            {serviceNetwork?.length
              ? formatServiceNetwork(serviceNetwork)
              : '___'}
            {formatServiceNetwork(serviceNetwork)?.length >=
            MAX_SERVICE_NETWORK_SIZE
              ? '...'
              : ''}
          </P>

          {formatServiceNetwork(serviceNetwork)?.length >= 70 ? (
            <Flex alignItems="flex-end">
              <Tag
                action={popover.toggle}
                actionAttributes={{ 'aria-label': 'Expand' }}
                actionRef={actionRef}
                actionIcon={iconix.ChevronDown}
                color="information"
                text={`+${serviceNetwork?.length.toString()}`}
                variant="outline"
                style={{ float: 'right' }}
              />
              <Popover ref={popoverRef} target={actionRef} manageEvents={false}>
                <Flex padding={1} style={{ width: '18.75rem' }}>
                  <P color="alaskanHusky">
                    {serviceNetwork?.sort().join(', ')}
                  </P>
                </Flex>
              </Popover>
            </Flex>
          ) : (
            ''
          )}
        </Flex>

        <Flex flexDirection="row" marginTop={1}>
          <P>
            <Trans>Trigger</Trans>
          </P>
        </Flex>

        <Flex flexDirection="column" marginTop={1}>
          <P color="alaskanHusky" weight="regular">
            {triggerType?.length > 0 ? triggerType?.sort().join(', ') : '___'}
          </P>
        </Flex>
      </Flex>
      {archiveMode ? (
        <ArchiveCardFooter>
          <Flex flexDirection="row">
            <Flex flexDirection="column" justifyContent="center">
              <Flex
                flexDirection="column"
                paddingX={2}
                paddingY={1}
                flexShrink={1}
              >
                <P color="alaskanHusky" weight="medium">
                  <Trans>Archived by {archivedBy}</Trans>
                </P>
                <P color="alaskanHusky" weight="regular">
                  {archivedAt}
                </P>
              </Flex>
            </Flex>
          </Flex>
          <Tooltip
            target={
              <Flex marginRight={1} paddingY={0.5}>
                <Button
                  type="button"
                  id="restore"
                  text="restore"
                  kind="primary"
                  variant="ghost"
                  disabled
                />
              </Flex>
            }
            placement="right"
          >
            <Trans>Integration no longer compatible with the system</Trans>
          </Tooltip>
        </ArchiveCardFooter>
      ) : (
        <CardFooter>
          <Tag
            text={published}
            icon={published === 'Published' ? iconix.EyeAlt : iconix.EyeSlash}
            color={published === 'Published' ? 'success' : 'secondary'}
            variant="outline"
          />
          <Tag
            text={status}
            icon={iconix.CircleF}
            color={status === 'Active' ? 'success' : 'secondary'}
            variant="outline"
          />
        </CardFooter>
      )}
    </IntegrationContainerWrapper>
  );
}

IntegrationContainer.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  published: PropTypes.string.isRequired,
  locationUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  audience: PropTypes.arrayOf(PropTypes.string).isRequired,
  serviceNetwork: PropTypes.arrayOf(PropTypes.string).isRequired,
  triggerType: PropTypes.arrayOf(PropTypes.string).isRequired,
  archivedAt: PropTypes.arrayOf(PropTypes.string),
  archivedBy: PropTypes.arrayOf(PropTypes.string),
  archiveMode: PropTypes.bool,
};

IntegrationContainer.defaultProps = {
  archiveMode: false,
  archivedAt: '21 Oct 2022, 7:00 AM',
  archivedBy: 'Lucas Rodríguez',
};
