import React, { useState } from 'react';

import { PropTypes } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CommandSelector from './CommandSelector';
import CommandAddButton from './CommandAddButton';
import RemoveCommandModal from './RemoveCommandModal';

const CommandFlow = ({
  commands,
  selectedCommands,
  updateSelectedCommands,
  editMode,
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [removedCommands, setRemovedCommands] = useState({
    commands: [],
    startingIndex: 0,
  });
  const commandOptions = commands.map((command) => ({
    id: command.id,
    label: command.id,
    value: command.id,
    connections: command.connections,
  }));

  const getCommand = (id) => {
    return commandOptions
      .filter(
        (commandOption) =>
          commandOption.id === id || commandOption.value === id,
      )
      .pop();
  };
  const redefineSelectedCommands = (position, actualSelection) => {
    return actualSelection.map((item, index) => (index < position ? item : ''));
  };
  const removeCommands = (removingCommands) => {
    const remainingCommands = selectedCommands.filter(
      (item) => removingCommands.commands.indexOf(item) < 0,
    );

    setVisibleModal(false);
    updateSelectedCommands(remainingCommands);
  };
  const removeCommand = (index) => {
    const newCommands = [...selectedCommands];
    const currentCommand = newCommands[index];
    if (currentCommand && index + 1 < selectedCommands.length) {
      const removed = {
        startingIndex: index,
        commands: [...newCommands.splice(index, Infinity)],
      };
      setRemovedCommands(removed);
      setVisibleModal(true);
      return;
    }

    newCommands.splice(index, Infinity);

    setRemovedCommands({ commands: [], startingIndex: 0 });
    updateSelectedCommands(newCommands);
  };
  const emptySelection = (position) => {
    const blankedCommands = redefineSelectedCommands(
      position,
      selectedCommands,
    );

    updateSelectedCommands(blankedCommands);
  };
  const handleAddCommand = () => {
    const incremented = [...selectedCommands, ''];

    updateSelectedCommands(incremented);
  };
  const selectCommand = (selectedId, index) => {
    const selectedCommand = getCommand(selectedId);
    if (!selectedCommand) return emptySelection(index);

    const currentCommands = redefineSelectedCommands(index, selectedCommands);
    currentCommands[index] = selectedCommand.value;

    return updateSelectedCommands(currentCommands);
  };
  const disabledAddButton = () => {
    const lastCommand = selectedCommands[selectedCommands.length - 1];
    if (isEmpty(lastCommand)) return true;

    const lastCommandConnections = getCommand(lastCommand).connections;
    if (isEmpty(lastCommandConnections)) return true;

    return false;
  };
  const showButtonTooltip = () => {
    const lastCommand = selectedCommands[selectedCommands.length - 1];
    if (isEmpty(lastCommand)) return false;

    return true;
  };

  return (
    <div style={{ marginTop: '0.625rem' }}>
      {selectedCommands.map((selectedCommand, index, allCommands) => {
        const emptySelector = {
          id: `unselected-${index}`,
          connections: commandOptions.map((command) => command.value),
        };

        const currentCommand =
          commandOptions
            .filter((command) => command.value === selectedCommand)
            .pop() || emptySelector;
        const key = `command-flow-${index}-item`;

        const previousCommand = allCommands[index - 1];
        let allowedCommands = [];

        if (previousCommand)
          allowedCommands = getCommand(previousCommand).connections.map(
            (allowedConnection) => getCommand(allowedConnection),
          );
        if (!index) allowedCommands = commandOptions;

        return (
          <CommandSelector
            index={index}
            options={allowedCommands}
            key={key}
            editMode={editMode}
            selectedCommand={currentCommand.id}
            updateSelection={selectCommand}
            removeCommandHandler={removeCommand}
          />
        );
      })}
      <CommandAddButton
        disabled={disabledAddButton() || editMode}
        emptyCommand={showButtonTooltip()}
        onClick={handleAddCommand}
      />
      <RemoveCommandModal
        actions={[
          {
            text: 'Remove commands',
            intent: 'danger',
            onClick: () => removeCommands(removedCommands),
          },
          { text: 'Cancel', onClick: () => setVisibleModal(!visibleModal) },
        ]}
        commands={removedCommands}
        setVisibleModal={setVisibleModal}
        visibleModal={visibleModal}
      />
    </div>
  );
};

CommandFlow.propTypes = {
  commands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      connections: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  selectedCommands: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateSelectedCommands: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default CommandFlow;
