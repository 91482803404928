import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import { Breadcrumbs, Button, Grid, Loading } from '@decisiv/ui-components';
import PageTitle from '../../components/PageTitle/PageTitle';
import PaddedForm from '../../components/PaddedForm';
import AsteriskSubtitle from '../../components/AsteriskSubtitle';
import CommandsService from '../../api/commands';
import ServiceNetworkService from '../../api/service_network';
import CategoriesAndActionsService from '../../api/categories_and_actions';
import ApplicationEventsService from '../../api/application_events';
import CreateModal from './create_modal';
import BasicInformation from './basic_information';
import Scope from './scope';
import Trigger from './trigger';
import Commands from './commands';
import Metadata from './metadata';
import CacheSettings from './cache_settings';

export default function IntegrationCreatePage() {
  const formDefault = {
    name: null,
    description: null,
    audience: [],
    service_network: [],
    events: [],
    trigger_type: [],
    http_api_request_url: null,
    commands_flow: [''],
    action: null,
    category: null,
    cache_enabled: false,
    cache_ttl: 0,
    metadata: [],
  };

  const [integration, setIntegration] = useState({});
  const TRIGGER_TYPE_APPLICATION_EVENT = 'application_event';
  const TRIGGER_TYPE_HTTP_API_REQUEST = 'http_api_request';

  const [commands, setCommands] = useState([]);
  const [serviceNetworkItems, setServiceNetworkItems] = useState([]);
  const [applicationEventsItems, setApplicationEventsItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [createBtnDisabledStatus, setCreateBtnDisabledStatus] = useState(true);
  const [triggerTypeApplicationEvent, setTriggerTypeApplicationEvent] =
    useState(false);
  const [triggerTypeHttpRequest, setTriggerTypeHttpRequest] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [form, setForm] = useState(formDefault);
  const [commandsFlow, setCommandsFlow] = useState(['']);
  const [metadataInputs, setMetadataInputs] = useState([]);
  const [enabledAddMetadataInput, setEnabledAddMetadataInput] = useState(true);
  const [allCategoriesAndActions, setAllCategoriesAndActions] = useState([]);
  const [categoryActions, setCategoryActions] = useState([]);

  function parseCommands({ data }) {
    const parsedData = data.map((command) => {
      return {
        id: command.attributes.name,
        connections: command.attributes.connections,
      };
    });

    return parsedData;
  }

  function parseCategories({ data }) {
    const parsedData = data.map((categoryAndActions) => {
      return {
        id: categoryAndActions.attributes.category,
        label: categoryAndActions.attributes.category,
        value: categoryAndActions.attributes.category,
        name: categoryAndActions.attributes.category,
      };
    });

    return parsedData;
  }

  function parseActions(actions) {
    const parsedData = actions.map((action) => {
      return {
        id: action,
        label: action,
        value: action,
        name: action,
      };
    });

    return parsedData;
  }

  useEffect(() => {
    async function getData() {
      setCategories([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      setServiceNetworkItems([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      setApplicationEventsItems([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      setCommands([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      setCategoryActions([
        {
          id: 'loading',
          label: <Loading />,
          value: 'loading',
          disabled: true,
        },
      ]);

      const responseCategoriesAndActions =
        await CategoriesAndActionsService.fetchCategoriesAndActions();
      /* istanbul ignore else */
      if (responseCategoriesAndActions.data) {
        const parsedCategories = parseCategories(responseCategoriesAndActions);

        setAllCategoriesAndActions(responseCategoriesAndActions.data);
        setCategories(parsedCategories);
      }

      const responseCommands = await CommandsService.fetchCommands();
      /* istanbul ignore else */
      if (responseCommands.data) {
        const parsedCommands = parseCommands(responseCommands);
        setCommands(parsedCommands);
      }

      const responseServiceNetwork =
        await ServiceNetworkService.fetchServiceNetwork();
      /* istanbul ignore else */
      if (responseServiceNetwork.data) {
        const parsedServiceNetworks = responseServiceNetwork.data.map((sn) => ({
          id: sn.attributes.name,
          label: sn.attributes.name,
          value: sn.attributes.name,
        }));
        setServiceNetworkItems(parsedServiceNetworks);
      }

      const responseApplicationEvents =
        await ApplicationEventsService.fetchApplicationEvents();
      /* istanbul ignore else */
      if (responseApplicationEvents.data) {
        const parsedApplicationEvents = responseApplicationEvents.data.map(
          (sn) => ({
            id: sn.attributes.name,
            label: sn.attributes.name,
            value: sn.attributes.name,
          }),
        );
        setApplicationEventsItems(parsedApplicationEvents);
      }
    }

    getData();
  }, []);

  const handleToggleCreateModal = () => setShowCreateModal(!showCreateModal);

  const handleForm = ({ event, selectedId, options, field }) => {
    const index = isEmpty(field) ? event.target.name : field;
    const newForm = form;
    let actions = null;
    switch (index) {
      // managing array value fields
      case 'audience':
      case 'trigger_type':
        if (event.target.checked) {
          newForm[index].push(event.target.value);
        } else {
          const itemIndex = newForm[index].indexOf(event.target.value);
          if (itemIndex !== -1) {
            newForm[index].splice(itemIndex, 1);
          }
        }
        break;
      case 'service_network':
      case 'events':
        // managing combobox multiple values fields
        if (
          !newForm[index].includes(selectedId) &&
          !isEmpty(selectedId) &&
          !options.removed
        ) {
          newForm[index].push(selectedId);
        }

        /* istanbul ignore next */
        if (newForm[index].includes(selectedId) && options.removed) {
          const indexToRemove = newForm[index].indexOf(selectedId);
          if (indexToRemove !== -1) {
            newForm[index].splice(indexToRemove, 1);
          }
        }

        if (isEmpty(integration)) {
          integration.attributes = {};
          integration.attributes.service_network = [];
          integration.attributes.events = [];
        }
        integration.attributes[index] = newForm[index];
        setIntegration(integration);

        break;
      case 'commands_flow':
        newForm[index] = event;
        setCommandsFlow(event);
        break;
      case 'action':
        newForm[index] = selectedId;
        break;
      case 'cache_enabled':
        newForm[index] = event.target.value === 'true';

        if (event.target.value === 'false') {
          newForm.cache_ttl = 0;
        }
        break;
      case 'cache_ttl':
        // eslint-disable-next-line no-case-declarations
        const value = event.target.value.replace(/\D/g, '');
        newForm[index] = value;
        break;
      case 'metadata': {
        const myIndex = parseInt(event.target.name.slice(-1), 10);
        const fieldName = event.target.name.slice(0, -2);

        newForm[index][myIndex][fieldName] = event.target.value;
        setForm(newForm);
        setMetadataInputs([...newForm.metadata]);

        setEnabledAddMetadataInput(
          newForm[index].every((element) => element.key !== ''),
        );

        break;
      }
      case 'category':
        /* istanbul ignore else */
        if (!isEmpty(selectedId)) {
          actions = allCategoriesAndActions.filter(
            (item) => item.attributes.category === selectedId,
          )[0].attributes.actions;

          setCategoryActions(parseActions(actions));
        } else {
          setCategoryActions([]);
        }

        newForm[index] = selectedId;
        break;
      default:
        // managing text value fields
        /* istanbul ignore next */
        newForm[index] = event.target.value.includes('{')
          ? event.target.value.replace(/\n/g, '')
          : event.target.value;
    }

    console.log(newForm.cache_enabled);
    console.log(newForm.cache_ttl);
    setForm(newForm);

    /* istanbul ignore next */
    const btnStatus =
      isEmpty(newForm.category) ||
      isEmpty(newForm.name) ||
      isEmpty(newForm.audience) ||
      isEmpty(newForm.trigger_type) ||
      isEmpty(newForm.description.replace(/\s/g, '')) ||
      newForm.commands_flow.includes(undefined) ||
      newForm.commands_flow.includes('') ||
      (newForm.trigger_type.includes(TRIGGER_TYPE_APPLICATION_EVENT) &&
        isEmpty(newForm.events)) ||
      (newForm.trigger_type.includes(TRIGGER_TYPE_HTTP_API_REQUEST) &&
        isEmpty(newForm.action));

    setCreateBtnDisabledStatus(btnStatus);
    setTriggerTypeApplicationEvent(
      newForm.trigger_type.includes(TRIGGER_TYPE_APPLICATION_EVENT),
    );
    setTriggerTypeHttpRequest(
      newForm.trigger_type.includes(TRIGGER_TYPE_HTTP_API_REQUEST),
    );
  };

  return (
    <>
      <CreateModal
        handleToggleCreateModal={handleToggleCreateModal}
        showCreateModal={showCreateModal}
        form={form}
        triggerTypeHttpRequest={triggerTypeHttpRequest}
        triggerTypeApplicationEvent={triggerTypeApplicationEvent}
      />

      <Breadcrumbs
        path={[{ to: '/integrations', text: 'Integrations' }]}
        current={t`Create Integration`}
        style={{ padding: '0.375rem 1.3125rem' }}
      />
      <PageTitle
        title={t`Create Integration`}
        subtitle={<AsteriskSubtitle />}
        marginBottom={2}
        paddingX={1}
      >
        <Button
          aria-label={t`Create Integration`}
          id="btn-save-integration"
          text={t`Create Integration`}
          onClick={handleToggleCreateModal}
          disabled={createBtnDisabledStatus}
        />
        <Button
          aria-label={t`Cancel`}
          text={t`Cancel`}
          kind="secondary"
          onClick={() => navigate('/integrations')}
        />
      </PageTitle>

      <PaddedForm>
        <Grid.Container>
          <BasicInformation
            handleForm={handleForm}
            categories={categories}
            editMode={false}
            integration={null}
          />
          <Scope
            handleForm={handleForm}
            serviceNetworkItems={serviceNetworkItems}
            integration={integration}
          />
          <Trigger
            handleForm={handleForm}
            applicationEventsItems={applicationEventsItems}
            triggerTypeHttpRequest={triggerTypeHttpRequest}
            triggerTypeApplicationEvent={triggerTypeApplicationEvent}
            integration={integration}
          />

          <Metadata
            handleForm={handleForm}
            metadataInputs={metadataInputs}
            setMetadataInputs={setMetadataInputs}
            enabledAddMetadataInput={enabledAddMetadataInput}
            setEnabledAddMetadataInput={setEnabledAddMetadataInput}
            form={form}
            setForm={setForm}
          />
          <CacheSettings
            handleForm={handleForm}
            editMode={false}
            triggerTypeHttpRequest={triggerTypeHttpRequest}
          />
          <Commands
            handleForm={handleForm}
            commands={commands}
            commandsFlow={commandsFlow}
            categoryActions={categoryActions}
            triggerTypeHttpRequest={triggerTypeHttpRequest}
          />
        </Grid.Container>
      </PaddedForm>
    </>
  );
}
