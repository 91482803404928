import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Modal from '@decisiv/ui-components/lib/components/Modal';
import { t, Trans } from '@lingui/macro';
import MinusCircle from '@decisiv/iconix/lib/components/MinusCircle';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import { useNotifications } from '@decisiv/ui-components/lib/components/Notifications';
import { navigate } from '@reach/router';
import noop from 'lodash/noop';
import { Bold } from '../style';
import ConfiguredIntegrationService from '../../../api/configured_integrations';

export default function RemoveLocationModal({
  visible,
  onClose,
  integration,
  location,
}) {
  const notificationSuccessProps = {
    intent: 'success',
    title: t`Configured Integrations`,
    duration: 3500,
    onClose: noop,
  };
  const notificationErrorProps = {
    intent: 'danger',
    title: t`Something went wrong!`,
    duration: 3500,
    onClose: noop,
  };

  const { notify } = useNotifications();

  const deleteConfiguredIntegration = useCallback(async () => {
    const resp =
      await ConfiguredIntegrationService.deleteConfiguredIntegrationByUuid(
        location?.id,
      );

    if (resp.status === 200) {
      notify(
        notificationSuccessProps,
        t`The location was removed successfully`,
      );

      navigate(`/integrations/${integration?.id}`);
    } else {
      notify(
        { ...notificationErrorProps, title: resp.errors[0].title },
        resp.errors[0].detail,
      );
    }
  }, [
    integration?.id,
    location?.id,
    notificationErrorProps,
    notificationSuccessProps,
    notify,
  ]);

  const integrationName = integration.attributes?.name;
  const integrationEnabled = integration.attributes?.enabled;
  const locationName = location.attributes?.name;
  const locationId = location.attributes?.srm_account_id;

  return (
    <Modal
      actions={[
        {
          text: t`Remove Integration`,
          onClick: () => deleteConfiguredIntegration(),
        },
        {
          text: t`Cancel`,
          onClick: onClose,
        },
      ]}
      icon={MinusCircle}
      intent="danger"
      onClose={onClose}
      title={t`Remove ${integrationName} Integration from ${locationName} ?`}
      visible={visible}
    >
      <Flex flexDirection="column">
        <P shade={1} marginTop={1} marginBottom={1}>
          <Trans>
            You are about to remove the {integrationName} Integration (currently{' '}
            {integrationEnabled ? t`enabled` : t`disabled`}) from
            <Bold>
              {`${locationName}`} ({locationId})
            </Bold>
            .
          </Trans>
        </P>
        <P shade={1}>
          <Trans>
            When you click REMOVE INTEGRATION, the integration is disabled for
            the location and disappears from the integrations’s list of
            locations. You can always add it back.
          </Trans>
        </P>
      </Flex>
    </Modal>
  );
}

RemoveLocationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  integration: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
