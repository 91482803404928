import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Combobox, Grid, H3, P } from '@decisiv/ui-components';
import color from '@decisiv/design-tokens/lib/color';
import toColorString from 'polished/lib/color/toColorString';
import CommandFlow from '../../components/CommandFlow';
import SectionRow from '../../components/SectionRow';
import filterOptions from '../../utils/filterOptions';

export default function Commands({
  handleForm,
  commands,
  commandsFlow,
  categoryActions,
  triggerTypeHttpRequest,
  editMode,
  integration,
}) {
  const [inputValue, setInputValue] = useState('');

  const [actionValue, setActionValue] = useState(
    ([integration?.attributes?.action] || []).map((action) => ({
      id: action,
      label: action,
      value: action,
    })),
  );

  const handleAction = () => {
    const updateAction = ([integration?.attributes?.action] || []).map(
      (action) => ({
        id: action,
        label: action,
        value: action,
      }),
    );
    setActionValue(updateAction);
    setInputValue('');
  };

  useEffect(() => {
    if (!triggerTypeHttpRequest) {
      handleForm({
        selectedId: '',
        field: 'action',
      });
    }
    // eslint-disable-next-line
  }, [triggerTypeHttpRequest]);

  return (
    <>
      <SectionRow>
        <Grid.Column padding={0} span="3">
          <H3>
            <Trans>Commands</Trans>
          </H3>
        </Grid.Column>
        <Grid.Column padding={0}>
          <P shade={1} size="small">
            <Trans>
              Integration Commands can be selected sequentially and visually
              linked together. For more information about commands, visit the{' '}
              <a href="/toolkit/commands" target="_blank">
                command toolkit
              </a>
            </Trans>
          </P>
          <CommandFlow
            commands={commands}
            editMode={editMode}
            selectedCommands={commandsFlow}
            updateSelectedCommands={(e) => {
              handleForm({ event: e, field: 'commands_flow' });
            }}
          />
          {triggerTypeHttpRequest ? (
            <>
              <P
                weight="semibold"
                style={{
                  marginTop: '1.3rem',
                  color: toColorString(color.base.alaskanHusky),
                }}
              >
                <Trans>Action</Trans>
              </P>
              <P shade={1} size="small" style={{ marginTop: '0.6rem' }}>
                <Trans>Action define the sum of the commands.</Trans>
              </P>
              <Combobox
                label={t`Action`}
                data-testid="combo-service-network"
                style={{ marginTop: '0.55rem' }}
                required
                options={filterOptions(categoryActions, inputValue)}
                value={actionValue}
                onChangeInputValue={(e) =>
                  setInputValue(e ? e.target.value : '')
                }
                onChangeValue={(selectedId, options) => {
                  handleForm({
                    selectedId,
                    options,
                    field: 'action',
                  });
                  handleAction();
                }}
                defaultInputValue={integration?.attributes.action}
              />
            </>
          ) : (
            ''
          )}
        </Grid.Column>
      </SectionRow>
    </>
  );
}

Commands.defaultProps = {
  editMode: false,
  integration: null,
};

Commands.propTypes = {
  handleForm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  commands: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  commandsFlow: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  categoryActions: PropTypes.arrayOf(PropTypes.object).isRequired,
  triggerTypeHttpRequest: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  integration: PropTypes.oneOfType([PropTypes.object]),
};
