import api from './api';

const fetchServiceNetwork = async () => {
  try {
    const { data } = await api.get(`/service-network`);

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

export default {
  fetchServiceNetwork,
};
